/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { FunctionComponent } from 'react';

import {
    AuthorizationContext,
    withAuthorizationContext,
} from '../controllers/authorization/AuthorizationContext';
import { checkPermission } from '../../utils/authorization';

/**
 * @typedef {Object} OwnProps
 * @property {Array<string>} actions
 * @property {string} [operator]
 * @property {Function} [yes]
 * @property {Function} [no]
 * @extends {AuthorizationContext}
 */
interface OwnProps extends AuthorizationContext {
    actions: Array<string>;
    operator?: string;
    yes?: Function;
    no?: Function;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

export const yesDefault = () => null;
export const noDefault = () => null;

/**
 * performs permission check
 * @param {Props} props
 * @returns {FunctionComponent<Props>}
 */
const Can: FunctionComponent<Props> = (
    {
        permissions,
        actions,
        operator,
        yes = yesDefault,
        no = noDefault,
    }: Props,
) => {
    return checkPermission(permissions, actions, operator) ? yes() : no();
};

export default withAuthorizationContext(Can);
