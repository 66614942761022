/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import axios from 'axios';
import { AuthenticationContextProvider } from './AuthenticationContext';

import RegistrationScreen from '../../screens/RegistrationScreen';
import RegistrationInviteScreen from '../../screens/RegistrationInviteScreen';
import { AppState } from '../../../reducers/types';
import {
    LoginFormFields,
    RegistrationPostData,
    RegistrationInvitePostData,
    RegistrationFormFields,
    RegistrationInviteFormFields,
    AccountValidationFormFields,
    ResendActivationFormFields,
    LoginPostData,
    RecoverPasswordFormFields,
    ResetPasswordPostData,
} from '../../../constants/authentication';
import { User, UserTypes } from '../../../constants/user';
import {
    requestLogin,
    requestRegistration,
    requestRegistrationInvite,
    requestAccountValidation,
    requestResendActivation,
    requestLogout,
    requestRecoverPassword,
    requestResetPassword,
} from '../../../actions/authentication';
import { validateForm, ValidationType, FormValidatorErrorType } from '../../../utils/validations';
import { KeyedObject } from '../../../constants/misc';
import LoginScreen from '../../screens/LoginScreen';
import TestScreen from '../../screens/TestScreen';
import ResetPasswordScreen from '../../screens/RecoverPasswordScreen';
import { webSocketTokenURL } from '../../../services/authentication';

/**
 * @typedef {Object} StateProps
 * @property {boolean} isAuthenticated
 * @property {boolean} isFetching
 * @property {string | null} token
 * @property {User | null} user
 */
interface StateProps {
    isAuthenticated: boolean;
    isFetching: boolean;
    token: string | null;
    user: User | null;
}

/**
 * @typedef {Object} DispatchProps
 * @property {Function} requestLogin
 * @property {Function} requestRegistration
 * @property {Function} requestAccountValidation
 * @property {Function} requestResendActivation
 * @property {Function} requestLogout
 * @property {Function} requestRecoverPassword
 * @property {Function} requestResetPassword
 */
interface DispatchProps {
    requestLogin: Function;
    requestRegistration: Function;
    requestRegistrationInvite: Function;
    requestAccountValidation: Function;
    requestResendActivation: Function;
    requestLogout: Function;
    requestRecoverPassword: Function;
    requestResetPassword: Function;
}

/**
 * @typedef {Object} OwnProps
 * @property {any} children
 */
interface OwnProps {
    children: any;
}

/**
 * @typedef {Object} Props
 */
type Props = StateProps & DispatchProps & OwnProps;

/**
 * @typedef {Object} OwnState
 * @property {boolean} showRegistration
 * @property {boolean} showRegistrationInvite
 * @property {boolean} showLogin
 * @property {boolean} showTestInfo
 * @property {boolean} showResetPassword
 * @property {Function} testInfoCallback
 */
interface OwnState {
    showRegistration: boolean;
    showRegistrationInvite: boolean;
    showLogin: boolean;
    showTestInfo: boolean;
    showResetPassword: boolean;
    userTypeSelected?: UserTypes | null;
    testInfoCallback: () => void;
}

/**
 * @typedef {Object} State
 */
type State = OwnState;

const initialState: State = {
    showRegistration: false,
    showRegistrationInvite: false,
    showLogin: false,
    showTestInfo: false,
    showResetPassword: false,
    userTypeSelected: null,
    testInfoCallback: () => {},
};

/**
 * provides store properties and actions access to its consumers
 * @extends {Component<Props, State>}
 */
export class AuthenticationController extends Component<Props, State> {
    state = initialState;

    /**
     * shows registration form
     */
    openRegistration = (userTypeSelected: UserTypes | null = null) => {
        this.setState({
            showRegistration: true,
            userTypeSelected,
        });
    };

    /**
     * hides registration form
     */
    closeRegistration = () => {
        this.setState({
            showRegistration: false,
        });
    };

    /**
     * shows registration invite form
     */
    openRegistrationInvite = () => {
        this.setState({
            showRegistrationInvite: true,
        });
    };

    /**
     * hides registration invite form
     */
    closeRegistrationInvite = () => {
        this.setState({
            showRegistrationInvite: false,
        });
    };

    /**
     * hides reset password form
     */
    closeResetPassword = () => {
        this.setState({
            showResetPassword: false,
        });
    };

    /**
     * validates the registration fields
     * @param {RegistrationFormFields} fields
     * @returns {KeyedObject | null}
     */
    validateRegistration = (fields: RegistrationFormFields, userType: UserTypes): KeyedObject | null => {
        let errors: KeyedObject | null;

        errors = validateForm(
            { ...fields, userType },
            {
                name: {
                    validations: [ValidationType.NotBlank],
                },
                lastName: {
                    validations: (userType === UserTypes.Athlete || userType === UserTypes.Manager) ? [ValidationType.NotBlank] : [],
                },
                password: {
                    validations: [ValidationType.NotBlank],
                },
                cpassword: {
                    validations: [ValidationType.NotBlank],
                },
                email: {
                    validations: [ValidationType.NotBlank],
                },
                userType: {
                    validations: [ValidationType.NotBlank],
                },
                modality: {
                    validations: (userType === UserTypes.Athlete || userType === UserTypes.Manager) ? [ValidationType.NotBlank] : [],
                },
            },
        );

        if (fields.password !== fields.cpassword) {
            if (errors === null) errors = {};
            if (errors.cpassword === null || errors.cpassword === undefined) {
                errors.cpassword = [{ typeOfViolation: FormValidatorErrorType.PasswordsDontMatch }];
            }
        }

        if (errors && Object.keys(errors).length === 0) errors = null;
        return errors;
    };

    /**
     * validates the registration invite fields
     * @param {RegistrationInviteFormFields} fields
     * @returns {KeyedObject | null}
     */
    validateRegistrationInvite = (fields: RegistrationInviteFormFields, isSponsor: boolean): KeyedObject | null => {
        let errors: KeyedObject | null;

        errors = validateForm(fields, {
            name: {
                validations: [ValidationType.NotBlank],
            },
            lastName: {
                validations: isSponsor ? [] : [ValidationType.NotBlank],
            },
            password: {
                validations: [ValidationType.NotBlank],
            },
            cpassword: {
                validations: [ValidationType.NotBlank],
            },
        });

        if (fields.password !== fields.cpassword) {
            if (errors === null) errors = {};
            if (errors.cpassword === null || errors.cpassword === undefined) {
                errors.cpassword = [{ typeOfViolation: FormValidatorErrorType.PasswordsDontMatch }];
            }
        }

        if (errors && Object.keys(errors).length === 0) errors = null;
        return errors;
    };

    /**
     * validates the recover password fields
     * @param {RegistrationFormFields} fields
     * @returns {KeyedObject | null}
     */
    validateRecoverPassword = (fields: RecoverPasswordFormFields): KeyedObject | null => {
        let errors: KeyedObject | null;

        errors = validateForm(fields, {
            email: {
                validations: [ValidationType.NotBlank],
            },
        });

        if (errors && Object.keys(errors).length === 0) errors = null;
        return errors;
    };

    /**
     * calls the registration action creator
     * @param {RegistrationFormFields} fields
     * @param {Function} onSuccess
     * @param {Function} onFailure
     */
    register = (fields: RegistrationPostData, userType: UserTypes, onSuccess: Function, onFailure: Function) => {
        const { requestRegistration } = this.props;

        requestRegistration(fields, userType, onSuccess, onFailure);
    };

    /**
     * calls the registration invite action creator
     * @param {RegistrationInviteFormFields} fields
     * @param {Function} onSuccess
     * @param {Function} onFailure
     */
    registerInvite = (fields: RegistrationInvitePostData, onSuccess: Function, onFailure: Function) => {
        const { requestRegistrationInvite } = this.props;

        let userType = UserTypes.Athlete;
        if (fields.token.includes(UserTypes.Admin)) {
            userType = UserTypes.Admin;
        } else if (fields.token.includes(UserTypes.Sponsor)) {
            userType = UserTypes.Sponsor;
        } else if (fields.token.includes(UserTypes.Manager)) {
            userType = UserTypes.Manager;
        }
        fields.token = fields.token.split('-')[0];
        requestRegistrationInvite(fields, userType, onSuccess, onFailure);
    };

    /**
     * calls the reset password action creator
     * @param {RegistrationFormFields} fields
     * @param {Function} onSuccess
     * @param {Function} onFailure
     */
    recoverPassword = (fields: RecoverPasswordFormFields, onSuccess: Function, onFailure: Function) => {
        const { requestRecoverPassword } = this.props;

        requestRecoverPassword(fields, onSuccess, onFailure);
    };

    /**
     * calls the validate account action creator
     * @param {AccountValidationFormFields} fields
     * @param {Function} onSuccess
     * @param {Function} onFailure
     */
    accountValidate = (fields: AccountValidationFormFields, onSuccess: Function, onFailure: Function) => {
        const { requestAccountValidation } = this.props;

        requestAccountValidation(fields, onSuccess, onFailure);
    };

    /**
     * calls the resend activation action creator
     * @param {ResendActivationFormFields} fields
     * @param {Function} onSuccess
     * @param {Function} onFailure
     */
    resendActivation = (fields: ResendActivationFormFields, onSuccess: Function, onFailure: Function) => {
        const { requestResendActivation } = this.props;

        requestResendActivation(fields, onSuccess, onFailure);
    };

    /**
     * shows login form
     */
    openLogin = () => {
        this.setState({
            showLogin: true,
        });
    };

    /**
     * hides login form
     */
    closeLogin = () => {
        this.setState({
            showLogin: false,
        });
    };

    /**
     * hides login form and shows register form
     */
    registerFromLogin = () => {
        this.setState({
            showLogin: false,
            showRegistration: true,
        });
    };

    /**
     * hides login form and shows register form
     */
    recoverPasswordForm = () => {
        this.setState({
            showLogin: false,
            showResetPassword: true,
        });
    };

    /**
     * validates the registration fields
     * @param {LoginFormFields} fields
     * @returns {KeyedObject | null}
     */
    validateLogin = (fields: LoginFormFields): KeyedObject | null => {
        let errors: KeyedObject | null;

        errors = validateForm(fields, {
            email: {
                validations: [ValidationType.NotBlank],
            },
            password: {
                validations: [ValidationType.NotBlank],
            },
        });

        if (errors && Object.keys(errors).length === 0) errors = null;
        return errors;
    };

    /**
     * calls the login action creator
     * @param {LoginPostData} fields
     * @param {Function} onSuccess
     * @param {Function} onFailure
     */
    login = (fields: LoginPostData, onSuccess: Function, onFailure: Function) => {
        const { requestLogin } = this.props;

        requestLogin(fields, onSuccess, onFailure);
    };

    /**
     * calls the reset password action creator
     * @param {LoginPostData} fields
     * @param {Function} onSuccess
     * @param {Function} onFailure
     */
    resetPassword = (fields: ResetPasswordPostData, onSuccess: Function, onFailure: Function) => {
        const { requestResetPassword } = this.props;

        requestResetPassword(fields, onSuccess, onFailure);
    };

    resetStore = (onSuccess: Function) => {
        const { requestLogout } = this.props;
        requestLogout(onSuccess);
    };

    /**
     * shows test form
     */
    openTestInfo = (finishCallback?: (() => void)): void => {
        this.setState({
            showTestInfo: true,
            testInfoCallback: finishCallback || (() => {}),
        });
    };

    /**
     * hides test form
     */
    closeTest = (): void => {
        const { testInfoCallback } = this.state;

        this.setState({
            showTestInfo: false,
        }, testInfoCallback);
    };

    getSocketToken = async (): Promise<string | null> => {
        try {
            const { data } = await axios.get(webSocketTokenURL());

            if (!data?.webSocketToken) return null;

            return data.webSocketToken;
        } catch {
            return null;
        }
    }

    render() {
        const {
            children, isAuthenticated, isFetching, token, user,
        } = this.props;
        const {
            showLogin, showRegistration, showTestInfo, showResetPassword, showRegistrationInvite, userTypeSelected,
        } = this.state;

        return (
            <AuthenticationContextProvider
                value={{
                    isAuthenticated,
                    isFetching,
                    user,
                    token,
                    openRegistration: this.openRegistration,
                    openRegistrationInvite: this.openRegistrationInvite,
                    openLogin: this.openLogin,
                    accountValidate: this.accountValidate,
                    resendActivation: this.resendActivation,
                    openTestInfo: this.openTestInfo,
                    cleanStore: this.resetStore,
                    resetPassword: this.resetPassword,
                    getSocketToken: this.getSocketToken,
                }}
            >
                {children}
                {showLogin && (
                    <LoginScreen
                        isFetching={isFetching}
                        close={this.closeLogin}
                        submit={this.login}
                        validate={this.validateLogin}
                        register={this.registerFromLogin}
                        recoverPassword={this.recoverPasswordForm}
                    />
                )}
                {showRegistration && (
                    <RegistrationScreen
                        isFetching={isFetching}
                        userTypeSelected={userTypeSelected}
                        close={this.closeRegistration}
                        submit={this.register}
                        validate={this.validateRegistration}
                    />
                )}
                {showRegistrationInvite && (
                    <RegistrationInviteScreen
                        isFetching={isFetching}
                        close={this.closeRegistrationInvite}
                        submit={this.registerInvite}
                        validate={this.validateRegistrationInvite}
                        openLogin={this.openLogin}
                    />
                )}
                {showResetPassword && (
                    <ResetPasswordScreen
                        isFetching={isFetching}
                        close={this.closeResetPassword}
                        submit={this.recoverPassword}
                        validate={this.validateRecoverPassword}
                    />
                )}
                {showTestInfo && (
                    <TestScreen
                        isFetching={isFetching}
                        close={this.closeTest}
                        openRegistration={this.openRegistration}
                        role={user?.role}
                        athleteModality={user?.club?.clubCompositeKey.sport}
                    />
                )}
            </AuthenticationContextProvider>
        );
    }
}

/**
 * mapStateToProps
 * @param {AppState} state
 * @returns {StateProps}
 */
const mapStateToProps = (state: AppState): StateProps => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        isFetching: state.authentication.isFetching,
        user: state.authentication.user,
        token: state.authentication.token,
    };
};

/**
 * mapDispatchToProps
 * @param {Dispatch} dispatch
 * @returns {DispatchProps}
 */
export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    requestLogin: (formData: LoginPostData, onSuccess: Function, onFailure: Function) => dispatch(requestLogin(formData, onSuccess, onFailure)),
    requestRegistration: (
        formData: RegistrationFormFields,
        userType: UserTypes,
        onSuccess: Function,
        onFailure: Function,
    ) => dispatch(requestRegistration(formData, userType, onSuccess, onFailure)),
    requestRegistrationInvite: (
        formData: RegistrationFormFields,
        userType: UserTypes,
        onSuccess: Function,
        onFailure: Function,
    ) => dispatch(requestRegistrationInvite(formData, userType, onSuccess, onFailure)),
    requestAccountValidation: (formData: AccountValidationFormFields, onSuccess: Function, onFailure: Function) => dispatch(requestAccountValidation(formData, onSuccess, onFailure)),
    requestResendActivation: (formData: ResendActivationFormFields, onSuccess: Function, onFailure: Function) => dispatch(requestResendActivation(formData, onSuccess, onFailure)),
    requestLogout: (onSuccess: Function) => dispatch(requestLogout(onSuccess)),
    requestRecoverPassword: (formData: RecoverPasswordFormFields, onSuccess: Function, onFailure: Function) => dispatch(requestRecoverPassword(formData, onSuccess, onFailure)),
    requestResetPassword: (formData: ResetPasswordPostData, onSuccess: Function, onFailure: Function) => dispatch(requestResetPassword(formData, onSuccess, onFailure)),
});

export const ConnectedAuthenticationController = connect(mapStateToProps, mapDispatchToProps)(AuthenticationController);
