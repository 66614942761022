/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

import {
    PreferencesContext,
    withPreferencesContext,
} from '../controllers/preferences/PreferencesContext';
import { Theme } from '../../constants/preferences';

/**
 * @typedef {Object} OwnProps
 * @extends {PreferencesContext}
 * @property {any} children
 */
interface OwnProps extends PreferencesContext {
    children: any;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * provides theme class to the children components
 * @param {Props} props
 * @returns {FunctionComponent<Props>}
 */
const Themed: FunctionComponent<Props> = (
    {
        theme,
        children,
    }: Props) => {

    const themeClass = `theme-${(theme === Theme.Dark) ? Theme.Dark : Theme.Light}`;

    return (
        <div className={`theme-container ${themeClass.toLowerCase()}`}>
            {children}
        </div>
    );
};

export default withPreferencesContext(Themed);
