/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import { ReactSVG } from 'react-svg';
import IconTimes from '../assets/IconTimes';
import logo from '../../assets/images/horizontal_logo.svg';
import { withTranslationContext, TranslationContext } from '../controllers/translation/TranslationContext';
import FormField from './FormField';
import { ApiError, KeyedObject } from '../../constants/misc';
import { validateForm, ValidationType } from '../../utils/validations';
import loader from '../../assets/images/loader.svg';
import { ContactUser } from '../../constants/contact';
import { User, SendMessageRequest } from '../../constants/user';
import { withUserContext, UserContext } from '../controllers/user/UserContext';
import { displaySuccess } from '../../utils/notifications';

interface OwnProps {
    userPageOwner: User | null;
    onClose: () => void;
}

type Props = OwnProps & TranslationContext & UserContext;

const ContactModal: FunctionComponent<Props> = ({
    t,
    onClose,
    userPageOwner,
    sendMessage,
    handleAPIErrors,
}: Props) => {
    const [isFetching, setIsFetching] = useState(false);
    const [fields, setFields] = useState<ContactUser | null>(null);
    const [errors, setErrors] = useState<KeyedObject>({});
    const [charCount, setCharCount] = useState<number>(0);

    const onChange = (name: string, value: string): void => {
        const shouldSetValue = value.length <= 1000;
        if (shouldSetValue) {
            setFields({ ...fields, [name]: value } as ContactUser);
            setCharCount(value.length);
        }
    };

    const success = (): void => {
        setIsFetching(false);
        displaySuccess({ message: t('sendMessage.successMessageSend') });
        onClose();
    };

    const failure = (newErrors?: ApiError): void => {
        setErrors(handleAPIErrors(newErrors, true)?.fields || {});
        setIsFetching(false);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();

        const formErrors = validateForm(fields || {}, {
            message: {
                validations: [ValidationType.NotBlank],
            },
        });
        
        if (formErrors) {
            setErrors(formErrors);
            return;
        }
        
        if (!fields) return;

        const payload: SendMessageRequest = {
            userId: userPageOwner?.id,
            message: fields.message,
        };
        
        setIsFetching(true);
        sendMessage(payload, success, failure);
    };
    
    return (
        <div className="modal" data-testid="contact-modal">
            <div className="modal__backdrop" />
            { isFetching && (
                <div className="loader">
                    <ReactSVG wrapper="span" src={loader} />
                </div>
            )}
            <div className="modal--secondary contact-modal modal__content">
                <div className="modal--secondary__header">
                    <img src={logo} alt="logo" />
                    <button type="button" onClick={onClose}>
                        <IconTimes fill="#278982" />
                    </button>
                </div>
                <div className="modal--secondary__title">
                    <span>{t('contactModal.title') + userPageOwner?.name}</span>
                    <i className="title title--alternative" />
                </div>
                <form className="modal--secondary__form contact-modal__form " onSubmit={onSubmit}>
                    <FormField
                        name="message"
                        multiline
                        rows={8}
                        onChange={({ currentTarget: { name, value } }): void => onChange(name, value)}
                        value={fields?.message}
                        label={`${t('contactModal.message')} (${charCount}/1000)`}
                        cssClass="modal--secondary__form__input"
                        errors={errors}
                    />
                    <button type="submit" hidden />
                </form>
                <div className="modal--secondary__actions action-end">
                    <button
                        className="btn btn--primary-inverse"
                        type="button"
                        onClick={onSubmit}
                        data-testid="contact-modal-submit"
                    >
                        {t('general.send')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default withUserContext(withTranslationContext(ContactModal));
