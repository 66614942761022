/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

export interface PaymentData {
    creditAmount: number;
    phoneNumber: string;
}

export interface Pack {
    credits: number;
    price: number;
}

export const silverPack: Pack = {
    credits: 50,
    price: 100,
};

export const goldPack: Pack = {
    credits: 120,
    price: 200,
};

export const diamondPack: Pack[] = [
    {
        credits: 250,
        price: 400,
    },
    {
        credits: 375,
        price: 600,
    },
    {
        credits: 500,
        price: 800,
    },
    {
        credits: 625,
        price: 1000,
    },
    {
        credits: 750,
        price: 1200,
    },
    {
        credits: 875,
        price: 1400,
    },
    {
        credits: 1000,
        price: 1600,
    },
    {
        credits: 1125,
        price: 1800,
    },
    {
        credits: 1250,
        price: 2000,
    },
];
