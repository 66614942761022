/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import reactGa from 'react-ga4';
import { SponsorContext, withSponsorContext } from '../controllers/sponsor/SponsorContext';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import IconTimes from '../assets/IconTimes';
import logo from '../../assets/images/logo_white.svg';
import runningImage from '../../assets/images/running.png';
import modalSlant from '../../assets/images/modal_slant.svg';
import FormField from '../elements/FormField';
import { LoginFormFields, LoginPostData } from '../../constants/authentication';
import { KeyedObject, MatchParams } from '../../constants/misc';
import { displayError } from '../../utils/notifications';
import { ErrorCode } from '../../constants/errors';
import {
    ADMIN_USERS_ROUTE,
    FAN_PROFILE_ROUTE,
    MANAGER_PROFILE_ROUTE,
    PROFILE_ROUTE,
    SPONSOR_PROFILE_ROUTE,
} from '../../constants/routes';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/authorization/AuthorizationContext';
import { checkPermission } from '../../utils/authorization';
import { RolePermission } from '../../constants/authorization';
import { User, UserRoles } from '../../constants/user';
import { GACategories } from '../../utils/analytics';

/**
 * @typedef {Object} OwnProps
 * @extends {TranslationContext}
 * @property {boolean} isFetching
 * @property {Function} close
 * @property {Function} submit
 * @property {Function} validate
 * @property {Function} register
 */
interface OwnProps extends TranslationContext, RouteComponentProps<MatchParams, {}, RouteState>, AuthorizationContext, SponsorContext {
    isFetching: boolean;
    close: Function;
    submit: Function;
    validate: Function;
    register: Function;
    recoverPassword: Function;
}

interface RouteState {
    from?: string;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 * @property {LoginFormFields} fields
 * @property {KeyedObject} [errors]
 * @property {boolean} remember
 */
interface OwnState {
    fields: LoginFormFields;
    errors: KeyedObject | null;
    remember: boolean;
}

const initialState: OwnState = {
    fields: {
        email: '',
        password: '',
    },
    remember: false,
    errors: null,
};

/**
 * @typedef {Object} State
 */
type State = OwnState;

/**
 * shows the login screen
 * @extends {Component<Props, State>}
 */
class LoginScreen extends Component<Props, State> {
    state = initialState;

    /**
     * handles input change
     * @param {React.FormEvent<HTMLInputElement>} e
     */
    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    /**
     * checkbox change handler
     * @param {React.FormEvent<HTMLInputElement>} e
     */
    onCheckChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.checked,
        });
    };

    /**
     * handles redirect to register screen
     * @param {React.MouseEvent} e
     */
    onRegisterClick = (e: React.MouseEvent) => {
        e.preventDefault();
        const { register } = this.props;
        register();
    };

    /**
     * it handles forgot password click
     * @param {React.MouseEvent} e
     */
    onForgotPassword = (e: React.MouseEvent) => {
        const { recoverPassword } = this.props;
        recoverPassword();
    };

    /**
     * handles form submit
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { submit, validate } = this.props;
        const { fields } = this.state;

        const errors = validate(fields);

        this.setState(
            {
                ...this.state,
                errors,
            },
            () => {
                if (!errors) {
                    const formData: LoginPostData = {
                        email: fields.email,
                        password: fields.password,
                        acceptingTerms: true,
                    };

                    submit(formData, this.onSuccess, this.onFailure);
                }
            },
        );
    };

    /**
     * handles login success
     */
    onSuccess = (user: User) => {
        const {
            close, history, fetchAthletePendingSupports,
        } = this.props;
        
        this.setState(
            {
                fields: initialState.fields,
                errors: null,
                remember: initialState.remember,
            },
            async () => {
                close();
                const userRoleRoute: Record<UserRoles, string> = {
                    [UserRoles.Admin]: ADMIN_USERS_ROUTE,
                    [UserRoles.Athlete]: PROFILE_ROUTE,
                    [UserRoles.Fan]: FAN_PROFILE_ROUTE,
                    [UserRoles.Manager]: MANAGER_PROFILE_ROUTE,
                    [UserRoles.Sponsor]: SPONSOR_PROFILE_ROUTE,
                };
                if (user.role === UserRoles.Athlete) await fetchAthletePendingSupports();
                const route = userRoleRoute[user.role];
                reactGa.event({
                    category: GACategories.USER_LOGIN,
                    action: `${user.role} login`,
                });
                history.push(route);
            },
        );
    };

    /**
     * handles login failure
     * @param {KeyedObject} errors
     */
    onFailure = (errors: KeyedObject) => {
        const { t, close } = this.props;

        if (errors?.errors?.[0]?.errorCode === 7) {
            close();
        }

        if (errors.fields) {
            this.setState({
                ...this.state,
                errors: errors.fields,
            });
        }

        if (errors.errors) {
            errors.errors.forEach((error: any) => {
                displayError({
                    message: t(`errors.${ErrorCode[error.errorCode]}`),
                });
            });
        }
    };

    initialRoute = (permissions: RolePermission[]) => {
        checkPermission(permissions, []);
    };

    render() {
        const { close, isFetching, t } = this.props;
        const { fields, errors, remember } = this.state;

        return (
            <div className="modal registration-screen" data-testid="login-screen">
                <div className="modal__backdrop" />
                <div className="modal__content">
                    <button
                        type="button"
                        className="modal__content__close"
                        onClick={() => close()}
                        data-testid="close-modal"
                    >
                        <IconTimes fill="#FFF" />
                    </button>
                    <div className="modal__content__top">
                        <div className="modal__content__top__header">
                            <img src={logo} className="logo" alt="" />
                            <img src={runningImage} className="running" alt="" />
                            <img src={modalSlant} className="slant" alt="" />
                        </div>
                        <div className="modal__content__top__subheader">
                            <h1>{t('login.title')}</h1>
                        </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 col-xs-10 offset-xs-1">
                                <FormField
                                    label={t('login.email')}
                                    type="text"
                                    name="email"
                                    value={fields.email}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    disabled={isFetching}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 col-xs-10 offset-xs-1">
                                <FormField
                                    label={t('login.password')}
                                    type="password"
                                    name="password"
                                    value={fields.password}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    disabled={isFetching}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 col-xs-10 offset-xs-1">
                                <button
                                    type="submit"
                                    className="btn btn--primary-inverse btn--block"
                                    disabled={isFetching}
                                >
                                    {t('login.submit')}
                                </button>
                            </div>
                        </div>
                        <div className="row col-sm-8 offset-sm-2 col-xs-10 offset-xs-1 register-link">
                            <p>
                                {t('login.notRegistered')}
                                <a href="#a" onClick={this.onRegisterClick}>
                                    {t('login.create')}
                                </a>
                            </p>
                        </div>
                    </form>
                    <div className="modal__content__bottom">
                        <label className="control control--checkbox">
                            <input
                                name="remember"
                                type="checkbox"
                                checked={remember}
                                onChange={this.onCheckChange}
                                disabled={isFetching}
                            />
                            <div className="indicator" />
                            {t('login.remember')}
                        </label>
                        <p>
                            <a href="#a" onClick={this.onForgotPassword}>
                                {t('login.forgot')}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuthorizationContext(withTranslationContext(withSponsorContext(withRouter(LoginScreen))));
