/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import axios from 'axios';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import IconTimes from '../assets/IconTimes';
import logo from '../../assets/images/logo_white.svg';
import runningImage from '../../assets/images/running.png';
import modalSlant from '../../assets/images/modal_slant.svg';
import { DropdownOption, KeyedObject, MatchParams } from '../../constants/misc';
import Dropdown from '../elements/Dropdown';
import FormField from '../elements/FormField';
import { validateForm, ValidationType } from '../../utils/validations';
import { displayError, displaySuccess } from '../../utils/notifications';
import { ErrorCode } from '../../constants/errors';
import { UserTypes } from '../../constants/user';
import { inviteUserURL } from '../../services/authentication';
import { enumToOptions } from '../../utils/dropdown';
import { sportsURL } from '../../services/utils';

/**
 * @typedef {Object} OwnProps
 * @extends {TranslationContext,  RouteComponentProps}
 */
interface OwnProps extends TranslationContext, RouteComponentProps<MatchParams> {
    close: () => void;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 */
interface OwnState {
    email: string;
    userType: DropdownOption | null;
    errors: KeyedObject | null;
    modality: DropdownOption | null;
    sportsOptions: DropdownOption[];
}

/**
 * @typedef {Object} State
 */
type State = OwnState;

const initialState: State = {
    email: '',
    userType: null,
    errors: null,
    modality: null,
    sportsOptions: [],
};

/**
 * shows the user invite screen
 * @extends {Component<Props, State>}
 */
class InviteScreen extends Component<Props, State> {
    state = initialState;

    async componentDidMount() {
        let sports;

        try {
            ({ data: sports } = await axios.get(sportsURL()));
        } catch {
            sports = [];
        }
        const sportsOptions = enumToOptions(sports, true);

        this.setState({
            sportsOptions,
        });
    }
    
    /**
     * handles input change
     * @param {React.FormEvent<HTMLInputElement>} e
     */
    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { name, value } = e.currentTarget;

        this.setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    onDropDownChange = (option: DropdownOption, name: string): void => {
        if (name === 'modality') {
            this.setState({
                modality: option,
            });
            return;
        }
        this.setState({
            userType: option,
        });
    };

    /**
     * handles form submit
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { email, userType, modality } = this.state;

        let validations: Record<string, unknown> = { email: { validations: [ValidationType.NotBlank] } };
        if (userType?.value === UserTypes.Manager || userType?.value === UserTypes.Athlete) {
            validations = { ...validations, modality: { validations: [ValidationType.NotBlank] } };
        }

        const errors = validateForm({ email, userType, modality }, validations);

        this.setState(
            { errors },
            () => {
                if (!errors) {
                    const convertedFields = { email, userType: userType?.value, modality: modality?.value };
                    axios
                        .post(inviteUserURL(), convertedFields)
                        .then(this.onSuccess)
                        .catch((error) => {
                            let formErrors = {};
                            if (error && error.response) {
                                formErrors = error.response.data;
                            }
                            this.onFailure(formErrors);
                        });
                }
            },
        );
    };

    /**
     * handles invite success
     */
    onSuccess = () => {
        const { close, t } = this.props;
        displaySuccess({
            message: t('invite.sent'),
        });
        close();
    };

    /**
     * handles invite failure
     * @param {KeyedObject} errors
     */
    onFailure = (errors: KeyedObject) => {
        const { t } = this.props;

        if (errors.fields) {
            this.setState({
                errors: errors.fields,
            });
        }

        if (errors.errors) {
            errors.errors.forEach((error: any) => {
                displayError({
                    message: t(`errors.${ErrorCode[error.errorCode]}`),
                });
            });
        }
    };

    render() {
        const { close, t } = this.props;
        const {
            userType, email, errors, modality, sportsOptions,
        } = this.state;

        const userTypeOptions: DropdownOption[] = [
            { label: t(`invite.${UserTypes.Athlete}`), value: UserTypes.Athlete },
            { label: t(`invite.${UserTypes.Sponsor}`), value: UserTypes.Sponsor },
            { label: t(`invite.${UserTypes.Admin}`), value: UserTypes.Admin },
            { label: t(`invite.${UserTypes.Manager}`), value: UserTypes.Manager },
        ];

        return (
            <div className="modal registration-screen" data-testid="login-screen">
                <div className="modal__backdrop" />
                <div className="modal__content">
                    <button
                        type="button"
                        className="modal__content__close"
                        onClick={() => close()}
                        data-testid="modal-close"
                    >
                        <IconTimes fill="#FFF" />
                    </button>
                    <div className="modal__content__top">
                        <div className="modal__content__top__header">
                            <img src={logo} className="logo" alt="" />
                            <img src={runningImage} className="running" alt="" />
                            <img src={modalSlant} className="slant" alt="" />
                        </div>
                        <div className="modal__content__top__subheader">
                            <h1>{t('invite.title')}</h1>
                        </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <Dropdown
                                    label={t('invite.userType')}
                                    name="userType"
                                    options={userTypeOptions}
                                    value={userType}
                                    onChange={this.onDropDownChange}
                                    errors={errors}
                                />
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <FormField
                                    label={t('invite.email')}
                                    type="text"
                                    name="email"
                                    value={email}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    errors={errors}
                                />
                            </div>
                        </div>
                        {
                            (userType?.value === UserTypes.Manager || userType?.value === UserTypes.Athlete) && (
                                <div className="row">
                                    <div className="col-sm-6 col-xs-12">
                                        <Dropdown
                                            label={t('profile.sport')}
                                            name="modality"
                                            options={sportsOptions}
                                            value={modality}
                                            onChange={this.onDropDownChange}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        <div className="row">
                            <div className="register-button-container">
                                <button type="submit" className="btn btn--primary-inverse btn--block">
                                    {t('invite.submit')}
                                </button>
                            </div>
                        </div>
                        <div className="modal__content__bottom">
                            <p>{t('invite.description')}</p>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(withRouter(InviteScreen));
