/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { TabType } from '../../utils/misc';
import IconTimes from '../assets/IconTimes';
import logo from '../../assets/images/logo.svg';

/**
 * @typedef {Object} OwnProps
 * @extends {TranslationContext}
 * @property {Function} close
 * @property {string} tab
 * @property {boolean} inRegister
 */
interface OwnProps extends TranslationContext {
    close: () => void;
    tab: string;
    inRegister: boolean;
}
/**
 * @typedef {Object} TabItem
 * @property {string} value
 * @property {string} text
 */

interface TabItem {
    value: string;
    text: string;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 * @property {TabItem} [tabItems]
 * @property {TabItem} selectedTab
 */
interface OwnState {
    tabItems: TabItem[];
    selectedTab: TabItem;
}

const initialState: OwnState = {
    tabItems: [
        {
            value: TabType.ABOUT_US,
            text: 'privacyModal.aboutTab',
        },
        {
            value: TabType.TERMS_CONDITIONS,
            text: 'privacyModal.termsTab',
        },
        {
            value: TabType.PRIVACY_POLICY,
            text: 'privacyModal.privacyTab',
        },
        {
            value: TabType.COOKIES_POLICY,
            text: 'privacyModal.cookiesTab',
        },
        {
            value: TabType.GENDER_EQUALITY_PLAN,
            text: 'privacyModal.gepTab',
        },
    ],
    selectedTab: {
        value: TabType.ABOUT_US,
        text: 'privacyModal.aboutTab',
    },
};

/**
 * @typedef {Object} State
 */
type State = OwnState;

/**
 * shows the terms and conditions screen
 * @extends {Component<Props, State>}
 */
class TermsAndConditionsScreen extends Component<Props, State> {
    state = initialState;

    private readonly privacyEmail = 'privacyModal.terms.mail';

    private readonly sportrack = 'privacyModal.sportrack';

    componentDidMount = () => {
        const { tab } = this.props;
        this.onTabChange(tab);
    };

    /**
     * handles section changes in modal
     */
    onTabChange = (tab: string) => {
        const { tabItems } = this.state;
        
        const [defaultTab, termsConditions, privacyPolicy, cookiesPolicy, genderEqualityPlan] = tabItems;
        
        let selectedTab = null;

        switch (tab) {
            case TabType.TERMS_CONDITIONS:
                selectedTab = termsConditions;
                break;
            case TabType.PRIVACY_POLICY:
                selectedTab = privacyPolicy;
                break;
            case TabType.COOKIES_POLICY:
                selectedTab = cookiesPolicy;
                break;
            case TabType.GENDER_EQUALITY_PLAN:
                selectedTab = genderEqualityPlan;
                break;
            default:
                selectedTab = defaultTab;
        }

        this.setState({
            selectedTab,
        });
    };

    renderTermsAndConditions = (): React.ReactNode => {
        const { t } = this.props;

        return (
            <div data-testid="terms-conditions" className="modal__content__termsConditions">
                <div className="description">
                    {t('privacyModal.terms.parag1.part1')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.terms.parag1.part2')}
                </div>
                <div className="description">{t('privacyModal.terms.parag2')}</div>
                <div className="main-title">{t('privacyModal.terms.title1')}</div>
                <div className="description">
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.terms.parag1_1.part2')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.terms.parag1_1.part3')}
                </div>
                <div className="main-title">{t('privacyModal.terms.title2')}</div>
                <div className="description">
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.terms.parag2_1.part2')}
                </div>
                <div className="description">
                    {t('privacyModal.terms.parag2_2.part1')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.terms.parag2_2.part2')}
                </div>
                <div className="main-title">{t('privacyModal.terms.title3')}</div>
                <div className="description">{t('privacyModal.terms.parag3_1')}</div>
                <div className="description">{t('privacyModal.terms.parag3_2')}</div>
                <div className="description">
                    {t('privacyModal.terms.parag3_3.part1')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.terms.parag3_3.part2')}
                </div>
                <div className="description">
                    {t('privacyModal.terms.parag3_4')}
                    <a href="#a" onClick={() => this.onTabChange(TabType.COOKIES_POLICY)} data-testid="cookies-link">
                        {t('privacyModal.cookiesTitle')}
                    </a>
                    .
                </div>
                <div className="main-title">{t('privacyModal.terms.title4')}</div>
                <div className="description">{t('privacyModal.terms.parag4_1')}</div>
                <div className="description">
                    {t('privacyModal.terms.parag4_2.part1')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.terms.parag4_2.part2')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.terms.parag4_2.part3')}
                </div>
                <div className="main-title">{t('privacyModal.terms.title5')}</div>
                <div className="description">
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.terms.parag5_1.part2')}
                </div>
                <div className="description">{t('privacyModal.terms.parag5_2')}</div>
                <div className="description">
                    {t('privacyModal.terms.parag5_3')}
                    <a href={`mailto:${t(this.privacyEmail)}`}>{t(this.privacyEmail)}</a>
                </div>
                <div className="main-title">{t('privacyModal.terms.title6')}</div>
                <ul className="description">
                    <li><strong>{t('privacyModal.terms.parag6_1.part1_1')}</strong>&#32;{t('privacyModal.terms.parag6_1.part1_2')}</li>
                    <li><strong>{t('privacyModal.terms.parag6_1.part2_1')}</strong>&#32;{t('privacyModal.terms.parag6_1.part2_2')}</li>
                    <li><strong>{t('privacyModal.terms.parag6_1.part3_1')}</strong>&#32;{t('privacyModal.terms.parag6_1.part3_2')}</li>
                    <li><strong>{t('privacyModal.terms.parag6_1.part4_1')}</strong>&#32;<a href={`mailto:${t(this.privacyEmail)}`}>{t(this.privacyEmail)}</a></li>
                </ul>
            </div>
        );
    };

    renderAboutUs = (): React.ReactNode => {
        const { t } = this.props;
        return (
            <div className="modal__content__termsConditions">
                <div className="main-title">{t('privacyModal.aboutUs.title1')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag1_1')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag1_2')}</div>
                <div className="main-title">{t('privacyModal.aboutUs.title2')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag2_1')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag2_2')}</div>
                <ul>
                    <li className="description">{t('privacyModal.aboutUs.bullet2_1')}</li>
                    <li className="description">{t('privacyModal.aboutUs.bullet2_2')}</li>
                    <li className="description">{t('privacyModal.aboutUs.bullet2_3')}</li>
                </ul>
                <div className="main-title">{t('privacyModal.aboutUs.title3')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag3_1')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag3_2')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag3_3')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag3_4')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag3_5')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag3_6')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag3_7')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag3_8')}</div>
                <div className="main-title">{t('privacyModal.aboutUs.title4')}</div>
                <div className="sub-title">{t('privacyModal.aboutUs.title4_1')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag4_1_1')}</div>
                <div className="sub-title">{t('privacyModal.aboutUs.title4_2')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag4_2_1')}</div>
                <div className="sub-title">{t('privacyModal.aboutUs.title4_3')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag4_3_1')}</div>
                <div className="sub-title">{t('privacyModal.aboutUs.title4_4')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag4_4_1')}</div>
                <div className="main-title">{t('privacyModal.aboutUs.title5')}</div>
                <div className="sub-title">{t('privacyModal.aboutUs.title5_1')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag5_1_1')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag5_1_2')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag5_1_3')}</div>
                <div className="sub-title">{t('privacyModal.aboutUs.title5_2')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag5_2_1')}</div>
                <div className="sub-title">{t('privacyModal.aboutUs.title5_3')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag5_3_1')}</div>
                <div className="main-title">{t('privacyModal.aboutUs.title6')}</div>
                <div className="description">{t('privacyModal.aboutUs.parag6_1')}</div>
            </div>
        );
    };

    renderPrivacyPolicy = (): React.ReactNode => {
        const { t } = this.props;
        return (
            <div className="modal__content__termsConditions">
                <div className="description">
                    {t('privacyModal.privacy.parag1.part1')}
                    <a href="www.sportrack.io">www.sportrack.io</a>
                    {t('privacyModal.privacy.parag1.part2')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.privacy.parag1.part3')}
                </div>
                <div className="description">
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.privacy.parag2.part2')}
                </div>
                <div className="description">
                    {t('privacyModal.privacy.parag3')}
                    <a href={`mailto:${t(this.privacyEmail)}`}>{t(this.privacyEmail)}</a>.
                </div>
                <div className="description">
                    {t('privacyModal.privacy.parag4.part1')}
                    <a href="#a" onClick={() => this.onTabChange(TabType.TERMS_CONDITIONS)}>
                        {t('privacyModal.termsTitle')}
                    </a>
                    {t('privacyModal.privacy.parag4.part2')}
                    <a href="#a" onClick={() => this.onTabChange(TabType.COOKIES_POLICY)} data-testid="cookies-link">
                        {t('privacyModal.cookiesTitle')}
                    </a>
                    .
                </div>
                <div className="main-title">{t('privacyModal.privacy.title1')}</div>
                <div className="description">
                    {t('privacyModal.privacy.parag1_1.part1')}
                    <i>{t('privacyModal.privacy.parag1_1.part2')}</i>
                </div>
                <div className="main-title">{t('privacyModal.privacy.title2')}</div>
                <div className="description">
                    {t('privacyModal.privacy.parag2_1.part1')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.privacy.parag2_1.part2')}
                </div>
                <div className="description">
                    {t('privacyModal.privacy.parag2_2.part1')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.privacy.parag2_2.part2')}
                </div>
                <div className="description">
                    {t('privacyModal.privacy.parag2_3.part1')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.privacy.parag2_3.part2')}
                </div>
                <div className="description">{t('privacyModal.privacy.parag2_4')}</div>
                <div className="description">
                    {t('privacyModal.privacy.parag2_5.part1')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.privacy.parag2_5.part2')}
                </div>
                <div className="main-title">{t('privacyModal.privacy.title3')}</div>
                <div className="description">{t('privacyModal.privacy.parag3_1')}</div>
                <div className="main-title">{t('privacyModal.privacy.title4')}</div>
                <div className="description">
                    {t('privacyModal.privacy.parag4_1.part1')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.privacy.parag4_1.part2')}
                </div>
                <div className="description">{t('privacyModal.privacy.parag4_2')}</div>
                <div className="main-title">{t('privacyModal.privacy.title5')}</div>
                <div className="description">{t('privacyModal.privacy.parag5_1')}</div>
                <div className="description">
                    {t('privacyModal.privacy.parag5_2')}
                    <a href="mailto:info@sportrack.io">info@sportrack.io</a>.
                </div>
                <div className="description">
                    {t('privacyModal.privacy.parag5_3')}
                    <a href="https://www.cnpd.pt/">https://www.cnpd.pt/</a>.
                </div>
                <div className="main-title">{t('privacyModal.privacy.title6')}</div>
                <div className="description">
                    {t('privacyModal.privacy.parag6_1.part1')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.privacy.parag6_1.part2')}
                </div>
                <div className="description">
                    {t('privacyModal.privacy.parag6_2.part1')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.privacy.parag6_2.part2')}
                </div>
                <div className="description">
                    {t('privacyModal.privacy.parag6_3.part1')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.privacy.parag6_3.part2')}
                </div>
                <div className="main-title">{t('privacyModal.privacy.title7')}</div>
                <div className="description">{t('privacyModal.privacy.parag7_1')}</div>
                <div className="main-title">{t('privacyModal.privacy.title8')}</div>
                <div className="description">
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.privacy.parag8_1.part2')}
                </div>
                <div className="description">
                    {t('privacyModal.privacy.parag8_2.part1')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.privacy.parag8_2.part2')}
                </div>
                <ul>
                    <li className="description">{t('privacyModal.privacy.bullet8_1')}</li>
                    <li className="description">{t('privacyModal.privacy.bullet8_2')}</li>
                    <li className="description">{t('privacyModal.privacy.bullet8_3')}</li>
                    <li className="description">{t('privacyModal.privacy.bullet8_4')}</li>
                </ul>
                <div className="main-title">{t('privacyModal.privacy.title9')}</div>
                <div className="description">
                    {t('privacyModal.privacy.parag9_1.part1')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.privacy.parag9_1.part2')}
                </div>
                <div className="main-title">{t('privacyModal.privacy.title10')}</div>
                <div className="description">
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.privacy.parag10_1.part2')}
                </div>
            </div>
        );
    };
    
    renderGenderEqualityPlan = (): React.ReactNode => {
        const { t } = this.props;
        return (
            <div className="modal__content__termsConditions">
                <div className="main-title">{t('privacyModal.gep.title')}</div>
                <div className="description">{t('privacyModal.gep.parag')}</div>
                <div className="main-title">{t('privacyModal.gep.title1')}</div>
                <div className="description">{t('privacyModal.gep.parag1_1')}</div>
                <div className="description">{t('privacyModal.gep.parag1_2')}</div>
                <div className="description">{t('privacyModal.gep.parag1_3')}</div>
                <div className="main-title">{t('privacyModal.gep.title2')}</div>
                <div className="description">{t('privacyModal.gep.parag2_1')}</div>
                <div className="description">{t('privacyModal.gep.parag2_2')}</div>
                <div className="description">{t('privacyModal.gep.parag2_3')}</div>
                <div className="main-title">{t('privacyModal.gep.title3')}</div>
                <div className="description">{t('privacyModal.gep.parag3_1')}</div>
                <div className="description">{t('privacyModal.gep.parag3_2')}</div>
                <div className="description">{t('privacyModal.gep.parag3_3')}</div>
                <div className="main-title">{t('privacyModal.gep.title4')}</div>
                <div className="description">{t('privacyModal.gep.parag4_1')}</div>
                <div className="description">{t('privacyModal.gep.parag4_2')}</div>
                <div className="description">{t('privacyModal.gep.parag4_3')}</div>
                <div className="main-title">{t('privacyModal.gep.title5')}</div>
                <div className="description">{t('privacyModal.gep.parag5_1')}</div>
                <div className="description">{t('privacyModal.gep.parag5_2')}</div>
                <div className="description">{t('privacyModal.gep.parag5_3')}</div>
                <div className="main-title">{t('privacyModal.gep.title6')}</div>
                <div className="description">{t('privacyModal.gep.parag6_1')}</div>
                <div className="description">{t('privacyModal.gep.parag6_2')}</div>
                <div className="main-title">{t('privacyModal.gep.title7')}</div>
                <div className="description">{t('privacyModal.gep.parag7_1')}</div>
                <div className="description">{t('privacyModal.gep.parag7_2')}</div>
                <div className="main-title">{t('privacyModal.gep.title8')}</div>
                <div className="description">{t('privacyModal.gep.parag8_1')}</div>
                <div className="description">{t('privacyModal.gep.parag8_2')}</div>
                <div className="main-title">{t('privacyModal.gep.title9')}</div>
                <div className="description">{t('privacyModal.gep.parag9_1')}</div>
            </div>
        );
    };

    renderCookiePolicy = (): React.ReactNode => {
        const { t } = this.props;
        return (
            <div className="modal__content__termsConditions">
                <div className="description">
                    {t('privacyModal.cookies.parag1.part1')}
                    <strong>{t(this.sportrack)}</strong>
                    {t('privacyModal.cookies.parag1.part2')}
                </div>
                <div className="description">{t('privacyModal.cookies.parag2')}</div>
                <div className="description">{t('privacyModal.cookies.parag3')}</div>
                <ul>
                    <li className="description">{t('privacyModal.cookies.parag4')}</li>
                    <li className="description">{t('privacyModal.cookies.parag5')}</li>
                    <li className="description">{t('privacyModal.cookies.parag6')}</li>
                </ul>
                <div className="description">{t('privacyModal.cookies.parag7')}</div>
            </div>
        );
    };

    render() {
        const { close, inRegister, t } = this.props;

        const { tabItems, selectedTab } = this.state;

        const s = inRegister ? { background: 'transparent' } : {};

        return (
            <div className="modal terms-conditions">
                <div className="modal__backdrop" style={s} />
                <div className="modal__content">
                    <button
                        type="button"
                        className="modal__content__close modal__content__termsConditions"
                        data-testid="modal-close"
                        onClick={() => close()}
                    >
                        <IconTimes fill="#206259" />
                    </button>
                    <div className="modal__content__termsConditions">
                        <img src={logo} alt="" />
                        <div className="line" />
                    </div>
                    <div className="tabs">
                        {tabItems.map((tab) => {
                            const { text, value } = tab;
                            return (
                                <div key={value} className="tab-item" onClick={() => this.onTabChange(value)}>
                                    <div className={`${tab === selectedTab ? 'title-active' : 'title'}`}>{t(text)}</div>
                                    <div className={`${tab === selectedTab ? 'active' : ''}`}>
                                        <div className="active-start" />
                                        <div className="active-end" />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {selectedTab.value === TabType.ABOUT_US && this.renderAboutUs()}
                    {selectedTab.value === TabType.TERMS_CONDITIONS && this.renderTermsAndConditions()}
                    {selectedTab.value === TabType.PRIVACY_POLICY && this.renderPrivacyPolicy()}
                    {selectedTab.value === TabType.GENDER_EQUALITY_PLAN && this.renderGenderEqualityPlan()}
                    {selectedTab.value === TabType.COOKIES_POLICY && this.renderCookiePolicy()}
                </div>
            </div>
        );
    }
}

export default withTranslationContext(TermsAndConditionsScreen);
