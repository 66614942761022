/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import axios from 'axios';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import logo from '../../assets/images/logo_white.svg';
import runningImage from '../../assets/images/running.png';
import modalSlant from '../../assets/images/modal_slant.svg';
import FormField from '../elements/FormField';
import { RegistrationFormFields, RegistrationPostData } from '../../constants/authentication';
import { DropdownOption, KeyedObject } from '../../constants/misc';
import { displayError, displaySuccess } from '../../utils/notifications';
import { termsURL } from '../../services/terms';
import { Terms } from '../../constants/terms';
import { ErrorCode } from '../../constants/errors';
import IconTimes from '../assets/IconTimes';
import TermsAndConditionsScreen from './TermsAndConditionsScreen';
import { TabType } from '../../utils/misc';
import Dropdown from '../elements/Dropdown';
import { enumToOptions } from '../../utils/dropdown';
import { sportsURL } from '../../services/utils';
import { UserTypes } from '../../constants/user';
import { CREDIT_ROUTE, VALIDATE_ACCOUNT_ROUTE } from '../../constants/routes';
import { RECAPTCHA_KEY } from '../../settings';
import SelectCountry from '../elements/SelectCountry';

/**
 * @typedef {Object} OwnProps
 * @extends {TranslationContext}
 * @property {boolean} isFetching
 * @property {Function} close
 * @property {Function} submit
 * @property {Function} validate
 */
interface OwnProps extends RouteComponentProps, TranslationContext {
    isFetching: boolean;
    close: Function;
    submit: Function;
    validate: Function;
    userTypeSelected?: UserTypes | null;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 * @property {boolean} agree
 * @property {RegistrationFormFields} fields
 * @property {KeyedObject} [errors]
 * @property {Terms} [terms]
 * @property {boolean} showTermsAndConditions
 * @property {string} userType
 */
interface OwnState {
    agree: boolean;
    fields: RegistrationFormFields;
    errors: KeyedObject | null;
    terms: Terms | null;
    showTermsAndConditions: boolean;
    userType: DropdownOption | null;
    modality: DropdownOption | null;
    userTypeOptions: DropdownOption[];
    sportsOptions: DropdownOption[];
    hasReCaptchaError: boolean;
    recaptchaResponse: string;
}

const initialState: OwnState = {
    agree: false,
    userType: null,
    modality: null,
    fields: {
        name: '',
        lastName: '',
        countryCode: null,
        fiscalCode: '',
        postalCode: '',
        address: '',
        password: '',
        cpassword: '',
        email: '',
        modality: '',
        recaptcha: '',
    },
    errors: null,
    terms: null,
    showTermsAndConditions: false,
    userTypeOptions: [],
    sportsOptions: [],
    hasReCaptchaError: false,
    recaptchaResponse: '',
};

/**
 * @typedef {Object} State
 */
type State = OwnState;

/**
 * shows the login screen
 * @extends {Component<Props, State>}
 */
class RegistrationScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount() {
        const { t, userTypeSelected } = this.props;

        const userTypeOptions = [
            { label: t(`registration.${UserTypes.Athlete}`), value: UserTypes.Athlete },
            { label: t(`registration.${UserTypes.Fan}`), value: UserTypes.Fan },
            { label: t(`registration.${UserTypes.Sponsor}`), value: UserTypes.Sponsor },
            { label: t(`registration.${UserTypes.Manager}`), value: UserTypes.Manager },
        ];

        const userType = userTypeSelected ? userTypeOptions.find((opt) => opt.value === userTypeSelected) : null;

        this.setState({
            userTypeOptions,
            userType: userType || null,
        });

        this.prepare();
    }

    /**
     * handles input change
     * @param {React.FormEvent<HTMLInputElement>} e
     */
    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    /**
     * checkbox change handler
     * @param {React.FormEvent<HTMLInputElement>} e
     */
    onCheckChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.checked,
        });
    };

    /**
     * handles change on dropdown
     * @param {DropdownOption} [option]
     */
    onDropDownChange = (option: DropdownOption, name: string): void => {
        const { fields } = this.state;

        if (name === 'modality') {
            this.setState({
                modality: option,
                fields: {
                    ...fields,
                    modality: option.value,
                },
            });
            return;
        }
        
        if (name === 'userType') {
            this.setState({
                userType: option,
            });
            return;
        }

        this.setState({
            fields: {
                ...fields,
                [name]: option,
            },
        });
    };

    /**
     * handles not implemented link click
     * @param {React.MouseEvent} e
     */
    onInvalidClick = (e: React.MouseEvent) => {
        const { t } = this.props;
        e.preventDefault();
        displayError({ message: t('general.unavailable') });
    };

    /**
     * handles form submit
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { submit, validate, t } = this.props;
        const {
            fields, agree, terms, userType, modality, recaptchaResponse,
        } = this.state;

        if (!recaptchaResponse) {
            this.setState({
                hasReCaptchaError: true,
            });
            return;
        }

        if (agree) {
            const errors = validate(fields, userType?.value);
            
            this.setState(
                { errors },
                () => {
                    if (!errors && userType) {
                        const formData: RegistrationPostData = {
                            name: fields.name,
                            lastName: fields.lastName,
                            countryCode: fields.countryCode?.value,
                            fiscalCode: fields.fiscalCode,
                            postalCode: fields.postalCode,
                            address: fields.address,
                            password: fields.password,
                            email: fields.email,
                            acceptedTermsOfUse: terms ? terms.version : '',
                            modality: modality ? String(modality?.value) : '',
                            recaptcha: recaptchaResponse,
                        };
                        submit(formData, userType.value, this.onSuccess, this.onFailure);
                    }
                },
            );
        } else {
            displayError({
                message: t('registration.accept'),
            });
        }
    };

    /**
     * handles open terms and conditions
     */
    onTermsAndConditionsClick = () => {
        this.setState({
            showTermsAndConditions: true,
        });
    };

    /**
     * handles login success
     */
    onSuccess = () => {
        const { t, history, close } = this.props;

        this.setState({
            fields: initialState.fields,
            errors: null,
            agree: false,
        }, () => {
            displaySuccess({
                message: t('registration.link'),
            });
            close();
            history.push(CREDIT_ROUTE, { from: VALIDATE_ACCOUNT_ROUTE });
        });
    };

    /**
     * handles login failure
     * @param {KeyedObject} errors
     */
    onFailure = (errors: KeyedObject) => {
        const { t } = this.props;

        if (errors.fields) {
            this.setState({
                errors: errors.fields,
            });
        }

        if (errors.errors) {
            errors.errors.forEach((error: any) => {
                displayError({
                    message: t(`errors.${ErrorCode[error.errorCode]}`),
                });
            });
        }
    };

    onRecaptchaVerify = (response: string | null) => {
        if (response) {
            this.setState({
                ...this.state,
                hasReCaptchaError: false,
                recaptchaResponse: response,
            });
        } else {
            this.setState({
                ...this.state,
                hasReCaptchaError: true,
            });
        }
    }

    /**
     * prepares data for the view
     */
    prepare = async () => {
        try {
            const { data } = await axios.get(termsURL());
            const sports = await axios.get(sportsURL());
            const sportsOptions = enumToOptions(sports.data, true);
                            
            this.setState({
                terms: data,
                sportsOptions,
            });
        } catch {}
    };

    render() {
        const {
            close, isFetching, t, userTypeSelected,
        } = this.props;
        const {
            agree, fields, errors, showTermsAndConditions, userType, userTypeOptions, sportsOptions, modality, hasReCaptchaError,
        } = this.state;

        return (
            <Fragment>
                <div className="modal registration-screen" data-testid="registration-screen">
                    <div className="modal__backdrop" />
                    <div className="modal__content">
                        <button
                            type="button"
                            className="modal__content__close"
                            data-testid="close-modal"
                            onClick={() => close()}
                        >
                            <IconTimes fill="#FFF" />
                        </button>
                        <div className="modal__content__top">
                            <div className="modal__content__top__header">
                                <img src={logo} className="logo" alt="" />
                                <img src={runningImage} className="running" alt="" />
                                <img src={modalSlant} className="slant" alt="" />
                            </div>
                            <div className="modal__content__top__subheader">
                                <h1>{t('registration.title')}</h1>
                                <p>{t('registration.description')}</p>
                            </div>
                        </div>
                        <form onSubmit={this.onSubmit}>
                            <div className="row">
                                <div className="col-sm-6 col-xs-12">
                                    <Dropdown
                                        label={t('registration.userType')}
                                        name="userType"
                                        options={userTypeOptions}
                                        value={userType}
                                        onChange={this.onDropDownChange}
                                        disabled={isFetching || !!userTypeSelected}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    <FormField
                                        label={t('registration.email')}
                                        type="text"
                                        name="email"
                                        value={fields.email}
                                        onChange={this.onInputChange}
                                        placeholder=""
                                        disabled={isFetching}
                                        errors={errors}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6 col-xs-12">
                                    <FormField
                                        label={t('registration.name')}
                                        type="text"
                                        name="name"
                                        value={fields.name}
                                        onChange={this.onInputChange}
                                        placeholder=""
                                        disabled={isFetching}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    {(userType?.value === UserTypes.Athlete || userType?.value === UserTypes.Manager) && (
                                        <FormField
                                            label={t('registration.lastName')}
                                            type="text"
                                            name="lastName"
                                            value={fields.lastName}
                                            onChange={this.onInputChange}
                                            placeholder=""
                                            disabled={isFetching}
                                            errors={errors}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6 col-xs-12">
                                    <SelectCountry
                                        name="countryCode"
                                        label={t('registration.country')}
                                        value={fields.countryCode}
                                        onChange={this.onDropDownChange}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    <FormField
                                        label={t('registration.postalCode')}
                                        type="text"
                                        name="postalCode"
                                        value={fields.postalCode}
                                        onChange={this.onInputChange}
                                        placeholder=""
                                        disabled={isFetching}
                                        errors={errors}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <FormField
                                        label={t('registration.fiscalCode')}
                                        type="text"
                                        name="fiscalCode"
                                        value={fields.fiscalCode}
                                        onChange={this.onInputChange}
                                        placeholder=""
                                        disabled={isFetching}
                                        errors={errors}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <FormField
                                        label={t('registration.address')}
                                        type="text"
                                        name="address"
                                        value={fields.address}
                                        onChange={this.onInputChange}
                                        placeholder=""
                                        disabled={isFetching}
                                        errors={errors}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6 col-xs-12">
                                    <FormField
                                        label={t('registration.password')}
                                        type="password"
                                        name="password"
                                        value={fields.password}
                                        onChange={this.onInputChange}
                                        placeholder=""
                                        disabled={isFetching}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    <FormField
                                        label={t('registration.repeat')}
                                        type="password"
                                        name="cpassword"
                                        value={fields.cpassword}
                                        onChange={this.onInputChange}
                                        placeholder=""
                                        disabled={isFetching}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                            {
                                (userType?.value === UserTypes.Manager || userType?.value === UserTypes.Athlete) && (
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12">
                                            <Dropdown
                                                label={t('profile.sport')}
                                                name="modality"
                                                options={sportsOptions}
                                                value={modality}
                                                onChange={this.onDropDownChange}
                                                errors={errors}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            <div className="recaptcha">
                                <ReCAPTCHA
                                    sitekey={String(RECAPTCHA_KEY)}
                                    onChange={this.onRecaptchaVerify}
                                />
                                <p className={hasReCaptchaError ? 'recaptcha-error' : ''}>
                                    {t('registration.reCaptchaError')}
                                </p>

                            </div>
                            <div className="row">
                                <div className="register-button-container">
                                    <button
                                        type="submit"
                                        className="btn btn--primary-inverse btn--block"
                                        disabled={isFetching}
                                    >
                                        {t('registration.send')}
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className="modal__content__bottom">
                            <label className="control control--checkbox">
                                <input
                                    name="agree"
                                    type="checkbox"
                                    checked={agree}
                                    onChange={this.onCheckChange}
                                    disabled={isFetching}
                                />
                                <div className="indicator" />
                                <p>{t('registration.read')}&nbsp;
                                    <a href="#a" onClick={this.onTermsAndConditionsClick}>
                                        {t('registration.terms')}
                                    </a>
                                </p>
                            </label>
                            <p>{t('registration.link')}</p>
                            <p>
                                <a href="#a" onClick={this.onInvalidClick}>
                                    {t('registration.resend')}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                {showTermsAndConditions && (
                    <TermsAndConditionsScreen
                        close={() => this.setState({ showTermsAndConditions: false })}
                        tab={TabType.TERMS_CONDITIONS}
                        inRegister
                    />
                )}
            </Fragment>
        );
    }
}

export default withRouter(withTranslationContext(RegistrationScreen));
