/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export interface KeyedObject {
    [key: string]: any;
}

export interface ApiError {
    fields?: KeyedObject;
    errors?: KeyedObject[];
}

export type DropdownOption = {
    value: any;
    label: any;
}

export interface MatchParams {
    id?: string;
    name?: string;
    mediaId?: string;
    status?: string;
}

export interface Media {
    id: number;
    mediaType: MediaType;
    main: string;
    mainMimeType: string;
    thumbnail: string;
}

export interface List<T> {
    list: T[];
    total: number;
}

export enum Direction {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
}

export enum MediaType {
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
}

export enum Environment {
    DEV = 'development',
    TEST = 'test',
    RELEASE = 'release',
    PRODUCTION = 'production',
}

export const BLISSCARE_PRODUCTION_ID = 349958;
export const BLISSCARE_RELEASE_ID = 100052;
export const BLISSCARE_TEST_ID = 153956;

export const SHOW_SPONSORS_SECTION = false;

export const SHOW_ATHLETE_PLANS_SECTION = false;

export const TOTAL_ITEMS = 'x-total-count';

export const ADD_NEW_CLUB = 'ADD_NEW_CLUB_BUTTON';

export const INSTRUCTION = 'INSTRUCTION';

export type EmptyObject = Record<string, unknown>;
