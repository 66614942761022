/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import axios from 'axios';
import { MatchParams } from '../../constants/misc';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { withUserContext, UserContext } from '../controllers/user/UserContext';
import { usersURL } from '../../services/users';
import { User, UserTypes } from '../../constants/user';
import defaultAvatar from '../../assets/images/avatar_default.svg';
import { displayError } from '../../utils/notifications';
import { ATHLETE_ROUTE, SPONSOR_ROUTE } from '../../constants/routes';

/**
 * @typedef {Object} OwnProps
 * @extends {RouteComponentProps<MatchParams>, AuthenticationContext}
 */
interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContext, UserContext, TranslationContext {}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 * @property {User[]} athletes
 * @property {User[]} sponsors
 * @property {number} totalAthletes
 * @property {number} totalSponsors
 * @property {number} step
 * @property {boolean} canFetch
 * @property {string} selectedTab
 */
interface OwnState {
    athletes: User[];
    sponsors: User[];
    totalAthletes: number;
    totalSponsors: number;
    step: number;
    canFetch: boolean;
    selectedTab: string;
}

/**
 * @typedef {Object} State
 */
type State = OwnState;

const initialState: State = {
    athletes: [],
    sponsors: [],
    totalAthletes: 0,
    totalSponsors: 0,
    step: 0,
    canFetch: true,
    selectedTab: '',
};

/**
 * shows the initial screen
 * @extends {Component<Props, State>}
 */
class RedeSportrack extends Component<Props, State> {
    state = initialState;

    private readonly athletesTabLabel = 'network.athletes.tab';

    componentDidMount() {
        const { t } = this.props;
        const screenWidth = window.screen.width;
        const isDesktop = screenWidth > 768;

        this.setState({
            selectedTab: isDesktop ? t('network.all') : t(this.athletesTabLabel),
        });

        window.addEventListener('scroll', this.onScroll);
        this.fetchUsers();
    }

    /**
     * handles on scroll event and check if is end of the page
     */
    onScroll = (): void => {
        const { canFetch } = this.state;
        const offset = 200;
        if (window.innerHeight + window.scrollY >= document.body.scrollHeight - offset) {
            if (canFetch) this.setState({ canFetch: false }, this.fetchUsers);
        } else {
            if (!canFetch) {
                this.setState({
                    canFetch: true,
                });
            }
        }
    };

    /**
     * handles athlete card click
     * @param {number} id
     */
    onAthleteClick = (id: number): void => {
        const { history } = this.props;

        history.push(`${ATHLETE_ROUTE}/${id}`);
    };

    /**
     * handles sponsor card click
     * @param {number} id
     */
    onSponsorClick = (id: number): void => {
        const { history } = this.props;

        history.push(`${SPONSOR_ROUTE}/${id}`);
    };

    /**
     * handles tab click
     * @param {number} id
     */
    onTabChange = (tab: string): void => {
        this.setState({
            selectedTab: tab,
        });
    };

    getTabs = (): string[] => {
        const { t } = this.props;
        const screenWidth = window.screen.width;
        const isDesktop = screenWidth > 768;
        const tabs = [t(this.athletesTabLabel), t('network.sponsors.tab')];

        return isDesktop ? [t('network.all'), ...tabs] : tabs;
    };

    /**
     * appends data for the view
     */
    fetchUsers = async (): Promise<void> => {
        const { t } = this.props;
        const { step, athletes, sponsors } = this.state;

        const urls = [
            usersURL({ type: UserTypes.Athlete, _limit: 20, _page: step }),
            usersURL({ type: UserTypes.Sponsor, _limit: 20, _page: step }),
        ];
        const nextStep = step + 1;

        axios
            .all(urls.map((url) => axios.get(url)))
            .then(
                axios.spread((athleteResponse, sponsorsResponse) => {
                    const athletesMore = athleteResponse.data.filter((athlete: User) => !athlete.hiddenProfile);
                    const totalAthletes = athleteResponse.headers['x-total-count'];
                    const sponsorsMore = sponsorsResponse.data.filter((sponsor: User) => !sponsor.hiddenProfile);
                    const totalSponsors = sponsorsResponse.headers['x-total-count'];

                    this.setState({
                        athletes: [...athletes, ...athletesMore],
                        sponsors: [...sponsors, ...sponsorsMore],
                        step: nextStep,
                        totalAthletes,
                        totalSponsors,
                    });
                }),
            )
            .catch(() => {
                displayError({ message: t('general.incomplete') });
            });
    };

    /**
     * renders athletes column
     */
    renderAthletesColumn = (): React.ReactNode => {
        const { athletes } = this.state;
        const { t } = this.props;

        const rowElements = [];

        const itemUser = (user: User): React.ReactNode => {
            if (!user || user.hiddenProfile) return <React.Fragment />;
            return (
                <div
                    data-testid="athlete"
                    key={user.id}
                    className="rede-sportrack-screen__body__athletes__row__item"
                    onClick={() => this.onAthleteClick(user.id)}
                >
                    <hr />
                    <div className="rede-sportrack-screen__body__athletes__row__item__info">
                        <img src={user.avatar?.main || defaultAvatar} alt="" />
                        <span className="rede-sportrack-screen__body__athletes__row__item__info__name">{`${user.name} ${user.lastName}`}</span>
                        <span className="rede-sportrack-screen__body__athletes__row__item__info__sport">
                            {user.club ? t(`sports.${user.club.clubCompositeKey.sport}`) : t('sports.NA')}
                        </span>
                    </div>
                </div>
            );
        };

        for (let index = 0; index < athletes.length; index += 2) {
            rowElements.push(
                <div key={athletes[index].id} className="rede-sportrack-screen__body__athletes__row">
                    {itemUser(athletes[index])}
                    {itemUser(athletes[index + 1])}
                </div>,
            );
        }

        return rowElements;
    };

    /**
     * renders sponsor column
     */
    renderSponsorsColumn = (): React.ReactNode => {
        const { sponsors } = this.state;

        return sponsors.filter((sponsor) => !sponsor.hiddenProfile).map((sponsor) => {
            return (
                <div
                    data-testid="sponsor"
                    key={sponsor.id}
                    className="rede-sportrack-screen__body__sponsors__item"
                    onClick={() => this.onSponsorClick(sponsor.id)}
                >
                    <hr />
                    <div className="rede-sportrack-screen__body__sponsors__item__info">
                        <img src={sponsor.avatar?.main} alt="" />
                        <div className="rede-sportrack-screen__body__sponsors__item__info__statistics">
                            <span>{sponsor.name}</span>
                            <div />
                        </div>
                    </div>
                </div>
            );
        });
    };

    renderTabs = () => {
        const { selectedTab } = this.state;
        const tabs = this.getTabs();
        return (
            <div className="sportrack-tabs">
                <div className="sportrack-tabs__tabs-container underline-tabs">
                    {tabs.map((tab) => (
                        <span
                            key={tab}
                            onClick={() => this.onTabChange(tab)}
                            className={`${tab === selectedTab ? 'tab tab--active' : 'tab'}`}
                        >
                            {tab}
                        </span>
                    ))}
                </div>
            </div>
        );
    };

    render() {
        const { t } = this.props;
        const { totalSponsors, totalAthletes, selectedTab } = this.state;

        return (
            <div className="rede-sportrack-screen" data-testid="rede-sportrack-screen">
                {this.renderTabs()}
                <div className="rede-sportrack-screen__body">
                    {selectedTab !== t('network.sponsors.tab') && (
                        <div className="rede-sportrack-screen__body__athletes">
                            <span className="title title--athletes">{t('network.athletes.label')}</span>
                            <span className="rede-sportrack-screen__body__athletes__counter">
                                {t('network.athletes.counter', { totalAthletes })}
                            </span>
                            {this.renderAthletesColumn()}
                        </div>
                    )}
                    {selectedTab !== t(this.athletesTabLabel) && (
                        <div className="rede-sportrack-screen__body__sponsors">
                            <span className="title title--sponsors">{t('network.sponsors.label')}</span>
                            <span className="rede-sportrack-screen__body__sponsors__counter">
                                {t('network.sponsors.counter', { totalSponsors })}
                            </span>
                            {this.renderSponsorsColumn()}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslationContext(withUserContext(withAuthenticationContext(withRouter(RedeSportrack))));
