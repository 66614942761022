import { API_URL } from '../settings';

const api = 'achievements';

/**
 * Athlete Achievements URL
 * @returns {string}
 */
export const achievementsURL = (): string => {
    return `${API_URL}/${api}`;
};

/**
 * Athlete Achievement URL
 * @param {number | string} id
 * @returns {string}
 */
export const achievementURL = (id: React.Key): string => {
    return `${API_URL}/${api}/${id}`;
};
