/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';

import { ReactSVG } from 'react-svg';
import { ReceiveMessageDetails } from '@void/utils/websockets';
import { isEqual } from 'lodash';
import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { UserContext, withUserContext } from '../../controllers/user/UserContext';
import DrawerGallery from '../../elements/DrawerGallery';
import ButtonContact from '../../elements/ButtonContact';
import ButtonShare from '../../elements/ButtonShare';
import Avatar from '../../elements/Avatar';
import iconPin from '../../../assets/images/icon_pin.svg';
import FormField from '../../elements/FormField';
import { userURL } from '../../../services/users';
import { FanProfileFormFields, User, UserRoles } from '../../../constants/user';
import { DropdownOption, KeyedObject, MatchParams } from '../../../constants/misc';
import { FAN_ROUTE, INDEX_ROUTE } from '../../../constants/routes';
import { displayError, displaySuccess } from '../../../utils/notifications';
import { ErrorCode } from '../../../constants/errors';
import loader from '../../../assets/images/loader.svg';
import { SportestContext, withSportestContext } from '../../controllers/sportest/SportestContext';
import { Sportest } from '../../../constants/sportest';
import { SportTestBadge } from '../../elements/SportTestBadge';
import CoverImage from '../../elements/CoverImage';
import UserWallet from '../../elements/UserWallet';
import { IconGlobe } from '../../assets/IconGlobe';
import SelectCountry from '../../elements/SelectCountry';
import { IconBank } from '../../assets/IconBank';
import { IconHome } from '../../assets/IconHome';
import SponsorshipManagerTable from '../../elements/SponsorshipManagerTable';
import { SponsorAthletesSupport } from '../../../constants/sponsor';
import { SponsorContext, withSponsorContext } from '../../controllers/sponsor/SponsorContext';
import { SocketEventType } from '../../../types/websockets';
import { Receive, WS } from '../../../utils/websocket';
import { TableActionType } from '../../../utils/table';
import RouteLeavingGuard from '../RouteLeavingGuard';

/**
 * @typedef {Object} OwnProps
 * @extends {RouteComponentProps<MatchParams>, AuthenticationContext, TranslationContext, UserContext}
 */
interface OwnProps
    extends RouteComponentProps<MatchParams>,
        AuthenticationContext,
        TranslationContext,
        UserContext,
        SponsorContext,
        SportestContext {}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 * @property {File} [cover]
 * @property {File} [avatar]
 * @property {boolean} showGallery
 * @property {User} [userData]
 * @property {ProfileFormFields} fields
 * @property {KeyedObject} [errors]
 * @property {boolean} [isFetching]
 * @property {boolean} [isFetchingAvatar]
 * @property {Sportest | null} lastSportest
 */
interface OwnState {
    changesMade: boolean;
    cover: any;
    avatar: any;
    showGallery: boolean;
    userData: User | null;
    fields: FanProfileFormFields;
    errors: KeyedObject | null;
    isFetching: boolean;
    isFetchingAvatar: boolean;
    lastSportest: Sportest | null;
    athletesSupportList: SponsorAthletesSupport[];
    totalAthletesSupport: number;
    paramsSponsorshipFan: Record<string, number>;
    isSocketConnecting: boolean;
}

/**
 * @typedef {Object} State
 */
type State = OwnState;

const initialState: State = {
    changesMade: false,
    cover: null,
    avatar: null,
    showGallery: false,
    userData: null,
    fields: {
        name: '',
        countryCode: null,
        postalCode: '',
        fiscalCode: '',
        address: '',
        phrase: '',
        biography: '',
        avatarId: null,
        coverId: null,
    },
    errors: null,
    isFetching: false,
    isFetchingAvatar: false,
    lastSportest: null,
    athletesSupportList: [],
    totalAthletesSupport: 0,
    paramsSponsorshipFan: {
        _limit: 10,
        _page: 0,
    },
    isSocketConnecting: false,
};

/**
 * shows the fan profile screen
 * @extends {Component<Props, State>}
 */
class FanProfileScreen extends Component<Props, State> {
    state = initialState;

    private readonly avatarRef = React.createRef<HTMLInputElement>();

    private readonly coverRef = React.createRef<HTMLInputElement>();

    componentDidMount() {
        const { submitPendingSportests, isAuthenticated } = this.props;
        const { isSocketConnecting } = this.state;
        submitPendingSportests();
        this.prepare();

        if (isAuthenticated && !isSocketConnecting) {
            WS.addHandler(SocketEventType.SUPPORTERS_UPDATE, this.handleSupportUpdate);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        const { user, pendingAthleteSupporters, isAuthenticated } = this.props;
        const { user: prevUser, pendingAthleteSupporters: prevPendingAthleteSupporters, isAuthenticated: prevIsAuthenticated } = prevProps;
        const { isSocketConnecting, fields } = this.state;
        const { fields: prevFields } = prevState;

        if (user?.availableCredits !== prevUser?.availableCredits || pendingAthleteSupporters !== prevPendingAthleteSupporters) {
            this.fetchAthletesSupport();
        }

        if (isAuthenticated && !prevIsAuthenticated && !isSocketConnecting) {
            WS.addHandler(SocketEventType.SUPPORTERS_UPDATE, this.handleSupportUpdate);
        }

        if (isEqual(prevFields, initialState.fields)
            || isEqual(fields, initialState.fields)
            || isEqual(prevFields, fields)
        ) return;

        this.setState({ changesMade: true });
    }

    componentWillUnmount(): void {
        WS.removeHandler(SocketEventType.SUPPORTERS_UPDATE, this.handleSupportUpdate);
    }

    /**
     * handlers
     */

    onToggleGallery = () => {
        this.setState((prevState: State) => ({
            showGallery: !prevState.showGallery,
        }));
    };

    onInputChange = (e: any) => {
        const { value, name } = e.currentTarget;
        this.setState((prevState: State) => ({
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };

    onDropdownChange = (option: DropdownOption | null, name: string): void => {
        const { fields } = this.state;

        this.setState({
            fields: {
                ...fields,
                [name]: option,
            },
        });
    };

    onSubmit = (e: React.MouseEvent) => {
        e.preventDefault();

        const {
            validateFanProfileUpdate, fanProfileUpdate, user, match: { params: { id } },
        } = this.props;
        const { fields } = this.state;

        const errors = validateFanProfileUpdate(fields);

        this.setState(
            { errors },
            () => {
                if (!errors) {
                    fanProfileUpdate(fields, this.onSuccess, this.onFailure, user?.role === UserRoles.Admin ? id : undefined);
                }
            },
        );
    };

    onSuccess = () => {
        const { t, user } = this.props;
        this.setState(
            {
                changesMade: false,
                fields: initialState.fields,
                errors: null,
            },
            () => {
                this.prepare();
                if (user?.role === UserRoles.Admin) {
                    displaySuccess({
                        message: t('admin.users.updated'),
                    });
                    return;
                }
                displaySuccess({
                    message: t('profile.updated'),
                });
            },
        );
    };

    onFailure = (errors: KeyedObject) => {
        const { t } = this.props;

        if (errors) {
            if (errors.fields) {
                this.setState({
                    errors: errors.fields,
                });
            }

            if (errors.errors) {
                errors.errors.forEach((error: any) => {
                    displayError({
                        message: t(`errors.${ErrorCode[error.errorCode]}`),
                    });
                });
            }
        }
    };

    onCoverFileDialog = () => {
        if (this.coverRef.current) {
            this.coverRef.current.click();
        }
    };

    onCoverSelected = (files: FileList | null) => {
        const { t } = this.props;
        if (!files) return;
        const { coverUpdate } = this.props;

        const reader = new FileReader();
        const file = files[0];

        reader.onloadend = () => {
            this.setState(
                {
                    cover: reader.result,
                    isFetching: true,
                },
                () => {
                    coverUpdate(
                        file,
                        (response: any) => {
                            this.setState((prevState: State) => ({
                                fields: {
                                    ...prevState.fields,
                                    coverId: response.data.id,
                                },
                                isFetching: false,
                            }));
                        },
                        () => {
                            this.setState({
                                isFetching: false,
                            });
                            displayError({
                                message: t('profile.uploadError'),
                            });
                        },
                    );
                },
            );
        };

        reader.readAsDataURL(file);
    };

    onAvatarFileDialog = () => {
        if (this.avatarRef.current) {
            this.avatarRef.current.click();
        }
    };

    onAvatarSelected = (files: FileList | null) => {
        const { t } = this.props;
        if (!files) return;
        const { avatarUpdate } = this.props;

        const reader = new FileReader();
        const file = files[0];

        reader.onloadend = () => {
            this.setState(
                {
                    avatar: reader.result,
                    isFetchingAvatar: true,
                },
                () => {
                    avatarUpdate(
                        file,
                        (response: any) => {
                            this.setState((prevState: State) => ({
                                fields: {
                                    ...prevState.fields,
                                    avatarId: response.data.id,
                                },
                                isFetchingAvatar: false,
                            }));
                        },
                        () => {
                            this.setState({
                                isFetchingAvatar: false,
                            });
                            displayError({
                                message: t('profile.uploadError'),
                            });
                        },
                    );
                },
            );
        };

        reader.readAsDataURL(file);
    };

    onSportTestFinished = (): void => {
        const { user, getSportests } = this.props;

        if (!user) return;
        getSportests(user.id).then((sportests) => this.setState({ lastSportest: sportests.pop() || null }));
    };

    onPageChange = (evt: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        const { paramsSponsorshipFan } = this.state;

        this.setState(
            {
                paramsSponsorshipFan: { ...paramsSponsorshipFan, _page: page },
            },
            this.fetchAthletesSupport,
        );
    };

    onRowsPerPageChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { paramsSponsorshipFan } = this.state;

        this.setState(
            {
                paramsSponsorshipFan: { ...paramsSponsorshipFan, _limit: Number(evt.target.value) },
            },
            this.fetchAthletesSupport,
        );
    };

    /**
     * requests & actions
     */

    prepare = async () => {
        const {
            user, history, match, getSportests,
        } = this.props;

        const userId = match?.params?.id || user?.id || '';
        const promises: [Promise<Sportest[]>, Promise<AxiosResponse>] = [getSportests(userId), axios.get(userURL(userId))];

        let res = [];
        try {
            res = await Promise.all(promises);
        } catch (error) {
            history.push(INDEX_ROUTE);
            return;
        }

        const [sportests, userResponse] = res;

        this.setState((prevState: State) => ({
            userData: userResponse.data,
            fields: {
                ...prevState.fields,
                name: userResponse.data.name || initialState.fields.name,
                countryCode: userResponse.data.countryCode || initialState.fields.countryCode,
                postalCode: userResponse.data.postalCode || initialState.fields.postalCode,
                fiscalCode: userResponse.data.fiscalCode || initialState.fields.fiscalCode,
                address: userResponse.data.address || initialState.fields.address,
                phrase: userResponse.data.phrase || initialState.fields.phrase,
                biography: userResponse.data.biography || initialState.fields.biography,
                avatarId: userResponse.data.avatar?.id || initialState.fields.avatarId,
                coverId: userResponse.data.coverPhoto?.id || initialState.fields.coverId,
            },
            lastSportest: sportests.pop() || null,
        }));

        if (user?.id === userResponse.data.id) this.fetchAthletesSupport();
    };

    removeCoverImage = () => {
        const { fields, userData } = this.state;
        if (userData) {
            this.setState({
                cover: undefined,
                userData: {
                    ...userData,
                    coverPhoto: null,
                },
                fields: {
                    ...fields,
                    coverId: 0,
                },
            });
        }
    }

    removeUserImage = () => {
        const { fields, userData } = this.state;
        if (userData) {
            this.setState({
                avatar: undefined,
                userData: {
                    ...userData,
                    avatar: null,
                },
                fields: {
                    ...fields,
                    avatarId: 0,
                },
            });
        }
    }

    fetchAthletesSupport = () => {
        const { paramsSponsorshipFan } = this.state;
        const { getAthletesSupport } = this.props;

        getAthletesSupport({ ...paramsSponsorshipFan })
            .then(({ list, total }) => {
                this.setState({
                    athletesSupportList: list,
                    totalAthletesSupport: total,
                });
            });
    }

    handleSupportUpdate = ({ payload: supportUpdateEvent }: ReceiveMessageDetails<Receive, 'type', SocketEventType.SUPPORTERS_UPDATE>) => {
        const { athletesSupportList } = this.state;
        
        const supportElement = athletesSupportList.find((el) => el.id === supportUpdateEvent.id);
        
        if (!supportElement) {
            return;
        }

        const auxList = athletesSupportList.map((el) => {
            if (el.id === supportElement.id) return { ...el, ...supportUpdateEvent };

            return el;
        });

        this.setState({
            athletesSupportList: auxList,
        });
    }

    /**
     * renders
     */

    renderInputsFiles = () => {
        return (
            <React.Fragment>
                <input
                    id="cover-input"
                    type="file"
                    ref={this.coverRef}
                    data-testid="fan-cover-input"
                    onChange={(e) => this.onCoverSelected(e.target.files)}
                />
                <input
                    id="avatar-input"
                    type="file"
                    ref={this.avatarRef}
                    data-testid="fan-avatar-input"
                    onChange={(e) => this.onAvatarSelected(e.target.files)}
                />
            </React.Fragment>
        );
    };

    renderHeaderContent(): React.ReactNode {
        const { t, user } = this.props;

        const {
            cover,
            avatar,
            userData,
            isFetchingAvatar,
        } = this.state;

        if (!userData) return null;

        const itsMe = user?.id === userData?.id;
        const currentCover = userData.coverPhoto ? userData.coverPhoto.main : null;
        const currentAvatar = userData.avatar ? userData.avatar.main : null;
        const linkURL = `${FAN_ROUTE}/${userData.id}`;

        return (
            <React.Fragment>
                <CoverImage image={cover || currentCover} onEdit={this.onCoverFileDialog} onDelete={this.removeCoverImage} tooltip={t('profile.coverTooltip')} />
                <div className="buttons">
                    {itsMe && <UserWallet userPageOwner={userData} />}
                    <ButtonContact userPageOwner={userData} />
                    <ButtonShare linkURL={linkURL} />
                </div>
                {isFetchingAvatar && (
                    <div className="fetching fetching--avatar">
                        <ReactSVG wrapper="span" src={loader} />
                    </div>
                )}
                <Avatar image={avatar || currentAvatar} editable onEdit={this.onAvatarFileDialog} onDelete={this.removeUserImage} />
                <button
                    type="button"
                    className="btn btn--primary-dark gallery-btn"
                    onClick={this.onToggleGallery}
                >
                    {t('profile.galery')}
                </button>
            </React.Fragment>
        );
    }

    renderFanInfo(): React.ReactNode {
        const { t } = this.props;
        const { fields, isFetching, errors } = this.state;

        return (
            <div className="row athlete-info">
                <div className="col-xl-3 col-sm-6 col-xs-12">
                    <FormField
                        label={t('profile.name')}
                        type="text"
                        name="name"
                        value={fields.name}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
            </div>
        );
    }

    renderFanStats(): React.ReactNode {
        const { isFetching, t } = this.props;
        const { fields, errors } = this.state;

        const colClass = 'col-xl-3 col-lg-4 col-sm-6 col-xs-12 with-icon';

        return (
            <div className="row athlete-stats">
                <div className={colClass}>
                    <div className="icn">
                        <img src={iconPin} alt="" />
                    </div>
                    <FormField
                        label={t('profile.address')}
                        type="text"
                        name="address"
                        value={fields.address}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn">
                        <IconGlobe />
                    </div>
                    <SelectCountry
                        name="countryCode"
                        label={t('profile.country')}
                        value={fields.countryCode}
                        onChange={this.onDropdownChange}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn">
                        <IconHome />
                    </div>
                    <FormField
                        label={t('profile.postalCode')}
                        type="text"
                        name="postalCode"
                        value={fields.postalCode}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn icn--phone">
                        <IconBank />
                    </div>
                    <FormField
                        label={t('profile.fiscalCode')}
                        type="text"
                        name="fiscalCode"
                        value={fields.fiscalCode}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
            </div>
        );
    }

    renderProfileInfo = (): React.ReactNode => {
        const { t } = this.props;
        const { fields, errors, isFetching } = this.state;

        return (
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="content-box">
                        <h3 className="title">{t('profile.aboutMe')}</h3>
                        <FormField
                            label={t('profile.definition')}
                            type="text"
                            name="phrase"
                            value={fields.phrase}
                            onChange={this.onInputChange}
                            placeholder=""
                            disabled={isFetching}
                            multiline
                            errors={errors}
                        />
                        <FormField
                            label={t('profile.about')}
                            type="text"
                            name="biography"
                            value={fields.biography}
                            onChange={this.onInputChange}
                            placeholder=""
                            disabled={isFetching}
                            multiline
                            errors={errors}
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            t, openTestInfo, history,
        } = this.props;
        const {
            userData, showGallery, isFetching, lastSportest,
            paramsSponsorshipFan, totalAthletesSupport, athletesSupportList, changesMade,
        } = this.state;

        if (!userData) return null;

        return (
            <>
                <RouteLeavingGuard
                    when={changesMade}
                    navigate={(pathName: string) => {
                        history.push(pathName);
                    }}
                    shouldBlockNavigation={() => true}
                    title={t('profile.noChangesPersistedConfirmation')}
                    confirmText={t('general.confirm')}
                    cancelText={t('general.cancel')}
                />
                <div className="profile-screen" data-testid="profile-screen">
                    <div className="profile-screen__body">
                        <div className="profile-screen__body__left with-overlay">
                            <div className="profile-screen__body__left__header">
                                {this.renderInputsFiles()}
                                {isFetching && (
                                    <div className="fetching">
                                        <ReactSVG wrapper="span" src={loader} />
                                    </div>
                                )}
                                {this.renderHeaderContent()}
                            </div>
                            {!showGallery ? (
                                <div className="profile-screen__body__left__content">
                                    <div className="profile-top">
                                        <div className="profile-top__left">
                                            {this.renderFanInfo()}
                                            <div className="hidden-xs stats-container">
                                                {this.renderFanStats()}
                                            </div>
                                        </div>
                                        <div className="profile-top__right">
                                            <button type="button" onClick={(): void => openTestInfo(this.onSportTestFinished)}>
                                                <SportTestBadge sportTest={lastSportest} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="profile-info">
                                        <div className="row hidden-sm hidden-md hidden-lg">
                                            <div className="col-sm-12">{this.renderFanStats()}</div>
                                        </div>
                                        {this.renderProfileInfo()}
                                        {athletesSupportList.length > 0 && (
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="content-box">
                                                        <h3 className="title">{t('profile.sponsorshipManager.mySupportedAthletes')}</h3>
                                                        <SponsorshipManagerTable
                                                            totalAthletesSupport={totalAthletesSupport}
                                                            athletesSupportList={athletesSupportList}
                                                            onPageChange={this.onPageChange}
                                                            onRowsPerPageChange={this.onRowsPerPageChange}
                                                            params={paramsSponsorshipFan}
                                                            fetchData={this.fetchAthletesSupport}
                                                            actionButtons={[TableActionType.CANCEL]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="profile-screen__body__left__gallery">
                                    <DrawerGallery galleryUser={userData} canEdit />
                                </div>
                            )}
                            <div className="overlay">
                                <button type="button" className="btn btn--primary-inverse" onClick={this.onSubmit}>
                                    {t('profile.save')}
                                </button>
                            </div>
                        </div>
                        <div className="profile-screen__body__right">
                            <DrawerGallery galleryUser={userData} canEdit />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withAuthenticationContext(
    withSponsorContext(withUserContext(withTranslationContext(withSportestContext(FanProfileScreen)))),
);
