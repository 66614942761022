/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';

import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { UserContext, withUserContext } from '../../controllers/user/UserContext';
import iconPin from '../../../assets/images/icon_pin.svg';
import badgeStar from '../../../assets/images/badge_star.svg';
import defaultAvatar from '../../../assets/images/avatar_default.svg';
import DrawerGallery from '../../elements/DrawerGallery';
import ButtonContact from '../../elements/ButtonContact';
import ButtonShare from '../../elements/ButtonShare';
import Avatar from '../../elements/Avatar';
import defaultCover from '../../../assets/images/athlete_header_bg.png';
import { MatchParams, Media } from '../../../constants/misc';
import { userURL } from '../../../services/users';
import ProfileError from '../../elements/ProfileError';
import { FAN_ROUTE } from '../../../constants/routes';
import { Badge, User } from '../../../constants/user';
import { Sportest } from '../../../constants/sportest';
import { SportTestBadge } from '../../elements/SportTestBadge';
import { SponsorContext, withSponsorContext } from '../../controllers/sponsor/SponsorContext';
import Badges from '../../elements/Badges';

/**
 * @typedef {Object} OwnProps
 * @extends {RouteComponentProps<MatchParams>, AuthenticationContext, TranslationContext, UserContext}
 */
interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContext, TranslationContext, UserContext, SponsorContext {}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 * @property {boolean} showGallery
 * @property {Media} coverPhoto
 * @property {Media} avatar
 * @property {string} biography
 * @property {string} name
 * @property {string} address
 * @property {any} [mediaList]
 * @property {string} phrase
 * @property {boolean} error
 * @property {User | null} userData
 * @property {Sportest | null} lastSportest
 */
interface OwnState {
    showGallery: boolean;
    coverPhoto: Media | null;
    avatar: Media | null;
    biography: string;
    name: string;
    address: string;
    mediaList: any;
    objectives: string;
    phrase: string;
    linkURL: string;
    error: boolean;
    userData: User | null;
    lastSportest: Sportest | null;
    sponsoredAthletes: Badge[];
}

/**
 * @typedef {Object} State
 */
type State = OwnState;

const initialState: State = {
    showGallery: false,
    coverPhoto: null,
    avatar: null,
    biography: '',
    name: '',
    address: '',
    mediaList: [],
    objectives: '',
    phrase: '',
    linkURL: '',
    error: false,
    userData: null,
    lastSportest: null,
    sponsoredAthletes: [],
};

/**
 * shows the fan screen
 * @extends {Component<Props, State>}
 */
class FanScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount() {
        const { match } = this.props;

        window.scrollTo(0, 0);

        if (match && match.params && match.params.id) {
            this.prepare(match.params.id);
        }
    }

    /**
     * handles gallery toggle click
     * @param {React.MouseEvent} e
     */
    onToggleGallery = (): void => {
        const { showGallery } = this.state;

        this.setState({
            showGallery: !showGallery,
        });
    };

    /**
     * callback for when sportest test is finished
     */
     onSportTestFinished = (): void => {
         const { match: { params: { id } }, getSportests } = this.props;
         if (!id) return;
         getSportests(id).then((sportests) => this.setState({ lastSportest: sportests.pop() || null }));
     };

    prepare = async (id: string): Promise<void> => {
        const { getSportests, getSponsoredAthletesBadges } = this.props;
        const promises: [Promise<Sportest[]>, Promise<AxiosResponse>, Promise<Badge[]>] = [getSportests(id), axios.get(userURL(id)), getSponsoredAthletesBadges(id)];

        let res = [];
        try {
            res = await Promise.all(promises);
        } catch (error) {
            this.setState({
                error: true,
            });
            return;
        }

        const [sportests, userResponse, sponsoredAthletes] = res;

        const { data } = userResponse;
        if (data) {
            const {
                coverPhoto, avatar, biography, name, address, phrase,
            } = data;

            this.setState({
                coverPhoto,
                avatar,
                biography,
                name,
                address,
                phrase,
                userData: data,
                linkURL: `${FAN_ROUTE}/${id}`,
                lastSportest: sportests.pop() || null,
                sponsoredAthletes,
            });
        }
    }

    /**
     * renders sportest badge
     */
    renderSportestBadge = (mobile = false): React.ReactNode => {
        const { user, match: { params: { id } }, openTestInfo } = this.props;
        const { lastSportest } = this.state;
        return String(user?.id) === id ? (
            <button type="button" className={mobile ? 'hidden-sm hidden-md hidden-lg' : 'hidden-xs'} onClick={(): void => openTestInfo(this.onSportTestFinished)}>
                <SportTestBadge sportTest={lastSportest} />
            </button>
        ) : (<div className={mobile ? 'hidden-sm hidden-md hidden-lg' : 'hidden-xs'}><SportTestBadge sportTest={lastSportest} /></div>);
    };

    /**
     * returns the athlete stats
     */
    renderAthleteStats(): React.ReactNode {
        const { address } = this.state;

        return (
            <ul className="profile-stats">
                {address && (
                    <li>
                        <div className="icn">
                            <img src={iconPin} alt="" />
                        </div>
                        <p>{address}</p>
                    </li>
                )}
                { this.renderSportestBadge(false) }
            </ul>
        );
    }

    render(): React.ReactNode {
        const {
            avatar,
            coverPhoto,
            showGallery,
            name,
            phrase,
            biography,
            objectives,
            linkURL,
            error,
            userData,
            sponsoredAthletes,
        } = this.state;
        const { t } = this.props;

        const currentCover = coverPhoto?.main || defaultCover;
        const currentAvatar = avatar?.main || null;

        return (
            <div className="profile-screen" data-testid="athlete-screen">
                <ProfileError show={error} close={(): void => this.setState({ error: false })} />
                <div className="profile-screen__body">
                    <div className="profile-screen__body__left">
                        <div className="profile-screen__body__left__header">
                            <div className="cover">
                                <div className="cover__image" style={{ backgroundImage: `url(${currentCover})` }} />
                            </div>
                            <div className="buttons">
                                <ButtonContact userPageOwner={userData} />
                                <ButtonShare linkURL={linkURL} />
                            </div>
                            <Avatar image={currentAvatar || defaultAvatar} badge={badgeStar} />
                            <button
                                type="button"
                                className="btn btn--primary-dark gallery-btn"
                                onClick={this.onToggleGallery}
                                data-testid="gallery-toggle"
                            >
                                {t('athlete.galery')}
                            </button>
                        </div>
                        {!showGallery ? (
                            <div className="profile-screen__body__left__content">
                                <div className="profile-top">
                                    <div className="athlete-info">
                                        <h1>{name}</h1>
                                    </div>
                                    <div className="hidden-xs">{this.renderAthleteStats()}</div>
                                    { this.renderSportestBadge(true) }
                                </div>
                                <div className="profile-info">
                                    <div className="row hidden-sm hidden-md hidden-lg">
                                        <div className="col-sm-12">{this.renderAthleteStats()}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="content-box">
                                                <h3 className="title">{t('athlete.about')}</h3>
                                                <h4>{phrase}</h4>
                                                <p>{biography || t('general.noInfo')}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="content-box">
                                                <h3 className="title">{t('athlete.myGoals')}</h3>
                                                <p>{objectives || t('general.noInfo')}</p>
                                            </div>
                                            <div className="content-box">
                                                <div className="content-box__with-button">
                                                    <h3 className="title">{t('athlete.supportedAthletes')}</h3>
                                                </div>
                                                <div className="content-box__item content-box__item--sponsored">
                                                    {sponsoredAthletes.map((sponsoredAthlete) => (
                                                        <Badges
                                                            userId={sponsoredAthlete.id}
                                                            userRole={sponsoredAthlete.role}
                                                            badgeId={sponsoredAthlete.badgeUrl.id}
                                                            badgeUrl={sponsoredAthlete.badgeUrl.main}
                                                            refreshScreen={this.prepare}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div data-testid="mobile-gallery" className="profile-screen__body__left__gallery">
                                <DrawerGallery galleryUser={userData} />
                            </div>
                        )}
                    </div>
                    <div className="profile-screen__body__right">
                        <DrawerGallery galleryUser={userData} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withSponsorContext(withUserContext(withTranslationContext(FanScreen))));
