/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import { Button } from '@material-ui/core';
import Lottie from 'lottie-react';
import React, { useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReactComponent as SportrackIcon } from '../../assets/images/horizontal_logo.svg';
import { ReactComponent as HandshakeIcon } from '../../assets/images/icon-handshake.svg';
import closeIcon from '../../assets/images/icon_close_green.svg';
import { MatchParams } from '../../constants/misc';
import { PROFILE_ROUTE } from '../../constants/routes';
import { SponsorAthletesSupport } from '../../constants/sponsor';
import { SUPPORT_STATUS } from '../../constants/support';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { SponsorContext, withSponsorContext } from '../controllers/sponsor/SponsorContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import Avatar from './Avatar';
import partyConfetti from '../../assets/animations/party-confetti.json';
import { UserContext, withUserContext } from '../controllers/user/UserContext';

interface OwnProps extends UserContext {
    athleteSupporter?: SponsorAthletesSupport;
    onClose?: () => void;
}

type Props = OwnProps & SponsorContext & TranslationContext & AuthenticationContext & RouteComponentProps<MatchParams> & UserContext;

const AthletePendingSupportModal: React.FC<Props> = (props: Props) => {
    const {
        t,
        user,
        history,
        showAthleteSupporters,
        pendingAthleteSupporters,
        patchRejectSupport,
        patchAcceptSupport,
        resetAthletePendingSupports,
        athleteSupporter,
        onClose,
        updateUserWallet,
    } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSuccessInfo, setShowSuccessInfo] = useState<boolean>(false);

    const supporter = useMemo(() => {
        if (athleteSupporter) return athleteSupporter;

        if (pendingAthleteSupporters.list.length > 0) return pendingAthleteSupporters.list[0];

        return null;
    }, [athleteSupporter, pendingAthleteSupporters.list]);

    if ((!athleteSupporter) && (!showAthleteSupporters || pendingAthleteSupporters.total <= 0 || pendingAthleteSupporters.list.length === 0)) {
        return null;
    }

    const onFailure = () => {
        resetAthletePendingSupports();

        if (onClose) onClose();
    };

    const onAcceptSuccess = () => {
        setShowSuccessInfo(true);
        
        if (!user?.availableCredits || !supporter) return;

        updateUserWallet(user.availableCredits + supporter.totalCredits);
    };

    const handleCloseClick = () => {
        resetAthletePendingSupports();

        if (onClose) onClose();
    };

    const handleAcceptClick = async (supportId: number) => {
        setIsLoading(true);

        await patchAcceptSupport(supportId, onAcceptSuccess, onFailure);

        setIsLoading(false);
    };

    const handleRejectClick = async (supportId: number) => {
        setIsLoading(true);

        await patchRejectSupport(supportId, onFailure, onFailure);

        setIsLoading(false);
    };

    const handleSeeMySupportersClick = () => {
        setIsLoading(false);
        resetAthletePendingSupports();
        if (onClose) onClose();
        history.push(PROFILE_ROUTE);
    };

    const renderContentFooter = () => {
        return (
            <div className="athlete-supporter-modal__container__content__support-athlete">
                <p>{t('athlete.supporterModal.weReWith')}</p>
                <h3>{user?.name}{user?.lastName ? ` ${user.lastName}` : ''}</h3>
                {user?.club?.clubCompositeKey.sport && <h4>{user?.club?.clubCompositeKey.sport}</h4>}
            </div>
        );
    };

    const renderOneSupporter = () => {
        if (pendingAthleteSupporters.list.length > 1) return null;

        if (pendingAthleteSupporters.list.length === 1 || supporter) {
            if (showSuccessInfo) {
                return renderSuccessModal();
            }

            if (supporter?.status !== SUPPORT_STATUS.PENDING) {
                return renderUnavailableSupportRequest();
            }

            return renderPendingSupportRequest();
        }

        return null;
    };

    const renderUnavailableSupportRequest = () => {
        if (!supporter) return;

        const {
            userAvatar,
            status,
        } = supporter;

        return (
            <>
                <h2>{t('athlete.supporterModal.unavailable')}</h2>
                <h2>{t(`athlete.supporterModal.${status}`)}</h2>
                <div className="athlete-supporter-modal__container__content__supporting-request">
                    <span className="supporter-icon">
                        <Avatar image={userAvatar.main} />
                    </span>
                    <div className="handshake-icon">
                        <HandshakeIcon />
                    </div>
                    <span className="athlete-icon">
                        <Avatar image={user?.avatar?.main} />
                    </span>
                </div>
                {renderContentFooter()}
                <p>
                    {t('athlete.supporterModal.joinSponsorship')}
                    <SportrackIcon />
                </p>
                <div className="athlete-supporter-modal__container__content__buttons-container">
                    <Button
                        className="accept"
                        onClick={handleSeeMySupportersClick}
                        disabled={isLoading}
                    >
                        {t('athlete.supporterModal.seeMySupporters')}
                    </Button>
                </div>
            </>
        );
    };

    const renderPendingSupportRequest = () => {
        if (!supporter) return;

        const {
            userName,
            periodBought,
            totalCredits,
            userAvatar,
            id: supportId,
        } = supporter;

        return (
            <>
                <h2>
                    {
                        t('athlete.supporterModal.wantsToSupport',
                            { supporter: userName, credits: totalCredits, duration: periodBought })
                    }
                </h2>
                <div className="athlete-supporter-modal__container__content__supporting-request">
                    <span className="supporter-icon">
                        <Avatar image={userAvatar.main} />
                    </span>
                    <div className="handshake-icon">
                        <HandshakeIcon />
                    </div>
                    <span className="athlete-icon">
                        <Avatar image={user?.avatar?.main} />
                    </span>
                </div>
                {user?.name && renderContentFooter()}
                <p>
                    {t('athlete.supporterModal.joinSponsorship')}
                    <SportrackIcon />
                </p>
                <div className="athlete-supporter-modal__container__content__buttons-container">
                    <Button
                        className="reject"
                        onClick={() => handleRejectClick(supportId)}
                        disabled={isLoading}
                    >
                        {t('profile.sponsorshipManager.reject')}
                    </Button>
                    <Button
                        className="accept"
                        onClick={() => handleAcceptClick(supportId)}
                        disabled={isLoading}
                    >
                        {t('profile.sponsorshipManager.accept')}
                    </Button>
                </div>
            </>
        );
    };

    const renderMultipleSupports = () => {
        const { total } = pendingAthleteSupporters;

        const supporters = pendingAthleteSupporters.list.filter((_, idx) => idx < 3);

        return (
            <>
                <h2>{t('athlete.supporterModal.sponsorshipBonanza1')}</h2>
                <h2>{t('athlete.supporterModal.sponsorshipBonanza2')}</h2>
                <div className="athlete-supporter-modal__container__content__supporting-request">
                    {supporters.map((s: SponsorAthletesSupport) => (
                        <span className="supporter-icon" key={s.id}>
                            <Avatar image={s.userAvatar.main} />
                        </span>
                    ))}
                </div>
                <div className="athlete-supporter-modal__container__content__support-athlete">
                    <p>{total > 3 ? t('athlete.supporterModal.andMore', { number: total - supporters.length }) : ''}{t('athlete.supporterModal.areWith')}</p>
                    <h3>{user?.name}{user?.lastName ? ` ${user.lastName}` : ''}</h3>
                    {user?.club?.clubCompositeKey.sport && <h4>{user?.club?.clubCompositeKey.sport}</h4>}
                </div>
                <p>
                    {t('athlete.supporterModal.joinSponsorship')}
                    <SportrackIcon />
                </p>
                <div className="athlete-supporter-modal__container__content__buttons-container">
                    <Button
                        className="accept"
                        onClick={handleSeeMySupportersClick}
                        disabled={isLoading}
                    >
                        {t('athlete.supporterModal.seeMySupporters')}
                    </Button>
                </div>
            </>
        );
    };

    const renderSuccessModal = () => {
        const {
            userName, periodBought, totalCredits, userAvatar,
        } = pendingAthleteSupporters.list[0];

        return (
            <>
                <h1>{t('athlete.supporterModal.weSupport')}</h1>
                <h2>
                    {
                        t('athlete.supporterModal.isSupporting',
                            { supporter: userName, credits: totalCredits, duration: periodBought })
                    }
                </h2>
                <div className="athlete-supporter-modal__container__content__supporting-request">
                    <span className="supporter-icon">
                        <Avatar image={userAvatar.main} />
                    </span>
                    <span className="handshake-icon">
                        <HandshakeIcon />
                    </span>
                    <span className="athlete-icon">
                        <Avatar image={user?.avatar?.main} />
                    </span>
                </div>
                {renderContentFooter()}
            </>
        );
    };

    return (
        <div className="modal athlete-supporter-modal" data-testid="athlete-pending-support-modal">
            <div className="modal__backdrop" />
            <div className="athlete-supporter-modal__container">
                <button
                    className="athlete-supporter-modal__container__close-icon"
                    type="button"
                    onClick={handleCloseClick}
                    disabled={isLoading}
                >
                    <img
                        src={closeIcon}
                        alt="close icon"
                    />
                </button>
                <div className="athlete-supporter-modal__container__content">
                    {renderOneSupporter()}
                    {pendingAthleteSupporters.list.length > 1 && renderMultipleSupports()}
                </div>
                {showSuccessInfo && <Lottie animationData={partyConfetti} loop={false} className="lottie" />}
            </div>
        </div>
    );
};

export default withRouter(withUserContext(withAuthenticationContext(withSponsorContext(withTranslationContext(AthletePendingSupportModal)))));
