/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { DropdownOption } from '../../constants/misc';
import IconAfter from '../assets/IconAfter';
import IconBefore from '../assets/IconBefore';
import IconFirst from '../assets/IconFirst';
import IconLast from '../assets/IconLast';
import Dropdown from './Dropdown';

interface OwnProps {
    total: number;
    current: number;
    page: number;
    limit: number;
    setParams: Function;
}

type Props = OwnProps;

const TablePagination: FunctionComponent<Props> = ({
    limit,
    total,
    current,
    page,
    setParams,
}: Props) => {
    const firstPage = () => setParams('_page', 0);

    const lastPage = () => setParams('_page', Math.ceil(total / limit) - 1);

    const prevPage = () => {
        if (page > 0) {
            setParams('_page', page - 1);
        }
    };

    const nextPage = () => {
        if (page < Math.ceil(total / limit) - 1) {
            setParams('_page', page + 1);
        }
    };

    const rowsChange = (option: DropdownOption) => {
        setParams('_limit', Number(option.value));
    };

    const dropdownOptions: DropdownOption[] = [
        { label: '5', value: '5' },
        { label: '10', value: '10' },
        { label: '15', value: '15' },
        { label: '20', value: '20' },
    ];

    return (
        <div className="table-pagination">
            <div className="table-pagination__container">
                <Dropdown
                    options={dropdownOptions}
                    value={dropdownOptions.find((option) => option.value === String(limit)) || null}
                    onChange={rowsChange}
                    name="limit"
                />
                <IconFirst fill="#278982" onClick={firstPage} testId="pagination-first" />
                <IconBefore fill="#aaaaaa" onClick={prevPage} testId="pagination-before" />
                {`${page * limit + 1}-${page * limit + current} of ${total}`}
                <IconAfter fill="#aaaaaa" onClick={nextPage} testId="pagination-after" />
                <IconLast fill="#278982" onClick={lastPage} testId="pagination-last" />
            </div>
        </div>
    );
};

export default TablePagination;
