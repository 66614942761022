/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */
import React from 'react';
import deleteIcon from '../../assets/images/delete.svg';
import { KeyedObject } from '../../constants/misc';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

/**
 * @typedef {Object} OwnProps
 * @property {any} [image]
 * @property {string} [badge]
 * @property {boolean} [small]
 * @property {Function} [onClick]
 * @property {boolean} [editable]
 */
 interface OwnProps {
    onAccept: () => void;
    onClose: () => void;
    titleTranslationKey: string | {
        key: string;
        params?: KeyedObject;
    };
    deleteTranslationKey?: string;
    cancelTranslationKey: string;
    supportConfirmation?: boolean;
}

type Props = OwnProps & TranslationContext

export const ConfirmationModal: React.FC<OwnProps> = withTranslationContext((props: Props) => {
    const {
        t, titleTranslationKey, onClose, onAccept, deleteTranslationKey, cancelTranslationKey, supportConfirmation,
    } = props;

    const titleTranslation = typeof titleTranslationKey === 'string' ? t(titleTranslationKey) : t(titleTranslationKey.key, titleTranslationKey.params);

    return (
        <div className="modal">
            <div className="modal__test" />
            <div className="modal__content__test test">
                <div className="confirmation-modal">
                    <div className="confirmation-modal__content">
                        {!supportConfirmation && (
                            <img className="confirmation-modal__content__delete-icon" src={deleteIcon} alt="delete" />
                        )}
                        <p className="confirmation-modal__content__title">{titleTranslation}</p>
                        <div className="confirmation-modal__content__modal-buttons">
                            {!supportConfirmation && (
                                <button
                                    type="button"
                                    className="btn btn--warning"
                                    onClick={onAccept}
                                    data-testid="delete-button"
                                >
                                    {t(deleteTranslationKey || '')}
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn--warning-outline"
                                onClick={onClose}
                                data-testid="cancel-button"
                            >
                                {t(cancelTranslationKey)}
                            </button>
                            {supportConfirmation && (
                                <button
                                    type="button"
                                    className="btn btn--primary-inverse"
                                    onClick={onAccept}
                                    data-testid="delete-button"
                                >
                                    {t('general.confirm')}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
