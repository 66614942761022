/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import InputError from './FormFieldError';
import iconEye from '../../assets/images/icon_eye.svg';
import { getErrorsForField, hasAnyErrors } from '../../utils/validations';
import { KeyedObject } from '../../constants/misc';

interface OwnProps {
    label?: string;
    type?: string;
    name: string;
    value: unknown | undefined;
    onChange: (e: any) => void;
    placeholder?: string;
    disabled?: boolean;
    multiline?: boolean;
    cssClass?: string;
    errors?: KeyedObject | null;
    rows?: number;
}

type Props = OwnProps;
interface OwnState {
    showPassword: boolean;
}

const initialState: OwnState = {
    showPassword: false,
};

type State = OwnState;

const FormField: FunctionComponent<Props> = ({
    label = '',
    type = 'text',
    name,
    value,
    onChange,
    placeholder = '',
    disabled = false,
    multiline = false,
    cssClass = '',
    errors,
    rows,
}: Props) => {
    const [state, setState] = useState(initialState);

    /**
     * handles toggle password
     */
    const onTogglePassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    /**
     * returns the textarea
     */
    const renderTextArea = () => {
        return (
            <TextareaAutosize
                id={name}
                name={name}
                minRows={rows || 1}
                value={value ? String(value) : ''}
                maxRows={rows}
                rows={rows}
                onChange={(e) => onChange(e)}
                placeholder={placeholder}
                disabled={disabled}
                style={{ fontSize: '14px', minHeight: `${(rows || 1) * 21}px` }}
                
            />
        );
    };

    /**
     * returns the input
     */
    const renderInput = () => {
        return (
            <input
                type={inputType}
                id={name}
                name={name}
                value={value ? String(value) : ''}
                onChange={(e) => onChange(e)}
                placeholder={placeholder}
                disabled={disabled}
            />
        );
    };

    const getInputType = (inputType: string) => {
        if (inputType === 'password' && !state.showPassword) {
            return 'password';
        } if (inputType === 'number') {
            return 'number';
        }
        return 'text';
    };

    const fieldErrors = getErrorsForField(name, errors);
    const hasErrors = hasAnyErrors(fieldErrors);
    const inputType = getInputType(type);

    return (
        <div className="form-field">
            <div className={`input-container ${hasErrors ? 'has-error' : ''} ${cssClass}`}>
                {multiline ? renderTextArea() : renderInput()}
                {type === 'password' && (
                    <button type="button" onClick={onTogglePassword} data-testid="toggle-password">
                        <img src={iconEye} alt="" />
                    </button>
                )}
            </div>
            {label.length > 0 && <label htmlFor={name}>{label}</label>}
            {hasErrors && <InputError errors={fieldErrors} field={name} />}
        </div>
    );
};

export default FormField;
