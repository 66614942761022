/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

/**
 * @typedef {Object} OwnProps
 * @property {string} fill
 * @property {Function} onClick
 */
interface OwnProps {
    fill: string;
    onClick: Function;
    testId: string;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * icon svg
 * @param {Props} props
 * @extends {FunctionComponent<Props>}
 */
const IconFirst: FunctionComponent<Props> = (props: Props) => {
    const { onClick, fill, testId } = props;

    return (
        <div className="icon-first__icon" onClick={() => onClick()} data-testid={testId}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path fill={fill} d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" />
                <path d="M24 24H0V0h24v24z" fill="none" />
            </svg>
        </div>
    );
};

export default IconFirst;
