/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import isURL from 'validator/lib/isURL';
import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { UserContext, withUserContext } from '../../controllers/user/UserContext';
import iconPin from '../../../assets/images/icon_pin.svg';
import badgeStar from '../../../assets/images/badge_star.svg';
import contactIcon from '../../../assets/images/icon_btn_contact_green.svg';
import defaultAvatar from '../../../assets/images/avatar_default.svg';
import DrawerGallery from '../../elements/DrawerGallery';
import ButtonContact from '../../elements/ButtonContact';
import ButtonShare from '../../elements/ButtonShare';
import Avatar from '../../elements/Avatar';
import defaultCover from '../../../assets/images/athlete_header_bg.png';
import { MatchParams, Media } from '../../../constants/misc';
import { IconWWW } from '../../assets/IconWWW';
import { fileURL } from '../../../services/files';
import ProfileError from '../../elements/ProfileError';
import { Badge, Sponsor } from '../../../constants/user';
import { SponsorContext, withSponsorContext } from '../../controllers/sponsor/SponsorContext';
import SponsorProductList from '../../elements/SponsorProductList';
import Badges from '../../elements/Badges';
import { SPONSOR_ROUTE } from '../../../constants/routes';

interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContext,
     TranslationContext, UserContext, SponsorContext {}

type Props = OwnProps;

interface OwnState {
    showGallery: boolean;
    coverPhoto: Media | null;
    linkURL: string;
    error: boolean;
    userData: Sponsor | null;
    sponsoredAthletes: Badge[];
}

type State = OwnState;

const initialState: State = {
    showGallery: false,
    coverPhoto: null,
    linkURL: '',
    error: false,
    userData: null,
    sponsoredAthletes: [],
};

/**
 * shows the athlete screen
 * @extends {Component<Props, State>}
 */
class SponsorScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount(): void {
        const { match } = this.props;

        window.scrollTo(0, 0);

        if (match && match.params && match.params.id) {
            this.prepare(match.params.id);
        } else {
            this.prepare();
        }
    }

    /**
     * handles gallery toggle click
     */
    onToggleGallery = (): void => {
        const { showGallery } = this.state;

        this.setState({
            showGallery: !showGallery,
        });
    };

    /**
     * fetches initial Sponsor data
     */
    prepare = async (id?: string): Promise<void> => {
        const {
            user, getSponsor, getSponsoredAthletesBadges,
        } = this.props;
        const sponsorId = id || user?.id;

        const [userData, sponsoredAthletes] = await Promise.all([
            getSponsor(sponsorId), getSponsoredAthletesBadges(sponsorId),
        ]);

        if (!userData) {
            this.setState({ error: true });
            return;
        }

        this.setState({
            userData,
            linkURL: `${SPONSOR_ROUTE}/${sponsorId}`,
            sponsoredAthletes,
        });
    };

    render(): React.ReactNode {
        const {
            showGallery,
            linkURL,
            error,
            userData,
            sponsoredAthletes,
        } = this.state;
        const { t } = this.props;

        const {
            name,
            aboutUs,
            coverPhoto,
            avatar,
            biography,
            address,
            email,
            phrase,
        } = userData || {};

        const currentCover = coverPhoto ? fileURL(coverPhoto.id) : defaultCover;
        const currentAvatar = avatar ? fileURL(avatar.id) : null;
        const phone = userData?.phone || '';
        const contactURL = phone.includes('http://') || phone.includes('https://') ? phone : `https://${phone}`;

        return (
            <div className="profile-screen" data-testid="sponsor-screen">
                <ProfileError show={error} close={() => this.setState({ error: false })} />
                <div className="profile-screen__body">
                    <div className="profile-screen__body__left">
                        <div className="profile-screen__body__left__header">
                            <div className="cover">
                                <div className="cover__image" style={{ backgroundImage: `url(${currentCover})` }} />
                            </div>
                            <div className="buttons">
                                <ButtonContact userPageOwner={userData} />
                                <ButtonShare linkURL={linkURL} />
                            </div>
                            <Avatar image={currentAvatar || defaultAvatar} badge={badgeStar} />
                            <button
                                type="button"
                                className="btn btn--primary-dark gallery-btn"
                                onClick={this.onToggleGallery}
                                data-testid="gallery-toggle"
                            >
                                {t('sponsors.galery')}
                            </button>
                        </div>
                        {!showGallery ? (
                            <div className="profile-screen__body__left__content">
                                <div className="profile-top profile-top--sponsor">
                                    <div className="athlete-info">
                                        <h1>{name}</h1>
                                        <h2>{t('sponsors.sponsor')}</h2>
                                    </div>
                                </div>
                                <div className="profile-info">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="content-box">
                                                <h3 className="title">{t('sponsors.us')}</h3>
                                                <h4>{phrase}</h4>
                                                <p>{biography || t('sponsors.unavailable')}</p>
                                            </div>
                                            <div className="content-box">
                                                <h3 className="title">{t('sponsors.about')}</h3>
                                                <p>{aboutUs || t('sponsors.unavailable')}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="content-box">
                                                <h3 className="title">{t('sponsors.website')}</h3>
                                                <div className="content-box__item">
                                                    <div className="icn">
                                                        <img src={iconPin} alt="location icon" />
                                                    </div>
                                                    <p>{address}</p>
                                                </div>
                                                <div className="content-box__item">

                                                    <div className="icn icn--green icn--www">
                                                        <IconWWW />
                                                    </div>
                                                    {
                                                        isURL(phone) ? (
                                                            <a href={contactURL} target="_blank" rel="noopener noreferrer">{phone}</a>
                                                        ) : (
                                                            <span>{phone}</span>
                                                        )
                                                    }
                                                </div>
                                                <div className="content-box__item">
                                                    <div className="icn">
                                                        <img src={contactIcon} alt="email icon" className="content-box__item__contact" />
                                                    </div>
                                                    <p>{email}</p>
                                                </div>
                                            </div>
                                            <div className="content-box">
                                                <div className="content-box__with-button">
                                                    <h3 className="title">{t('athlete.supportedAthletes')}</h3>
                                                </div>
                                                <div className="content-box__item content-box__item--sponsored">
                                                    {sponsoredAthletes.map((sponsoredAthlete) => (
                                                        <Badges
                                                            userId={sponsoredAthlete.id}
                                                            userRole={sponsoredAthlete.role}
                                                            badgeId={sponsoredAthlete.badgeUrl.id}
                                                            badgeUrl={sponsoredAthlete.badgeUrl.main}
                                                            refreshScreen={this.prepare}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        {userData && (
                                            <SponsorProductList
                                                sponsor={userData}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div data-testid="mobile-gallery" className="profile-screen__body__left__gallery">
                                <DrawerGallery galleryUser={userData} />
                            </div>
                        )}
                    </div>
                    <div className="profile-screen__body__right">
                        <DrawerGallery galleryUser={userData} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(
    withSponsorContext(
        withUserContext(
            withTranslationContext(
                SponsorScreen,
            ),
        ),
    ),
);
