/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { useEffect } from 'react';
import reactGa from 'react-ga4';
import { SponsorProduct } from '../../constants/sponsor';
import { PaginationContext, withPaginationContext } from '../controllers/pagination/PaginationContext';
import ProductCard from './ProductCard';
import ProductModal from './ProductModal';
import { withSponsorContext, SponsorContext } from '../controllers/sponsor/SponsorContext';
import { ConfirmationModal } from './ConfirmationModal';
import { Sponsor } from '../../constants/user';
import { Direction } from '../../constants/misc';
import circleArrow from '../../assets/images/arrow_right_circle.svg';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import BuyProductModal from './BuyProductModal';
import { GACategories } from '../../utils/analytics';

interface OwnProps {
    sponsor: Sponsor;
    showPlaceholders?: boolean;
    showEdit?: boolean;
}

type Props = PaginationContext<SponsorProduct> & TranslationContext & SponsorContext & OwnProps;

const SponsorProductList: React.FC<Props> = (props: Props) => {
    const {
        list, sponsor, goToPage, t,
        paginate, getSponsorProducts, nextPage,
        deleteSponsorProduct, showPlaceholders,
        showEdit,
    } = props;

    const productRef = React.createRef<HTMLDivElement>();
    const productsSliderRef = React.createRef<HTMLDivElement>();

    const [openedOption, setOpenedOption] = React.useState<number | undefined>(-1);
    const [showBuyProductModal, setShowBuyProductModal] = React.useState(false);
    const [showProductModal, setShowProductModal] = React.useState(false);
    const [selectedProduct, setSelectedProduct] = React.useState<SponsorProduct | null>(null);
    const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
    const [numberOfPlaceholders, setNumberOfPlaceholders] = React.useState(0);

    const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
            nextPage();
        }
    }, { root: productsSliderRef.current });

    /**
     * calculates the missing product cards to fullfil the total width, so that free space is covered with right amount of "add new product" cards
     */
    const calculateCountMissingCards = () => {
        if (!productsSliderRef.current) {
            return;
        }

        setNumberOfPlaceholders(Math.ceil(productsSliderRef.current.clientWidth / 250));
    };

    const onMoveProductSlider = (direction: Direction): void => {
        if (!productsSliderRef.current) {
            return;
        }

        const amountToScroll = (direction === Direction.LEFT ? -1 : 1) * 200;
        productsSliderRef.current.scrollTo({
            behavior: 'smooth',
            left: productsSliderRef.current.scrollLeft + amountToScroll,
        });
    };

    const onEdit = (product: SponsorProduct | null) => {
        setSelectedProduct(product);
        setShowProductModal(true);
    };

    const onOpen = (product: SponsorProduct) => {
        reactGa.event({
            category: GACategories.PRODUCT_CLICK,
            action: ` ${sponsor.name}/${product.title}`,
        });
        setSelectedProduct(product);
        setShowBuyProductModal(true);
    };

    useEffect(() => {
        calculateCountMissingCards();
        paginate((params) => getSponsorProducts({ ...params, sponsorID: sponsor.id }));
        return (): void => observer.disconnect();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (productRef.current) {
            observer.observe(productRef.current);
        }
    }, [list, productRef, observer]);

    if (!showPlaceholders && list.length === 0) return null;
    
    return (
        <div className="col-lg-12 col-md-12">
            <div className="content-box content-box--actions">
                <h3 className="title">{t('sponsors.store')}</h3>
                <div className="content-box__actions">
                    {showEdit && (
                        <button
                            type="button"
                            className="btn btn--primary-inverse content-box__actions__plus"
                            onClick={(): void => {
                                setShowProductModal(true);
                                setSelectedProduct(null);
                            }}
                        >
                            <span>+</span>
                        </button>
                    )}
                    <button
                        className="content-box__actions__nav"
                        type="button"
                        onClick={(): void => onMoveProductSlider(Direction.LEFT)}
                    >
                        <img className="circle-arrow circle-arrow--inverse" src={circleArrow} alt="move left arrow" />
                    </button>
                    <button
                        className="content-box__actions__nav"
                        type="button"
                        onClick={(): void => onMoveProductSlider(Direction.RIGHT)}
                    >
                        <img className="circle-arrow " src={circleArrow} alt="move right arrow" />
                    </button>
                </div>
                <div className="content-box__item content-box__item--products" ref={productsSliderRef}>
                    {showConfirmationModal && (
                        <ConfirmationModal
                            cancelTranslationKey="general.cancel"
                            deleteTranslationKey="general.delete"
                            onAccept={async (): Promise<void> => {
                                await deleteSponsorProduct(selectedProduct?.id || -1);
                                await goToPage(0);
                                setShowConfirmationModal(false);
                                setOpenedOption(-1);
                            }}
                            onClose={(): void => {
                                setShowConfirmationModal(false);
                                setOpenedOption(-1);
                            }}
                            titleTranslationKey="sponsors.product.confirmationDelete"
                        />
                    )}
                    {showProductModal && (
                        <ProductModal
                            onSave={async (): Promise<void> => {
                                await goToPage(0);
                                setOpenedOption(-1);
                            }}
                            product={selectedProduct}
                            onClose={(): void => setShowProductModal(false)}
                        />
                    )}
                    {showBuyProductModal && (
                        <BuyProductModal
                            product={selectedProduct}
                            sponsor={sponsor}
                            onClose={(): void => setShowBuyProductModal(false)}
                        />
                    )}
                    {list.map((product, i) => (
                        <ProductCard
                            key={product.id}
                            ref={i === list.length - 2 ? productRef : undefined}
                            product={product}
                            onDelete={showEdit ? (): void => {
                                setSelectedProduct(product);
                                setShowConfirmationModal(true);
                            } : undefined}
                            onEdit={showEdit ? onEdit : undefined}
                            onOpen={onOpen}
                            isOptionOpen={openedOption === product.id}
                            openOption={(): void => setOpenedOption(openedOption === product.id ? -1 : product.id)}
                        />
                    ))}
                    {showPlaceholders && Array.from({ length: numberOfPlaceholders - list.length })
                        .map((_, i) => (
                            <ProductCard
                                key={`product-placeholder-${i}`}
                                onEdit={onEdit}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
};

SponsorProductList.defaultProps = {
    showPlaceholders: false,
    showEdit: false,
};

export default withSponsorContext(withPaginationContext(withTranslationContext(SponsorProductList)));
