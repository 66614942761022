/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';

import iconShare from '../../assets/images/icon_btn_share.svg';
import iconShareWhite from '../../assets/images/icon_btn_share_white.svg';
import logo from '../../assets/images/logo_white.svg';
import runningImage from '../../assets/images/running.png';
import modalSlant from '../../assets/images/modal_slant.svg';
import copyIcon from '../../assets/images/copy.svg';
import emailIcon from '../../assets/images/mail.svg';
import facebookLogo from '../../assets/images/facebook.svg';
import IconTimes from '../assets/IconTimes';
import { WEB_URL } from '../../settings';
import { displaySuccess } from '../../utils/notifications';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

export enum StyleVariant {
    LIGHT = 'LIGHT',
    WHITE = 'WHITE',
    INVERTED = 'INVERTED'
}

/**
 * @typedef {Object} OwnProps
 * @property {string} linkURL
 */
interface OwnProps extends TranslationContext {
    linkURL: string;
    variant?: StyleVariant;
    hideText?: boolean;
    title?: string;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 * @property {boolean} showShareModal
 */
interface OwnState {
    showShareModal: boolean;
}

/**
 * @typedef {Object} State
 */
type State = OwnState;

const initialState: State = {
    showShareModal: false,
};

/**
 * Component that shows a share button
 * @param {Props} props
 * @extends {Component<Props, State>}
 * @returns {FunctionComponent<Props>}
 */
class ButtonShare extends Component<Props, State> {
    state = initialState;

    /**
     * handles button click
     * @param {React.MouseEvent} e
     */
    onButtonClick = async (e: React.MouseEvent) => {
        const { linkURL, t } = this.props;

        e.stopPropagation();

        const home = linkURL === '';

        const link = `${WEB_URL}${linkURL}`;

        const nav: any = window.navigator;
        if (nav && nav.share) {
            await nav
                .share({
                    title: home ? t('share.home.share') : t('share.profile.share'),
                    text: '',
                    url: link,
                })
                .then(() => { })
                .catch(() => { });
            return;
        }

        this.setState({
            showShareModal: true,
        });
    };

    copyText = () => {
        const { t } = this.props;
        const copyText = document.getElementById('urlToCopy') as HTMLInputElement;
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
        displaySuccess({
            message: t('share.copied'),
        });
    };

    close = (e: React.MouseEvent) => {
        e.stopPropagation();

        this.setState({
            showShareModal: false,
        });
    };

    getClassName = (variant: StyleVariant) => {
        switch (variant) {
            case StyleVariant.LIGHT:
                return 'share--light';
            case StyleVariant.WHITE:
                return '';
            case StyleVariant.INVERTED:
                return 'share--inverted';
            default:
                return '';
        }
    };

    render() {
        const {
            linkURL, t, variant, title, hideText,
        } = this.props;

        const home = linkURL === '';

        const shareURL = `${WEB_URL}${linkURL}`;

        const { showShareModal } = this.state;

        const urlFacebook = `https://www.facebook.com/sharer/sharer.php?u=${shareURL}&amp;src=sdkpreparse`;

        return (
            <Fragment>
                <button type="button" className={`share ${variant && this.getClassName(variant)}`} onClick={this.onButtonClick} data-testid="share-button">
                    <img src={variant === StyleVariant.LIGHT ? iconShareWhite : iconShare} alt="" />
                    {!hideText && <p>{t('share.label')}</p>}
                </button>
                {showShareModal && (
                    <div className="modal modal--share">
                        <div className="modal__backdrop" />

                        <div className="modal__content shared">
                            <button
                                type="button"
                                className="modal__content__close"
                                onClick={this.close}
                                data-testid="modal-close"
                            >
                                <IconTimes fill="#FFF" />
                            </button>
                            <div className="modal__content__top">
                                <div className="modal__content__top__header">
                                    <img src={logo} className="logo" alt="" />
                                    <img src={runningImage} className="running" alt="" />
                                    <img src={modalSlant} className="slant" alt="" />
                                </div>
                                <div className="modal__content__top__subheader">
                                    <h1>{title || (home ? t('share.home.title') : t('share.profile.title'))}</h1>
                                </div>
                            </div>
                            <div className="share">
                                <p>{t('share.click')}</p>
                                <div className="link-url">
                                    <input value={shareURL} type="text" readOnly id="urlToCopy" data-testid="share-url-input" />
                                    <button onClick={this.copyText} data-testid="share-copy-button" type="button">
                                        <img src={copyIcon} alt="" />
                                    </button>
                                </div>
                                <p>{t('share.social')}</p>
                                <div className="social-media">
                                    <a href={urlFacebook}>
                                        <img src={facebookLogo} alt="" />
                                    </a>
                                    <a
                                        href={
                                            home
                                                ? t('share.home.mail', { linkURL: shareURL })
                                                : t('share.profile.mail', { linkURL: shareURL })
                                        }
                                    >
                                        <img src={emailIcon} alt="" className="mail" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}

export default withTranslationContext(ButtonShare);
