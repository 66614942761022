/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { DropdownOption, KeyedObject } from '../../constants/misc';
import Dropdown from './Dropdown';

import {
    TranslationContext,
    withTranslationContext,
} from "../../components/controllers/translation/TranslationContext";

interface OwnProps {
    label?: string;
    name: string;
    value: null | DropdownOption| string;
    onChange: Function;
    placeholder?: string;
    disabled?: boolean;
    cssClass?: string;
    errors?: KeyedObject | null;
}

type Props = OwnProps & TranslationContext;

interface CountryType {
    label: string;
    value: string;
}

const SelectCountry: FunctionComponent<Props> = ({
    label,
    name,
    value,
    onChange,
    errors,
    t,
}: Props) => {
    const getOptions = (): CountryType[] => {
        return ([
            {label: t('countries.AF'), value: 'AF'},
            {label: t('countries.AX'), value: 'AX'},
            {label: t('countries.AL'), value: 'AL'},
            {label: t('countries.DZ'), value: 'DZ'},
            {label: t('countries.AS'), value: 'AS'},
            {label: t('countries.AD'), value: 'AD'},
            {label: t('countries.AO'), value: 'AO'},
            {label: t('countries.AI'), value: 'AI'},
            {label: t('countries.AQ'), value: 'AQ'},
            {label: t('countries.AG'), value: 'AG'},
            {label: t('countries.AR'), value: 'AR'},
            {label: t('countries.AM'), value: 'AM'},
            {label: t('countries.AW'), value: 'AW'},
            {label: t('countries.AU'), value: 'AU'},
            {label: t('countries.AT'), value: 'AT'},
            {label: t('countries.AZ'), value: 'AZ'},
            {label: t('countries.BS'), value: 'BS'},
            {label: t('countries.BH'), value: 'BH'},
            {label: t('countries.BD'), value: 'BD'},
            {label: t('countries.BB'), value: 'BB'},
            {label: t('countries.BY'), value: 'BY'},
            {label: t('countries.BE'), value: 'BE'},
            {label: t('countries.BZ'), value: 'BZ'},
            {label: t('countries.BJ'), value: 'BJ'},
            {label: t('countries.BM'), value: 'BM'},
            {label: t('countries.BT'), value: 'BT'},
            {label: t('countries.BO'), value: 'BO'},
            {label: t('countries.BA'), value: 'BA'},
            {label: t('countries.BW'), value: 'BW'},
            {label: t('countries.BV'), value: 'BV'},
            {label: t('countries.BR'), value: 'BR'},
            {label: t('countries.IO'), value: 'IO'},
            {label: t('countries.BN'), value: 'BN'},
            {label: t('countries.BG'), value: 'BG'},
            {label: t('countries.BF'), value: 'BF'},
            {label: t('countries.BI'), value: 'BI'},
            {label: t('countries.KH'), value: 'KH'},
            {label: t('countries.CM'), value: 'CM'},
            {label: t('countries.CA'), value: 'CA'},
            {label: t('countries.CV'), value: 'CV'},
            {label: t('countries.KY'), value: 'KY'},
            {label: t('countries.CF'), value: 'CF'},
            {label: t('countries.TD'), value: 'TD'},
            {label: t('countries.CL'), value: 'CL'},
            {label: t('countries.CN'), value: 'CN'},
            {label: t('countries.CX'), value: 'CX'},
            {label: t('countries.CC'), value: 'CC'},
            {label: t('countries.CO'), value: 'CO'},
            {label: t('countries.KM'), value: 'KM'},
            {label: t('countries.CG'), value: 'CG'},
            {label: t('countries.CD'), value: 'CD'},
            {label: t('countries.CK'), value: 'CK'},
            {label: t('countries.CR'), value: 'CR'},
            {label: t('countries.CI'), value: 'CI'},
            {label: t('countries.HR'), value: 'HR'},
            {label: t('countries.CU'), value: 'CU'},
            {label: t('countries.CY'), value: 'CY'},
            {label: t('countries.CZ'), value: 'CZ'},
            {label: t('countries.DK'), value: 'DK'},
            {label: t('countries.DJ'), value: 'DJ'},
            {label: t('countries.DM'), value: 'DM'},
            {label: t('countries.DO'), value: 'DO'},
            {label: t('countries.EC'), value: 'EC'},
            {label: t('countries.EG'), value: 'EG'},
            {label: t('countries.SV'), value: 'SV'},
            {label: t('countries.GQ'), value: 'GQ'},
            {label: t('countries.ER'), value: 'ER'},
            {label: t('countries.EE'), value: 'EE'},
            {label: t('countries.ET'), value: 'ET'},
            {label: t('countries.FK'), value: 'FK'},
            {label: t('countries.FO'), value: 'FO'},
            {label: t('countries.FJ'), value: 'FJ'},
            {label: t('countries.FI'), value: 'FI'},
            {label: t('countries.FR'), value: 'FR'},
            {label: t('countries.GF'), value: 'GF'},
            {label: t('countries.PF'), value: 'PF'},
            {label: t('countries.TF'), value: 'TF'},
            {label: t('countries.GA'), value: 'GA'},
            {label: t('countries.GM'), value: 'GM'},
            {label: t('countries.GE'), value: 'GE'},
            {label: t('countries.DE'), value: 'DE'},
            {label: t('countries.GH'), value: 'GH'},
            {label: t('countries.GI'), value: 'GI'},
            {label: t('countries.GR'), value: 'GR'},
            {label: t('countries.GL'), value: 'GL'},
            {label: t('countries.GD'), value: 'GD'},
            {label: t('countries.GP'), value: 'GP'},
            {label: t('countries.GU'), value: 'GU'},
            {label: t('countries.GT'), value: 'GT'},
            {label: t('countries.GG'), value: 'GG'},
            {label: t('countries.GN'), value: 'GN'},
            {label: t('countries.GW'), value: 'GW'},
            {label: t('countries.GY'), value: 'GY'},
            {label: t('countries.HT'), value: 'HT'},
            {label: t('countries.HM'), value: 'HM'},
            {label: t('countries.VA'), value: 'VA'},
            {label: t('countries.HN'), value: 'HN'},
            {label: t('countries.HK'), value: 'HK'},
            {label: t('countries.HU'), value: 'HU'},
            {label: t('countries.IS'), value: 'IS'},
            {label: t('countries.IN'), value: 'IN'},
            {label: t('countries.ID'), value: 'ID'},
            {label: t('countries.IR'), value: 'IR'},
            {label: t('countries.IQ'), value: 'IQ'},
            {label: t('countries.IE'), value: 'IE'},
            {label: t('countries.IM'), value: 'IM'},
            {label: t('countries.IL'), value: 'IL'},
            {label: t('countries.IT'), value: 'IT'},
            {label: t('countries.JM'), value: 'JM'},
            {label: t('countries.JP'), value: 'JP'},
            {label: t('countries.JE'), value: 'JE'},
            {label: t('countries.JO'), value: 'JO'},
            {label: t('countries.KZ'), value: 'KZ'},
            {label: t('countries.KE'), value: 'KE'},
            {label: t('countries.KI'), value: 'KI'},
            {label: t('countries.KP'), value: 'KP'},
            {label: t('countries.KR'), value: 'KR'},
            {label: t('countries.KW'), value: 'KW'},
            {label: t('countries.KG'), value: 'KG'},
            {label: t('countries.LA'), value: 'LA'},
            {label: t('countries.LV'), value: 'LV'},
            {label: t('countries.LB'), value: 'LB'},
            {label: t('countries.LS'), value: 'LS'},
            {label: t('countries.LR'), value: 'LR'},
            {label: t('countries.LY'), value: 'LY'},
            {label: t('countries.LI'), value: 'LI'},
            {label: t('countries.LT'), value: 'LT'},
            {label: t('countries.LU'), value: 'LU'},
            {label: t('countries.MO'), value: 'MO'},
            {label: t('countries.MK'), value: 'MK'},
            {label: t('countries.MG'), value: 'MG'},
            {label: t('countries.MW'), value: 'MW'},
            {label: t('countries.MY'), value: 'MY'},
            {label: t('countries.MV'), value: 'MV'},
            {label: t('countries.ML'), value: 'ML'},
            {label: t('countries.MT'), value: 'MT'},
            {label: t('countries.MH'), value: 'MH'},
            {label: t('countries.MQ'), value: 'MQ'},
            {label: t('countries.MR'), value: 'MR'},
            {label: t('countries.MU'), value: 'MU'},
            {label: t('countries.YT'), value: 'YT'},
            {label: t('countries.MX'), value: 'MX'},
            {label: t('countries.FM'), value: 'FM'},
            {label: t('countries.MD'), value: 'MD'},
            {label: t('countries.MC'), value: 'MC'},
            {label: t('countries.MN'), value: 'MN'},
            {label: t('countries.MS'), value: 'MS'},
            {label: t('countries.MA'), value: 'MA'},
            {label: t('countries.MZ'), value: 'MZ'},
            {label: t('countries.MM'), value: 'MM'},
            {label: t('countries.NA'), value: 'NA'},
            {label: t('countries.NR'), value: 'NR'},
            {label: t('countries.NP'), value: 'NP'},
            {label: t('countries.NL'), value: 'NL'},
            {label: t('countries.AN'), value: 'AN'},
            {label: t('countries.NC'), value: 'NC'},
            {label: t('countries.NZ'), value: 'NZ'},
            {label: t('countries.NI'), value: 'NI'},
            {label: t('countries.NE'), value: 'NE'},
            {label: t('countries.NG'), value: 'NG'},
            {label: t('countries.NU'), value: 'NU'},
            {label: t('countries.NF'), value: 'NF'},
            {label: t('countries.MP'), value: 'MP'},
            {label: t('countries.NO'), value: 'NO'},
            {label: t('countries.OM'), value: 'OM'},
            {label: t('countries.PK'), value: 'PK'},
            {label: t('countries.PW'), value: 'PW'},
            {label: t('countries.PS'), value: 'PS'},
            {label: t('countries.PA'), value: 'PA'},
            {label: t('countries.PG'), value: 'PG'},
            {label: t('countries.PY'), value: 'PY'},
            {label: t('countries.PE'), value: 'PE'},
            {label: t('countries.PH'), value: 'PH'},
            {label: t('countries.PN'), value: 'PN'},
            {label: t('countries.PL'), value: 'PL'},
            {label: t('countries.PT'), value: 'PT'},
            {label: t('countries.PR'), value: 'PR'},
            {label: t('countries.QA'), value: 'QA'},
            {label: t('countries.RE'), value: 'RE'},
            {label: t('countries.RO'), value: 'RO'},
            {label: t('countries.RU'), value: 'RU'},
            {label: t('countries.RW'), value: 'RW'},
            {label: t('countries.SH'), value: 'SH'},
            {label: t('countries.KN'), value: 'KN'},
            {label: t('countries.LC'), value: 'LC'},
            {label: t('countries.PM'), value: 'PM'},
            {label: t('countries.VC'), value: 'VC'},
            {label: t('countries.WS'), value: 'WS'},
            {label: t('countries.SM'), value: 'SM'},
            {label: t('countries.ST'), value: 'ST'},
            {label: t('countries.SA'), value: 'SA'},
            {label: t('countries.SN'), value: 'SN'},
            {label: t('countries.CS'), value: 'CS'},
            {label: t('countries.SC'), value: 'SC'},
            {label: t('countries.SL'), value: 'SL'},
            {label: t('countries.SG'), value: 'SG'},
            {label: t('countries.SK'), value: 'SK'},
            {label: t('countries.SI'), value: 'SI'},
            {label: t('countries.SB'), value: 'SB'},
            {label: t('countries.SO'), value: 'SO'},
            {label: t('countries.ZA'), value: 'ZA'},
            {label: t('countries.GS'), value: 'GS'},
            {label: t('countries.ES'), value: 'ES'},
            {label: t('countries.LK'), value: 'LK'},
            {label: t('countries.SD'), value: 'SD'},
            {label: t('countries.SR'), value: 'SR'},
            {label: t('countries.SJ'), value: 'SJ'},
            {label: t('countries.SZ'), value: 'SZ'},
            {label: t('countries.SE'), value: 'SE'},
            {label: t('countries.CH'), value: 'CH'},
            {label: t('countries.SY'), value: 'SY'},
            {label: t('countries.TW'), value: 'TW'},
            {label: t('countries.TJ'), value: 'TJ'},
            {label: t('countries.TZ'), value: 'TZ'},
            {label: t('countries.TH'), value: 'TH'},
            {label: t('countries.TL'), value: 'TL'},
            {label: t('countries.TG'), value: 'TG'},
            {label: t('countries.TK'), value: 'TK'},
            {label: t('countries.TO'), value: 'TO'},
            {label: t('countries.TT'), value: 'TT'},
            {label: t('countries.TN'), value: 'TN'},
            {label: t('countries.TR'), value: 'TR'},
            {label: t('countries.TM'), value: 'TM'},
            {label: t('countries.TC'), value: 'TC'},
            {label: t('countries.TV'), value: 'TV'},
            {label: t('countries.UG'), value: 'UG'},
            {label: t('countries.UA'), value: 'UA'},
            {label: t('countries.AE'), value: 'AE'},
            {label: t('countries.GB'), value: 'GB'},
            {label: t('countries.US'), value: 'US'},
            {label: t('countries.UM'), value: 'UM'},
            {label: t('countries.UY'), value: 'UY'},
            {label: t('countries.UZ'), value: 'UZ'},
            {label: t('countries.VU'), value: 'VU'},
            {label: t('countries.VE'), value: 'VE'},
            {label: t('countries.VN'), value: 'VN'},
            {label: t('countries.VG'), value: 'VG'},
            {label: t('countries.VI'), value: 'VI'},
            {label: t('countries.WF'), value: 'WF'},
            {label: t('countries.EH'), value: 'EH'},
            {label: t('countries.YE'), value: 'YE'},
            {label: t('countries.ZM'), value: 'ZM'},
            {label: t('countries.ZW'), value: 'ZW'}
        ]);
    };

    const validateValue = (value: string | DropdownOption | null) => {
        const countries = getOptions();
        if (typeof value === 'string') {
            const option = countries.find(country => country.value === value);
            return option || null;
        } 
        return value;
    }

    return (
        <Dropdown
            label={label}
            name={name}
            options={getOptions()}
            value={validateValue(value)}
            onChange={onChange}
            errors={errors}
        />
    );
};

export default withTranslationContext(SelectCountry);
