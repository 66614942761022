/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

const api = 'users';

/**
 * Users URL
 * @param {Object} params
 * @returns {string}
 */
export const usersURL = (params = {}): string => {
    const queryParams = objectToParams(params);
    return `${API_URL}/${api}${queryParams}`;
};

/**
 * Admin Users URL
 * @param {Object} params
 * @returns {string}
 */
export const adminUsersURL = (params = {}): string => {
    const queryParams = objectToParams(params);
    return `${API_URL}/${api}/auth${queryParams}`;
};

/**
 * User URL
 * @param {any} [id]
 * @returns {string}
 */
export const userURL = (id: any): string => {
    return `${API_URL}/${api}/${id}`;
};

/**
 * User Toggle URL
 * @param {any} [id]
 * @returns {string}
 */
export const toggleVisibilityURL = (id: any): string => {
    return `${API_URL}/${api}/${id}/profile-state`;
};

/**
 * User Sportests URL
 * @param {React.Key} id
 * @returns {string}
 */
export const userSportestsURL = (id: React.Key): string => {
    return `${API_URL}/${api}/${id}/sportests`;
};
