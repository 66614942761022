/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { ComponentType, createContext, FC } from 'react';
import { ApiError, KeyedObject, List } from '../../../constants/misc';
import {
    PendingAthleteSupporters,
    SponsorAthletesSupport,
    SponsorProduct,
    SponsorProductsRequest,
} from '../../../constants/sponsor';
import { Badge, Sponsor } from '../../../constants/user';

import { ConnectedSponsorsController } from './SponsorController';
import { SUPPORT_STATUS } from '../../../constants/support';

export interface SponsorContext {
    pendingAthleteSupporters: PendingAthleteSupporters;
    showAthleteSupporters: boolean;
    submitProduct: (payload: SponsorProductsRequest, onSuccess: () => void, onFailure: (data?: ApiError) => void) => Promise<void>;
    getSponsoredAthletesBadges: (sponsorId: string) => Promise<Badge[]>;
    getSponsorsBadges: (athleteId: string) => Promise<Badge[]>;
    getSponsorProducts: (params: Record<string, string | number>) => Promise<List<SponsorProduct>>;
    getSponsorProduct: (productId: number) => Promise<SponsorProduct | null>;
    deleteSponsorProduct: (productId: number) => Promise<void>;
    getSponsor: (sponsorId: number) => Promise<Sponsor | null>;
    getAthleteSupport: (supportId: number) => Promise<SponsorAthletesSupport | null>;
    fetchAthletePendingSupports: () => Promise<void>;
    resetAthletePendingSupports: () => void;
    getAthletesSupport: (params: Record<string, number>) => Promise<List<SponsorAthletesSupport>>;
    getSupportsAthlete: (params: Record<string, number>) => Promise<List<SponsorAthletesSupport>>;
    patchAcceptSupport: (supportId: number, onSuccess: () => void, onFailure: (error: KeyedObject) => void) => Promise<void>;
    patchRejectSupport: (supportId: number, onSuccess: () => void, onFailure: (error: KeyedObject) => void) => Promise<void>;
    patchCancelSupport: (supportId: number, status: SUPPORT_STATUS, onSuccess: () => void, onFailure: (error: KeyedObject) => void) => Promise<void>;
}

// Create the context
export const sponsorContextDefaultValue = {
    pendingAthleteSupporters: {
        list: [],
        total: 0,
    },
    showAthleteSupporters: false,
    submitProduct: async (): Promise<void> => {},
    getSponsoredAthletesBadges: async (): Promise<Badge[]> => [],
    getSponsorsBadges: async (): Promise<Badge[]> => [],
    getSponsorProducts: async (): Promise<List<SponsorProduct>> => ({ list: [], total: 0 }),
    getSponsorProduct: async (): Promise<SponsorProduct | null> => null,
    deleteSponsorProduct: async (): Promise<void> => {},
    getSponsor: async (): Promise<Sponsor | null> => null,
    getAthleteSupport: async (): Promise<SponsorAthletesSupport | null> => null,
    fetchAthletePendingSupports: async (): Promise<void> => {},
    resetAthletePendingSupports: (): void => {},
    getAthletesSupport: async (): Promise<List<SponsorAthletesSupport>> => ({ list: [], total: 0 }),
    getSupportsAthlete: async (): Promise<List<SponsorAthletesSupport>> => ({ list: [], total: 0 }),
    patchAcceptSupport: async (): Promise<void> => {},
    patchRejectSupport: async (): Promise<void> => {},
    patchCancelSupport: async (): Promise<void> => {},
};

const SponsorsContext = createContext<SponsorContext | null>(sponsorContextDefaultValue);

// Export Provider and Consumer
export const SponsorContextProvider = SponsorsContext.Provider;
export const SponsorContextConsumer = SponsorsContext.Consumer;

export const withSponsorContext = <P extends Record<string, unknown>>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof SponsorContext>> => (props) => (
    <ConnectedSponsorsController>
        <SponsorContextConsumer>{(ctx) => <Component {...(props as P)} {...ctx} />}</SponsorContextConsumer>
    </ConnectedSponsorsController>
    );
