/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import axios, { AxiosError } from 'axios';

import { decode } from 'jsonwebtoken';
import { AuthenticationAction, AuthenticationActionTypes } from './authentication_types';
import {
    AccountValidationFormFields,
    AuthenticationPayload,
    LoginPostData,
    RecoverPasswordFormFields,
    RegistrationFormFields,
    ResendActivationFormFields,
    ResetPasswordPostData,
} from '../constants/authentication';
import {
    AdminPermissions, AthletePermissions, FanPermissions, ManagerPermissions, SponsorPermissions,
} from '../constants/authorization';
import {
    loginURL,
    recoverPasswordURL,
    resendActivationURL,
    resetPasswordURL,
    validateAccountURL,
} from '../services/authentication';
import { sponsorInviteURL, sponsorsURL } from '../services/sponsors';
import { athleteInviteURL, athletesURL } from '../services/athletes';
import { User, UserRoles, UserTypes } from '../constants/user';
import { permissionsResetActionCreator, permissionsSetActionCreator } from './authorization';
import { adminInviteURL } from '../services/admins';
import { fansURL } from '../services/fans';
import { managerInviteURL, managersURL } from '../services/managers';
import { AuthenticationState } from '../reducers/types';

/**
 * signals the login request is being made
 * @returns {AuthenticationActionTypes}
 */
export const loginRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_REQUEST,
    };
};

/**
 * received login success
 * @param {object} auth
 * @returns {AuthenticationActionTypes}
 */
export const loginSuccessActionCreator = (auth: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_SUCCESS,
        payload: auth,
    };
};

/**
 * received login failure
 * @returns {AuthenticationActionTypes}
 */
export const loginFailureActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_FAILURE,
    };
};

/**
 * signals the logout request is being made
 * @returns {AuthenticationActionTypes}
 */
export const logoutRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGOUT_REQUEST,
    };
};

/**
 * sets authentication properties
 * @param {object} auth
 * @returns {AuthenticationActionTypes}
 */
export const setAuthenticationActionCreator = (auth: Partial<AuthenticationState>): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.SET_AUTHENTICATION,
        payload: auth,
    };
};

export const setAuthenticatedUserActionCreator = (user: User): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.SET_AUTHENTICATED_USER,
        payload: user,
    };
};
/**
 * resets the authentication properties
 * @returns {AuthenticationActionTypes}
 */
export const resetAuthenticationActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.RESET_AUTHENTICATION,
    };
};

/**
 * signals the registration request is being made
 * @returns {AuthenticationActionTypes}
 */
export const registrationRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.REGISTRATION_REQUEST,
    };
};

/**
 * received registration success
 * @param {AuthenticationPayload} auth
 * @returns {AuthenticationActionTypes}
 */
export const registrationSuccessActionCreator = (auth: AuthenticationPayload): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.REGISTRATION_SUCCESS,
        payload: auth,
    };
};

/**
 * received registration failure
 * @returns {AuthenticationActionTypes}
 */
export const registrationFailureActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.REGISTRATION_FAILURE,
    };
};

/**
 * signals the account validation request is being made
 * @returns {AuthenticationActionTypes}
 */
export const accountValidationRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.ACCOUNT_VALIDATION_REQUEST,
    };
};

/**
 * received account validation success
 * @param {AuthenticationPayload} auth
 * @returns {AuthenticationActionTypes}
 */
export const accountValidationSuccessActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.ACCOUNT_VALIDATION_SUCCESS,
    };
};

/**
 * received account validation failure
 * @returns {AuthenticationActionTypes}
 */
export const accountValidationFailureActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.ACCOUNT_VALIDATION_FAILURE,
    };
};

/**
 * signals the resend activation request is being made
 * @returns {AuthenticationActionTypes}
 */
export const resendActivationRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.RESEND_ACTIVATION_REQUEST,
    };
};

/**
 * received resend activation success
 * @returns {AuthenticationActionTypes}
 */
export const resendActivationSuccessActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.RESEND_ACTIVATION_SUCCESS,
    };
};

/**
 * received resend activation failure
 * @returns {AuthenticationActionTypes}
 */
export const resendActivationFailureActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.RESEND_ACTIVATION_FAILURE,
    };
};

/**
 * makes the login request and sets the necessary data in the store
 * @param {LoginPostData} formData
 * @param {Function} onSuccess
 * @param {Function} onFailure
 */
export const requestLogin = (formData: LoginPostData, onSuccess: Function, onFailure: Function) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(loginRequestActionCreator());
        try {
            const { status, data, headers } = await axios.post(loginURL(), formData);

            if (status === 200) {
                const authorizationToken = headers.authorization;

                dispatch(
                    loginSuccessActionCreator({
                        user: data,
                        token: authorizationToken,
                    }),
                );

                const { groups }: any = decode(authorizationToken);
                const userGroups: Array<string> = groups;

                if (userGroups && userGroups.includes(UserRoles.Athlete)) {
                    dispatch(permissionsSetActionCreator(AthletePermissions));
                } else if (userGroups && userGroups.includes(UserRoles.Sponsor)) {
                    dispatch(permissionsSetActionCreator(SponsorPermissions));
                } else if (userGroups && userGroups.includes(UserRoles.Admin)) {
                    dispatch(permissionsSetActionCreator(AdminPermissions));
                } else if (userGroups && userGroups.includes(UserRoles.Fan)) {
                    dispatch(permissionsSetActionCreator(FanPermissions));
                } else if (userGroups && userGroups.includes(UserRoles.Manager)) {
                    dispatch(permissionsSetActionCreator(ManagerPermissions));
                }

                onSuccess(data);
            }
        } catch (error) {
            let formErrors = {};
            const err = error as AxiosError;
            if (err && err.response) {
                formErrors = err.response.data;
            }
            dispatch(loginFailureActionCreator());
            onFailure(formErrors);
        }
    };
};

/**
 * makes the authentication data in the store
 */
export const setAuthentication = (payload: Partial<AuthenticationState>, onSuccess: Function) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(setAuthenticationActionCreator(payload));
        onSuccess();
    };
};

/**
 * makes the logout request and sets the necessary data in the store
 */
export const requestLogout = (onSuccess: Function) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(resetAuthenticationActionCreator());
        dispatch(permissionsResetActionCreator());
        onSuccess();
    };
};

/**
 * makes the recover password request and sets the necessary data in the store
 */
export const requestRecoverPassword = (
    formData: RecoverPasswordFormFields,
    onSuccess: Function,
    onFailure: Function,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            const { status } = await axios.post(recoverPasswordURL(), formData);

            if (status === 200) {
                onSuccess();
            }
        } catch (error) {
            let formErrors = {};
            const err = error as AxiosError;
            if (err && err.response) {
                formErrors = err.response.data;
            }
            onFailure(formErrors);
        }
    };
};

/**
 * makes the reset password request and sets the necessary data in the store
 */
export const requestResetPassword = (formData: ResetPasswordPostData, onSuccess: Function, onFailure: Function) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            const { status } = await axios.post(resetPasswordURL(), formData);

            if (status === 200) {
                onSuccess();
            }
        } catch (error) {
            let formErrors = {};
            const err = error as AxiosError;
            if (err && err.response) {
                formErrors = err.response.data;
            }
            onFailure(formErrors);
        }
    };
};

/**
 * makes the registration request and sets the necessary data in the store
 * @param {RegistrationFormFields} formData
 * @param {UserTypes} userType
 * @param {Function} onSuccess
 * @param {Function} onFailure
 */
export const requestRegistration = (
    formData: RegistrationFormFields,
    userType: UserTypes,
    onSuccess: Function,
    onFailure: Function,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(registrationRequestActionCreator());
        try {
            let url = '';
            switch (userType) {
                case UserTypes.Athlete:
                    url = athletesURL();
                    break;
                case UserTypes.Fan:
                    url = fansURL();
                    break;
                case UserTypes.Manager:
                    url = managersURL();
                    break;
                default:
                    url = sponsorsURL();
            }

            const { recaptcha, ...payload } = formData;
            const { status, data } = await axios.post(url, payload, {
                params: {
                    'g-recaptcha-response': recaptcha,
                },
            });

            if (status === 200) {
                dispatch(registrationSuccessActionCreator(data));
                onSuccess();
            }
        } catch (error) {
            let formErrors = {};
            const err = error as AxiosError;
            if (err && err.response) {
                formErrors = err.response.data;
            }
            dispatch(registrationFailureActionCreator());
            onFailure(formErrors);
        }
    };
};

/**
 * makes the registration request and sets the necessary data in the store
 * @param {RegistrationFormFields} formData
 * @param {UserTypes} userType
 * @param {Function} onSuccess
 * @param {Function} onFailure
 */
export const requestRegistrationInvite = (
    formData: RegistrationFormFields,
    userType: UserTypes,
    onSuccess: Function,
    onFailure: Function,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(registrationRequestActionCreator());
        try {
            let url = '';
            switch (userType) {
                case UserTypes.Admin:
                    url = adminInviteURL();
                    break;
                case UserTypes.Athlete:
                    url = athleteInviteURL();
                    break;
                case UserTypes.Manager:
                    url = managerInviteURL();
                    break;
                default:
                    url = sponsorInviteURL();
            }
            const { status, data, headers } = await axios.post(url, formData);

            if (status === 200) {
                const authorizationToken = headers.authorization;

                dispatch(
                    accountValidationSuccessActionCreator(),
                );

                const { groups }: any = decode(authorizationToken);
                const userGroups: Array<string> = groups;

                if (userGroups && userGroups.includes(UserRoles.Athlete)) {
                    dispatch(permissionsSetActionCreator(AthletePermissions));
                } else if (userGroups && userGroups.includes(UserRoles.Sponsor)) {
                    dispatch(permissionsSetActionCreator(SponsorPermissions));
                } else if (userGroups && userGroups.includes(UserRoles.Admin)) {
                    dispatch(permissionsSetActionCreator(AdminPermissions));
                }

                onSuccess(data.role);
            }
        } catch (error) {
            let formErrors = {};
            const err = error as AxiosError;
            if (err && err.response) {
                formErrors = err.response.data;
            }
            dispatch(registrationFailureActionCreator());
            onFailure(formErrors);
        }
    };
};

/**
 * makes the registration request and sets the necessary data in the store
 * @param {AccountValidationFormFields} formData
 * @param {Function} onSuccess
 * @param {Function} onFailure
 */
export const requestAccountValidation = (
    formData: AccountValidationFormFields,
    onSuccess: Function,
    onFailure: Function,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(accountValidationRequestActionCreator());
        try {
            await axios.post(validateAccountURL(), formData).then(() => {
                dispatch(
                    accountValidationSuccessActionCreator(),
                );
            });
            onSuccess();
        } catch (error) {
            let formErrors = {};
            const err = error as AxiosError;
            if (err && err.response) {
                formErrors = err.response.data;
            }
            dispatch(accountValidationFailureActionCreator());
            onFailure(formErrors);
        }
    };
};

/**
 * makes the resend activation request and sets the necessary data in the store
 * @param {ResendActivationFormFields} formData
 * @param {Function} onSuccess
 * @param {Function} onFailure
 */
export const requestResendActivation = (
    formData: ResendActivationFormFields,
    onSuccess: Function,
    onFailure: Function,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(resendActivationRequestActionCreator());
        try {
            const { status } = await axios.post(resendActivationURL(), formData);

            if (status === 200) {
                dispatch(resendActivationSuccessActionCreator());
                onSuccess();
            }
        } catch (error) {
            let formErrors = {};
            const err = error as AxiosError;
            if (err && err.response) {
                formErrors = err.response.data;
            }
            dispatch(resendActivationFailureActionCreator());
            onFailure(formErrors);
        }
    };
};
