/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import { DropdownOption } from './misc';
import { Club } from './user';

export enum AGE_GROUP {
    CHILDREN = 'CHILDREN',
    YOUTH = 'YOUTH',
    ADULTS = 'ADULTS',
    SENIORS = 'SENIORS'
}

export enum COMPETITION_LEVEL {
    LOCAL = 'LOCAL',
    NATIONAL = 'NATIONAL',
    INTERNATIONAL = 'INTERNATIONAL',
}

export interface AchievementFields {
    date: Date;
    competitionLevel: DropdownOption | null;
    ageGroup: DropdownOption | null;
    club: DropdownOption | null;
    result: DropdownOption | null;
}

export interface Achievement {
    id: number;
    date: Date;
    competitionLevel: COMPETITION_LEVEL;
    ageGroup: AGE_GROUP;
    club: Club;
    result: number;
}

export interface AchievementRequest {
    date: Date;
    competitionLevel: COMPETITION_LEVEL;
    ageGroup: AGE_GROUP;
    club: Club | null;
    result: number;
}
