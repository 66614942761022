/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    ATHLETE_ROUTE,
    FAN_PROFILE_ROUTE, FAN_ROUTE, INDEX_ROUTE, MANAGER_PROFILE_ROUTE, MANAGER_ROUTE, PROFILE_ROUTE, SPONSOR_PROFILE_ROUTE, SPONSOR_ROUTE,
} from '../../constants/routes';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { withAuthenticationContext, AuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import add from '../../assets/images/add.svg';
import { User, UserRoles } from '../../constants/user';
import { adminUsersURL, toggleVisibilityURL } from '../../services/users';
import { fileURL } from '../../services/files';
import IconEdit from '../assets/IconEdit';
import { displayError } from '../../utils/notifications';
import IconCheckbox from '../assets/IconCheckbox';
import TablePagination from '../elements/TablePagination';
import InviteScreen from './InviteScreen';
import IconCheckedBox from '../assets/IconCheckedBox';
import { hasAnyErrors } from '../../utils/validations';
import { ErrorCode } from '../../constants/errors';
import iconDelete from '../../assets/images/delete.svg';
import { ConfirmationModal } from '../elements/ConfirmationModal';
import { UserContext, withUserContext } from '../controllers/user/UserContext';
import { IconCreditCard } from '../assets/IconCreditCard';
import CreditEditModal from '../elements/CreditEditModal';

/**
 * @typedef {Object} OwnProps
 * @extends {TranslationContext, AuthenticationContext, RouteComponentProps}
 */
interface OwnProps extends TranslationContext, RouteComponentProps, AuthenticationContext, UserContext {}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

interface Params {
    _limit: number;
    _page: number;
    _sort: string;
    _order: 'asc' | 'desc';
}

/**
 * @typedef {Object} OwnState
 */
interface OwnState {
    selectedUser?: User;
    users: User[];
    totalUsers: number;
    params: Params;
    showInvite: boolean;
    showConfirmationModal: boolean;
    showCrediManagementModal: boolean;
}

/**
 * @typedef {Object} State
 */
type State = OwnState;

const initialState: State = {
    selectedUser: undefined,
    users: [],
    totalUsers: 0,
    params: {
        _limit: 10,
        _page: 0,
        _order: 'asc',
        _sort: 'name',
    },
    showInvite: false,
    showConfirmationModal: false,
    showCrediManagementModal: false,
};

/**
 * shows the users screen
 * @extends {Component<Props, State>}
 */
class UsersScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount(): void {
        const { user, history } = this.props;
        if (user?.role !== UserRoles.Admin) {
            history.push(INDEX_ROUTE);
        }
        this.fetchUsers();
    }

    onEditClick = (user: User): void => {
        const { history } = this.props;
        switch (user.role) {
            case UserRoles.Athlete:
                history.push(`${PROFILE_ROUTE}/${user.id}`);
                break;
            case UserRoles.Manager:
                history.push(`${MANAGER_PROFILE_ROUTE}/${user.id}`);
                break;
            case UserRoles.Sponsor:
                history.push(`${SPONSOR_PROFILE_ROUTE}/${user.id}`);
                break;
            case UserRoles.Fan:
                history.push(`${FAN_PROFILE_ROUTE}/${user.id}`);
                break;
            default:
                break;
        }
    };

    onCreditCardClick = (user: User): void => {
        this.setState({
            selectedUser: user,
            showCrediManagementModal: true,
        });
    };

    onUserClick = (user: User): void => {
        const { history } = this.props;
        switch (user.role) {
            case UserRoles.Athlete:
                history.push(`${ATHLETE_ROUTE}/${user.id}`);
                break;
            case UserRoles.Manager:
                history.push(`${MANAGER_ROUTE}/${user.id}`);
                break;
            case UserRoles.Sponsor:
                history.push(`${SPONSOR_ROUTE}/${user.id}`);
                break;
            case UserRoles.Fan:
                history.push(`${FAN_ROUTE}/${user.id}`);
                break;
            default:
                break;
        }
    };

    onDeleteUserIconClick = (user: User): void => {
        this.setState({
            selectedUser: user,
            showConfirmationModal: true,
        });
    }

    onSortChange = (field: string): void => {
        const { params } = this.state;
        this.setState({
            params: {
                ...params,
                _sort: field,
                _order: (field === params._sort && params._order !== 'desc') ? 'desc' : 'asc',
            },
        }, this.fetchUsers);
    }

    deleteUser = async () => {
        const { selectedUser } = this.state;
        const { deleteUser } = this.props;
        if (selectedUser) {
            await deleteUser(selectedUser);
            this.setState({
                selectedUser: undefined,
                showConfirmationModal: false,
            }, () => this.fetchUsers());
        }
    }

    fetchUsers = (): void => {
        const { params } = this.state;
        axios
            .get(adminUsersURL(params))
            .then(({ data, headers }) => this.setState({ users: data, totalUsers: headers['x-total-count'] }))
            .catch(() => {});
    };

    setParams = (name: string, value: number): void => {
        this.setState(
            {
                params: {
                    ...this.state.params,
                    [name]: value,
                },
            },
            this.fetchUsers,
        );
    };

    toggleVisibility = (userId: number): void => {
        const { t } = this.props;
        axios
            .put(toggleVisibilityURL(userId))
            .then(this.fetchUsers)
            .catch(e => {
                const res = e?.response?.data;

                let message = t('admin.users.failToggle');
                if (hasAnyErrors(res?.errors)) {
                    const error = res.errors[0];
                    message = t(`errors.${ErrorCode[error.errorCode]}`);
                }

                displayError({ message });
            });
    };

    render(): React.ReactNode {
        const { t, user: adminUser } = this.props;
        const {
            users, totalUsers, params, showInvite, showConfirmationModal, showCrediManagementModal, selectedUser,
        } = this.state;

        return (
            <div className="admin-screen" data-testid="athlete-screen">
                {showInvite && <InviteScreen close={() => this.setState({ showInvite: false })} />}
                {showConfirmationModal && (
                    <ConfirmationModal
                        onAccept={this.deleteUser}
                        onClose={() => this.setState({ showConfirmationModal: false })}
                        titleTranslationKey={{ key: 'profile.deleteConfirmation', params: { name: selectedUser?.name } }}
                        cancelTranslationKey="gallery.cancel"
                        deleteTranslationKey="gallery.delete"
                    />
                )}
                {showCrediManagementModal && selectedUser && (
                    <CreditEditModal
                        user={selectedUser}
                        onClose={(): void => {
                            this.fetchUsers();
                            this.setState({ showCrediManagementModal: false });
                        }}
                    />
                )}
                <div className="admin-screen__content">
                    <div className="admin-screen__content__header">
                        <h3 className="title">{t('admin.users.title')}</h3>
                        <button
                            type="button"
                            onClick={() => this.setState({ showInvite: true })}
                            className="btn btn--primary-inverse"
                            data-testid="admin-invite"
                        >
                            <img src={add} alt="add" />
                        </button>
                    </div>
                    <h4>{t('admin.users.users')}</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>{t('admin.users.actions')}</th>
                                <th>
                                    <button type="button" onClick={(): void => this.onSortChange('name')}>
                                        {t('admin.users.name')}
                                    </button>
                                </th>
                                <th>
                                    <button type="button" onClick={(): void => this.onSortChange('email')}>
                                        {t('admin.users.contact')}
                                    </button>
                                </th>
                                <th>
                                    <button type="button" onClick={(): void => this.onSortChange('role')}>
                                        {t('admin.users.access')}
                                    </button>
                                </th>
                                <th>
                                    <button type="button" onClick={(): void => this.onSortChange('hiddenProfile')}>
                                        {t('admin.users.public')}
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user.id}>
                                    <td className="table-action">
                                        { adminUser && adminUser.id !== user.id && (
                                            <img className="tab-item" src={iconDelete} alt="delete" onClick={(): void => this.onDeleteUserIconClick(user)} />
                                        )}
                                        { user.role !== UserRoles.Admin && (
                                            <>
                                                <IconEdit fill="#cccccc" onClick={(): void => this.onEditClick(user)} testId="user-edit" />
                                                <IconCreditCard onClick={(): void => this.onCreditCardClick(user)} />
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        <div className="clickable" data-testid="user-info" onClick={() => { this.onUserClick(user); }}>
                                            <img src={fileURL(user.avatar?.id)} alt="" />
                                            {`${user.name} ${user?.lastName || ''}`}
                                        </div>
                                    </td>
                                    <td>{user.email}</td>
                                    <td>{user.role}</td>
                                    <td>
                                        {user.hiddenProfile ? (
                                            <IconCheckbox
                                                testId="users-checkbox"
                                                fill="#278982"
                                                onClick={(): void => this.toggleVisibility(user.id)}
                                            />
                                        ) : (
                                            <IconCheckedBox
                                                testId="users-checkbox"
                                                fill="#278982"
                                                onClick={(): void => this.toggleVisibility(user.id)}
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <TablePagination
                        total={totalUsers}
                        current={users.length}
                        page={params._page}
                        limit={params._limit}
                        setParams={this.setParams}
                    />
                </div>
            </div>

        );
    }
}

export default withRouter(withAuthenticationContext(withTranslationContext(withUserContext(UsersScreen))));
