/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export const INDEX_ROUTE = '/';
export const ABOUT_ROUTE = '/about';
export const ATHLETE_ROUTE = '/athlete';
export const GALLERY = 'gallery';
export const ATHLETE_GALLERY_ROUTE = `/athlete/:id/${GALLERY}`;
export const FAN_ROUTE = '/fan';
export const FAN_GALLERY_ROUTE = `/fan/:id/${GALLERY}`;
export const SPONSOR_ROUTE = '/sponsor';
export const SPONSOR_GALLERY_ROUTE = `/sponsor/:id/${GALLERY}`;
export const MANAGER_ROUTE = '/manager';
export const MANAGER_GALLERY_ROUTE = `/manager/:id/${GALLERY}`;
export const NOT_FOUND_ROUTE = '/not_found';
export const VALIDATE_ACCOUNT_ROUTE = '/validate-account/:token';
export const PROFILE_ROUTE = '/profile/athlete';
export const PROFILE_SUPPORT_ROUTE = '/profile/athlete/support/:id';
export const CC_SUCCESS_ROUTE = '/payment/success';
export const CC_FAIL_ROUTE = '/payment/fail';
export const FAN_PROFILE_ROUTE = '/profile/fan';
export const SPONSOR_PROFILE_ROUTE = '/profile/sponsor';
export const MANAGER_PROFILE_ROUTE = '/profile/manager';
export const TEST_ROUTE = '/sportest';
export const ADMIN_USERS_ROUTE = '/admin/users';
export const ADMIN_INVITE_ROUTE = '/admin/users/add';
export const INVITE_ROUTE = '/register/:token';
export const RESET_PASSWORD_ROUTE = '/reset-password/:token';
export const REDE_SPORTRACK = '/rede-sportrack';
export const MARKET_ROUTE = '/market';
export const CREDIT_ROUTE = '/market/credits';
export const PRODUCT_ROUTE = '/sponsor/product';
