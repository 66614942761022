/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';

import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { UserContext, withUserContext } from '../../controllers/user/UserContext';
import iconPin from '../../../assets/images/icon_pin.svg';
import badgeStar from '../../../assets/images/badge_star.svg';
import defaultAvatar from '../../../assets/images/avatar_default.svg';
import DrawerGallery from '../../elements/DrawerGallery';
import ButtonContact from '../../elements/ButtonContact';
import ButtonShare from '../../elements/ButtonShare';
import Avatar from '../../elements/Avatar';
import defaultCover from '../../../assets/images/athlete_header_bg.png';
import { MatchParams, Media } from '../../../constants/misc';
import { userURL } from '../../../services/users';
import ProfileError from '../../elements/ProfileError';
import { MANAGER_ROUTE } from '../../../constants/routes';
import { Badge, User } from '../../../constants/user';
import { enumToString } from '../../../utils/format';
import Map from '../../elements/Map';
import IconPhone from '../../assets/IconPhone';
import { SponsorContext, withSponsorContext } from '../../controllers/sponsor/SponsorContext';
import Badges from '../../elements/Badges';

/**
 * @typedef {Object} OwnProps
 * @extends {RouteComponentProps<MatchParams>, AuthenticationContext, TranslationContext, UserContext}
 */
interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContext, TranslationContext, UserContext, SponsorContext {}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 * @property {boolean} showGallery
 * @property {Media} coverPhoto
 * @property {Media} avatar
 * @property {string} biography
 * @property {string} name
 * @property {string} address
 * @property {any} [mediaList]
 * @property {string} phrase
 * @property {boolean} error
 * @property {User | null} userData
 * @property {Sportest | null} lastSportest
 */
interface OwnState {
    showGallery: boolean;
    coverPhoto: Media | null;
    avatar: Media | null;
    biography: string;
    name: string;
    lastName: string;
    address: string;
    mediaList: any;
    objectives: string;
    phrase: string;
    phone: string;
    linkURL: string;
    error: boolean;
    userData: User | null;
    modality: string | null;
    sponsoredAthletes: Badge[];
}

/**
 * @typedef {Object} State
 */
type State = OwnState;

const initialState: State = {
    showGallery: false,
    coverPhoto: null,
    avatar: null,
    biography: '',
    name: '',
    lastName: '',
    modality: null,
    address: '',
    mediaList: [],
    objectives: '',
    phrase: '',
    phone: '',
    linkURL: '',
    error: false,
    userData: null,
    sponsoredAthletes: [],
};

/**
 * shows the fan screen
 * @extends {Component<Props, State>}
 */
class ManagerScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount() {
        const { match } = this.props;

        window.scrollTo(0, 0);

        if (match && match.params && match.params.id) {
            this.prepare(match.params.id);
        }
    }

    /**
     * handles gallery toggle click
     * @param {React.MouseEvent} e
     */
    onToggleGallery = (): void => {
        const { showGallery } = this.state;

        this.setState({
            showGallery: !showGallery,
        });
    };

    prepare = async (id: string): Promise<void> => {
        const {
            getSponsoredAthletesBadges,
        } = this.props;

        const promises: [Promise<AxiosResponse>, Promise<Badge[]>] = [axios.get(userURL(id)), getSponsoredAthletesBadges(id)];

        let res = [];
        try {
            res = await Promise.all(promises);
        } catch (error) {
            this.setState({
                error: true,
            });
            return;
        }

        const [userResponse, sponsoredAthletes] = res;

        const { data } = userResponse;

        if (data) {
            const {
                coverPhoto, avatar, biography, name, lastName, address, phrase, phone, modality,
            } = data;

            this.setState({
                coverPhoto,
                avatar,
                biography,
                name,
                lastName,
                address,
                modality,
                phone,
                phrase,
                userData: data,
                linkURL: `${MANAGER_ROUTE}/${id}`,
                sponsoredAthletes,
            });
        }
    }

    render(): React.ReactNode {
        const {
            avatar,
            coverPhoto,
            showGallery,
            name,
            lastName,
            modality,
            phrase,
            biography,
            linkURL,
            error,
            userData,
            address,
            phone,
            sponsoredAthletes,
        } = this.state;
        const { t } = this.props;

        const currentCover = coverPhoto?.main || defaultCover;
        const currentAvatar = avatar?.main || null;

        return (
            <div className="profile-screen" data-testid="manager-screen">
                <ProfileError show={error} close={(): void => this.setState({ error: false })} />
                <div className="profile-screen__body">
                    <div className="profile-screen__body__left">
                        <div className="profile-screen__body__left__header">
                            <div className="cover">
                                <div className="cover__image" style={{ backgroundImage: `url(${currentCover})` }} />
                            </div>
                            <div className="buttons">
                                <ButtonContact userPageOwner={userData} />
                                <ButtonShare linkURL={linkURL} />
                            </div>
                            <Avatar image={currentAvatar || defaultAvatar} badge={badgeStar} />
                            <button
                                type="button"
                                className="btn btn--primary-dark gallery-btn"
                                onClick={this.onToggleGallery}
                                data-testid="gallery-toggle"
                            >
                                {t('athlete.galery')}
                            </button>
                        </div>
                        {!showGallery ? (
                            <div className="profile-screen__body__left__content">
                                <div className="profile-top">
                                    <div className="athlete-info">
                                        <h1>
                                            {name} {lastName}
                                        </h1>
                                        <h2>{modality ? enumToString(modality) : ''}</h2>
                                    </div>
                                </div>
                                <div className="profile-info">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="content-box">
                                                <h3 className="title">{t('athlete.about')}</h3>
                                                <h4>{phrase}</h4>
                                                <p>{biography || t('general.noInfo')}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="content-box">
                                                <h3 className="title">{t('profile.myLocation')}</h3>
                                                <div className="content-box__item content-box__item--centered">
                                                    <img className="icn icn--green" src={iconPin} alt="" />
                                                    <p>{address}</p>
                                                </div>
                                                <div className="content-box__item content-box__item--centered">
                                                    <IconPhone />
                                                    <p>{phone}</p>
                                                </div>
                                                <Map location={address} />
                                            </div>
                                            <div className="content-box">
                                                <div className="content-box__with-button">
                                                    <h3 className="title">{t('athlete.supportedAthletes')}</h3>
                                                </div>
                                                <div className="content-box__item content-box__item--sponsored">
                                                    {sponsoredAthletes.map((sponsoredAthlete) => (
                                                        <Badges
                                                            userId={sponsoredAthlete.id}
                                                            userRole={sponsoredAthlete.role}
                                                            badgeId={sponsoredAthlete.badgeUrl.id}
                                                            badgeUrl={sponsoredAthlete.badgeUrl.main}
                                                            refreshScreen={this.prepare}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div data-testid="mobile-gallery" className="profile-screen__body__left__gallery">
                                <DrawerGallery galleryUser={userData} />
                            </div>
                        )}
                    </div>
                    <div className="profile-screen__body__right">
                        <DrawerGallery galleryUser={userData} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withSponsorContext(withUserContext(withTranslationContext(ManagerScreen))));
