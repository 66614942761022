/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import superateFrontpage from '../../assets/images/superate_frontpage.png';
import logoFrontpage from '../../assets/images/logo.svg';
import { MatchParams } from '../../constants/misc';

import { AuthorizationContext, withAuthorizationContext } from '../controllers/authorization/AuthorizationContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

/**
 * @typedef {Object} OwnProps
 * @extends {RouteComponentProps<MatchParams>, AuthenticationContext}
 */
interface OwnProps
    extends RouteComponentProps<MatchParams>,
        AuthenticationContext,
        AuthorizationContext,
        TranslationContext {}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * shows the initial screen
 * @extends {Component<Props>}
 */
class HomeScreen extends Component<Props> {
    componentDidMount(): void {
        const { history, openRegistrationInvite } = this.props;
        if (history.location.pathname.includes('/register/')) {
            openRegistrationInvite();
        }
    }

    render(): React.ReactNode {
        const { t } = this.props;

        return (
            <React.Fragment>
                <section className="home-screen" data-testid="home-screen">
                    <div className="left">
                        <div className="left__content">
                            <span>{t('home.title1')}</span>
                            <img src={superateFrontpage} alt="" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="top">
                            <img className="sportrack-logo" src={logoFrontpage} alt="" />
                        </div>
                        <div className="bottom">
                            <div>
                                <p>{t('home.title')}</p>
                                <h3>
                                    <span>{t('home.header1')}</span>
                                    <span>{t('home.header2')}</span>
                                    <span>{t('home.header3')}</span>
                                </h3>
                            </div>
                            <p className="lighter">{t('home.description')}</p>
                        </div>
                    </div>
                </section>

            </React.Fragment>
        );
    }
}

export default withRouter(withTranslationContext(withAuthorizationContext(withAuthenticationContext(HomeScreen))));
