/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import {
    RouteComponentProps, Switch, Route, Redirect,
} from 'react-router-dom';

import HomeScreen from '../screens/HomeScreen';
import AboutScreen from '../screens/AboutScreen';
import ValidateAccountScreen from '../screens/ValidateAccountScreen';
import ProfileScreen from '../screens/ProfileScreen';
import AthleteScreen from '../screens/AthleteScreen';
import AuthenticatedRoute from '../containers/AuthenticatedRoute';
import TestScreen from '../screens/TestScreen';
import {
    ABOUT_ROUTE,
    ATHLETE_ROUTE,
    FAN_ROUTE,
    INDEX_ROUTE,
    NOT_FOUND_ROUTE,
    PROFILE_ROUTE,
    FAN_PROFILE_ROUTE,
    SPONSOR_PROFILE_ROUTE,
    VALIDATE_ACCOUNT_ROUTE,
    TEST_ROUTE,
    SPONSOR_ROUTE,
    RESET_PASSWORD_ROUTE,
    REDE_SPORTRACK,
    ADMIN_USERS_ROUTE,
    INVITE_ROUTE,
    MANAGER_PROFILE_ROUTE,
    MANAGER_ROUTE,
    ATHLETE_GALLERY_ROUTE,
    FAN_GALLERY_ROUTE,
    SPONSOR_GALLERY_ROUTE,
    MANAGER_GALLERY_ROUTE,
    PRODUCT_ROUTE,
    CREDIT_ROUTE, PROFILE_SUPPORT_ROUTE,
    CC_SUCCESS_ROUTE,
    CC_FAIL_ROUTE,
} from '../../constants/routes';
import { MatchParams } from '../../constants/misc';
import FanScreen from '../screens/fans/FanScreen';
import FanProfileScreen from '../screens/fans/FanProfileScreen';
import SponsorScreen from '../screens/sponsors/SponsorScreen';
import SponsorProfileScreen from '../screens/sponsors/SponsorProfileScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import RedeSportrack from '../screens/RedeSportrack';
import UsersScreen from '../screens/UsersScreen';
import ManagerProfileScreen from '../screens/managers/ManagerProfileScreen';
import ManagerScreen from '../screens/managers/ManagerScreen';
import { UserRoles } from '../../constants/user';
import MarketScreen from '../screens/MarketScreen';
import ProductScreen from '../screens/ProductScreen';

/**
 * @typedef {Object} OwnProps
 * @extends {RouteComponentProps<MatchParams>}
 */
type OwnProps = RouteComponentProps<MatchParams>;

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * shows the appropriate content area
 * @param {Props} props
 * @returns {FunctionComponent<Props>}
 */
const ContentArea: FunctionComponent<Props> = () => {
    return (
        <div className="content-area" data-testid="content-area">
            <Switch>
                <Route exact path={INDEX_ROUTE} component={HomeScreen} />
                <Route exact path={INVITE_ROUTE} component={HomeScreen} />
                <Route exact path={ABOUT_ROUTE} component={AboutScreen} />
                <Route exact path={REDE_SPORTRACK} component={RedeSportrack} />
                <Route exact path={VALIDATE_ACCOUNT_ROUTE} component={ValidateAccountScreen} />
                <Route exact path={`${PRODUCT_ROUTE}/:id`} component={ProductScreen} />
                <Route
                    path={CREDIT_ROUTE}
                    component={MarketScreen}
                />
                <Route
                    path={[`${ATHLETE_GALLERY_ROUTE}/:mediaId`, `${ATHLETE_ROUTE}/:id`]}
                    component={AthleteScreen}
                />
                <Route
                    path={[`${FAN_GALLERY_ROUTE}/:mediaId`, `${FAN_ROUTE}/:id`]}
                    component={FanScreen}
                />
                <Route
                    path={[`${SPONSOR_GALLERY_ROUTE}/:mediaId`, `${SPONSOR_ROUTE}/:id?`]}
                    component={SponsorScreen}
                />
                <Route
                    path={[`${MANAGER_GALLERY_ROUTE}/:mediaId`, `${MANAGER_ROUTE}/:id?`]}
                    component={ManagerScreen}
                />
                <Route exact path={TEST_ROUTE} component={TestScreen} />
                <Route exact path={RESET_PASSWORD_ROUTE} component={ResetPasswordScreen} />
                <AuthenticatedRoute exact path={PROFILE_ROUTE} component={ProfileScreen} />
                <AuthenticatedRoute exact path={PROFILE_SUPPORT_ROUTE} component={ProfileScreen} />
                <AuthenticatedRoute exact path={FAN_PROFILE_ROUTE} component={FanProfileScreen} />
                <AuthenticatedRoute exact path={SPONSOR_PROFILE_ROUTE} component={SponsorProfileScreen} />
                <AuthenticatedRoute exact path={MANAGER_PROFILE_ROUTE} component={ManagerProfileScreen} />
                <AuthenticatedRoute exact path={CC_SUCCESS_ROUTE} component={ProfileScreen} />
                <AuthenticatedRoute exact path={CC_FAIL_ROUTE} component={ProfileScreen} />
                <AuthenticatedRoute rolesAllowed={[UserRoles.Admin]} exact path={`${PROFILE_ROUTE}/:id`} component={ProfileScreen} />
                <AuthenticatedRoute rolesAllowed={[UserRoles.Admin]} exact path={`${FAN_PROFILE_ROUTE}/:id`} component={FanProfileScreen} />
                <AuthenticatedRoute rolesAllowed={[UserRoles.Admin]} exact path={`${SPONSOR_PROFILE_ROUTE}/:id`} component={SponsorProfileScreen} />
                <AuthenticatedRoute rolesAllowed={[UserRoles.Admin]} exact path={`${MANAGER_PROFILE_ROUTE}/:id`} component={ManagerProfileScreen} />
                <AuthenticatedRoute rolesAllowed={[UserRoles.Admin]} exact path={ADMIN_USERS_ROUTE} component={UsersScreen} />

                <Redirect to={NOT_FOUND_ROUTE} />
            </Switch>
        </div>
    );
};

export default ContentArea;
