/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

interface OwnProps {
    size?: string;
}

type Props = OwnProps;

export const IconFailCircleFilled: FunctionComponent<Props> = (props: Props) => {
    const { size } = props;
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size || '13.362'} height={size || '13.362'} viewBox="0 0 45 45">
            <path d="M0,0H48V48H0Z" fill="none" />
            <path d="M22,42A20,20,0,1,1,42,22,20,20,0,0,1,22,42ZM20,28v4h4V28Zm0-16V24h4V12Z" transform="translate(2 2)" fill="#bc6c6c" />
        </svg>
    );
};
