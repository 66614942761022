/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import IconTimes from '../assets/IconTimes';
import logo from '../../assets/images/logo_white.svg';
import { INDEX_ROUTE } from '../../constants/routes';
import { MatchParams } from '../../constants/misc';

/**
 * @typedef {Object} OwnProps
 * @extends {RouteComponentProps<MatchParams>, TranslationContext}
 */
interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext {}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 */
interface OwnState {}

/**
 * @typedef {Object} State
 */
type State = OwnState;

const initialState: State = {};

/**
 * shows the not found screen
 * @extends {Component<Props, State>}
 */
class NotFoundScreen extends Component<Props, State> {
    state = initialState;

    /**
     * handles close click
     * @param {React.MouseEvent} e
     */
    onCloseClick = (e: React.MouseEvent) => {
        const { history } = this.props;

        history.push(INDEX_ROUTE);
    };

    render() {
        const { t } = this.props;
        return (
            <div className="not-found-screen" data-testid="not-found-screen">
                <button type="button" onClick={this.onCloseClick}>
                    <IconTimes fill="#FFF" />
                </button>
                <div>
                    <img src={logo} alt="" />
                    <h1>{t('notFound.oops')}</h1>
                    <p>{t('notFound.page')}</p>
                    <button type="button" className="btn btn--primary-inverse" onClick={this.onCloseClick}>
                        {t('notFound.back')}
                    </button>
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslationContext(NotFoundScreen));
