/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';

import { withTranslationContext, TranslationContext } from '../controllers/translation/TranslationContext';
import { SponsorProduct } from '../../constants/sponsor';
import { withSponsorContext, SponsorContext } from '../controllers/sponsor/SponsorContext';
import { Sponsor } from '../../constants/user';
import { displayError } from '../../utils/notifications';
import ButtonShare, { StyleVariant } from './ButtonShare';
import closeIcon from '../../assets/images/icon_close.svg';
import { IconPlaneDelivery } from '../assets/IconPlaneDelivery';
import { IconCheckCircleFilled } from '../assets/IconCheckCircleFilled';
import { PRODUCT_ROUTE } from '../../constants/routes';

interface OwnProps {
    product: SponsorProduct | null;
    sponsor: Sponsor;
    onClose: () => void;
}

type Props = OwnProps & TranslationContext & SponsorContext;

const BuyProductModal: React.FC<Props> = (props: Props) => {
    const {
        product, t, sponsor, onClose,
    } = props;
    
    return product && (
        <>
            <div className="modal modal--buy-product" data-testid="buy-product-modal">
                <div className="modal__backdrop" />
                <div className="modal__content product-modal--buy">
                    <div className="product-modal--buy__actions">
                        <button
                            type="button"
                            className="close"
                            onClick={onClose}
                        >
                            <img
                                src={closeIcon}
                                alt=""
                                className="close"
                                data-testid="close-modal"
                            />
                        </button>
                    </div>
                    <img src={product.imagePath.main} alt={product.title || ''} className="product-modal--buy__display-image" />
                    <div className="product-modal--buy__product-info">
                        <div className="product-modal__display__title">
                            <h3>{product.title}</h3>
                            <span>
                                <img src={sponsor.avatar?.main} alt={sponsor.name} />
                                <p>{sponsor.name}</p>
                            </span>
                        </div>
                        <div className="product-modal--buy__buttons">
                            <ButtonShare linkURL={`${PRODUCT_ROUTE}/${product.id}`} variant={StyleVariant.INVERTED} />
                            <button className="btn btn--primary-inverse" type="button" onClick={(): void => displayError({ message: t('sponsors.getInTouch', { sponsor: sponsor.name }) })}>
                                {t('sponsors.product.price1')}&nbsp;<strong>{t('sponsors.product.price2', { price: product.credits })}</strong>
                            </button>
                        </div>
                        <div className="product-modal--buy__product-details content-box">
                            <h3 className="title">
                                {t('productModal.details')}
                            </h3>
                            <p>
                                {product.description}
                            </p>
                        </div>
                        <div className="product-modal--buy__delivery-price-section">
                            <div className="product-modal--buy__delivery-price-section__content">
                                <IconPlaneDelivery />
                                <h3>
                                    {t('productModal.buy.deliverySection.includesDelivery')}
                                </h3>
                            </div>
                            <hr />
                            <div className="product-modal--buy__delivery-price-section__content">
                                <IconCheckCircleFilled />
                                <span className="product-modal--buy__delivery-price-section__info">
                                    {t('productModal.buy.deliverySection.moreDetails')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withSponsorContext(withTranslationContext(BuyProductModal));
