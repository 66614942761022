/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedAuthenticationController } from './AuthenticationController';
import {
    AccountValidationFormFields,
    ResendActivationFormFields,
    ResetPasswordPostData,
} from '../../../constants/authentication';
import { User, UserTypes } from '../../../constants/user';

/**
 * @typedef {Object} AuthenticationContext
 * @property {boolean} isAuthenticated
 * @property {boolean} isFetching
 * @property {User | null} user
 * @property {string | null} token
 * @property {Function} openRegistration
 * @property {Function} openRegistrationInvite
 * @property {Function} openLogin
 * @property {Function} accountValidate
 * @property {Function} resendActivation
 * @property {Function} openTestInfo
 */
export interface AuthenticationContext {
    isAuthenticated: boolean;
    isFetching: boolean;
    user: User | null;
    token: string | null;
    openRegistration(userType?: UserTypes | null): void;
    openRegistrationInvite(): void;
    openLogin(): void;
    accountValidate(fields: AccountValidationFormFields, onSuccess: Function, onFailure: Function): void;
    resendActivation(fields: ResendActivationFormFields, onSuccess: Function, onFailure: Function): void;
    openTestInfo(finishCallback?: (() => void)): void;
    cleanStore(onSuccess: Function): void;
    resetPassword(fields: ResetPasswordPostData, onSuccess: Function, onFailure: Function): void;
    getSocketToken(): Promise<string | null>;
}

// Create the context

export const authenticationContextDefaultValue: AuthenticationContext = {
    isAuthenticated: false,
    isFetching: false,
    user: null,
    token: null,
    openRegistration: () => {},
    openRegistrationInvite: () => {},
    openLogin: () => {},
    accountValidate: () => {},
    resendActivation: () => {},
    openTestInfo: () => {},
    cleanStore: () => {},
    resetPassword: () => {},
    getSocketToken: async () => null,
};

const AuthenticationContext = createContext<AuthenticationContext | null>(authenticationContextDefaultValue);

// Export Provider and Consumer

export const AuthenticationContextProvider = AuthenticationContext.Provider;
export const AuthenticationContextConsumer = AuthenticationContext.Consumer;

/**
 * provides a context HOC that components can use to subscribe as consumers
 * @param Component
 * @returns {React.FC}
 */
export const withAuthenticationContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof AuthenticationContext>> => (props) => (
    <ConnectedAuthenticationController>
        <AuthenticationContextConsumer>
            {(ctx) => <Component {...(props as P)} {...ctx} />}
        </AuthenticationContextConsumer>
    </ConnectedAuthenticationController>
    );
