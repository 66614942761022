/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { isEqual } from 'lodash';
import { matchPath, RouteComponentProps } from 'react-router-dom';
import axios from 'axios';
import { ReactSVG } from 'react-svg';
import logo from '../../assets/images/horizontal_logo.svg';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { UserContext, withUserContext } from '../controllers/user/UserContext';
import AthletePendingSupportModal from '../elements/AthletePendingSupportModal';
import DrawerGallery from '../elements/DrawerGallery';
import ButtonContact from '../elements/ButtonContact';
import ButtonShare from '../elements/ButtonShare';
import Avatar from '../elements/Avatar';
import CoverImage from '../elements/CoverImage';
import iconPin from '../../assets/images/icon_pin.svg';
import iconShieldCheck from '../../assets/images/icon_shield_check.svg';
import iconWeight from '../../assets/images/icon_weight.svg';
import iconHeight from '../../assets/images/icon_height.svg';
import iconBirthday from '../../assets/images/icon_birthday.svg';
import FormField from '../elements/FormField';
import Dropdown from '../elements/Dropdown';
import { userURL } from '../../services/users';
import {
    Club, ProfileFormFields, User, UserRoles,
} from '../../constants/user';
import {
    KeyedObject, MatchParams, DropdownOption, ADD_NEW_CLUB, INSTRUCTION,
} from '../../constants/misc';
import { sportsURL } from '../../services/utils';
import { enumToOptions, getOptionByValue } from '../../utils/dropdown';
import Datepicker from '../elements/Datepicker';
import {
    ATHLETE_ROUTE, INDEX_ROUTE, PROFILE_ROUTE, PROFILE_SUPPORT_ROUTE, CC_SUCCESS_ROUTE, CC_FAIL_ROUTE, FAN_PROFILE_ROUTE, SPONSOR_PROFILE_ROUTE,
} from '../../constants/routes';
import { displayError, displaySuccess } from '../../utils/notifications';
import { ErrorCode } from '../../constants/errors';
import loader from '../../assets/images/loader.svg';
import { SportestContext, withSportestContext } from '../controllers/sportest/SportestContext';
import { Timeline } from '../elements/Timeline';
import NewAchievementForm from '../elements/NewAchievementForm';
import { Achievement } from '../../constants/achievements';
import { Sportest } from '../../constants/sportest';
import { sortAchievementsByDate } from '../../utils/misc';
import { SportTestBadge } from '../elements/SportTestBadge';
import IconTimes from '../assets/IconTimes';
import { validateForm, ValidationType } from '../../utils/validations';
import UserWallet from '../elements/UserWallet';
import { IconHome } from '../assets/IconHome';
import { IconGlobe } from '../assets/IconGlobe';
import SelectCountry from '../elements/SelectCountry';
import { IconBank } from '../assets/IconBank';
import SponsorshipManagerTable from '../elements/SponsorshipManagerTable';
import { SponsorContext, withSponsorContext } from '../controllers/sponsor/SponsorContext';
import { SponsorAthletesSupport } from '../../constants/sponsor';
import { WS } from '../../utils/websocket';
import { SocketEventType } from '../../types/websockets';
import { TableActionType } from '../../utils/table';
import { IconCheckCircleFilled } from '../assets/IconCheckCircleFilled';
import { IconFailCircleFilled } from '../assets/IconFailCircleFilled';
import RouteLeavingGuard from './RouteLeavingGuard';

/**
 * @typedef {Object} OwnProps
 * @extends {RouteComponentProps<MatchParams>, AuthenticationContext, TranslationContext, UserContext}
 */
interface OwnProps
    extends RouteComponentProps<MatchParams>,
    AuthenticationContext,
    TranslationContext,
    UserContext,
    SponsorContext,
    SportestContext { }

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 * @property {File} [cover]
 * @property {File} [avatar]
 * @property {boolean} showGallery
 * @property {User} [userData]
 * @property {ProfileFormFields} fields
 * @property {KeyedObject} [errors]
 * @property {DropdownOption[]} [sportsOptions]
 * @property {DropdownOption[]} [clubsOptions]
 * @property {boolean} [isFetching]
 * @property {boolean} [isFetchingAvatar]
 * @property {Media[]} [photos]
 * @property {Media[]} [videos]
 * @property {boolean} showNewAchievementForm
 * @property {string} [newClub]
 * @property {KeyedObject} [newClubErrors]
 */
interface OwnState {
    changesMade: boolean;
    cover: any;
    avatar: any;
    showGallery: boolean;
    userData: User | null;
    fields: ProfileFormFields;
    errors: KeyedObject | null;
    sportsOptions: DropdownOption[];
    clubsOptions: DropdownOption[];
    clubSearchValue: string;
    isFetching: boolean;
    isFetchingAvatar: boolean;
    showAddClubModal: boolean;
    showNewAchievementForm: boolean;
    achievements: Achievement[];
    sportest: Sportest | null;
    newClub: string;
    newClubErrors: KeyedObject | null;
    athletesSupportList: SponsorAthletesSupport[];
    totalAthletesSupport: number;
    paramsAthletesSupport: Record<string, number>;
    supportsAthleteList: SponsorAthletesSupport[];
    totalSupportsAthlete: number;
    paramsSupportsAthlete: Record<string, number>;
    isFetchingGallery: boolean;
    pendingAthleteSupporter: SponsorAthletesSupport | null;
    isSocketConnecting: boolean;
    ccPaymentSucess: boolean;
    ccPaymentError: boolean;
}

/**
 * @typedef {Object} State
 */
type State = OwnState;

const initialState: State = {
    changesMade: false,
    cover: null,
    avatar: null,
    showGallery: false,
    userData: null,
    fields: {
        name: '',
        lastName: '',
        sport: null,
        countryCode: null,
        postalCode: '',
        fiscalCode: '',
        address: '',
        club: null,
        weight: '',
        height: '',
        dateOfBirth: null,
        phrase: '',
        biography: '',
        objectives: '',
        avatarId: null,
        coverId: null,
    },
    errors: null,
    sportsOptions: [],
    clubsOptions: [],
    clubSearchValue: '',
    isFetching: false,
    isFetchingAvatar: false,
    showAddClubModal: false,
    showNewAchievementForm: false,
    achievements: [],
    sportest: null,
    newClub: '',
    newClubErrors: null,
    athletesSupportList: [],
    totalAthletesSupport: 0,
    paramsAthletesSupport: {
        _limit: 10,
        _page: 0,
    },
    supportsAthleteList: [],
    totalSupportsAthlete: 0,
    paramsSupportsAthlete: {
        _limit: 10,
        _page: 0,
    },
    isFetchingGallery: false,
    pendingAthleteSupporter: null,
    isSocketConnecting: false,
    ccPaymentSucess: false,
    ccPaymentError: false,
};

/**
 * shows the profile screen
 * @extends {Component<Props, State>}
 */
class ProfileScreen extends Component<Props, State> {
    state = initialState;

    private readonly avatarRef = React.createRef<HTMLInputElement>();

    private readonly coverRef = React.createRef<HTMLInputElement>();

    ADD_NEW_CLUB = 'ADD_NEW_CLUB_BUTTON';

    INSTRUCTION = 'INSTRUCTION';

    componentDidMount() {
        const { submitPendingSportests, isAuthenticated, history } = this.props;
        submitPendingSportests();
        this.prepare();

        if (isAuthenticated) {
            WS.addHandler(SocketEventType.SUPPORTERS_UPDATE, this.handleSupportUpdate);
        }
        if (isAuthenticated && matchPath(history.location.pathname, {
            path: CC_SUCCESS_ROUTE,
            exact: true,
        })) {
            this.setState({
                ccPaymentSucess: true,
            });
        }
        if (isAuthenticated && matchPath(history.location.pathname, {
            path: CC_FAIL_ROUTE,
            exact: true,
        })) {
            this.setState({
                ccPaymentError: true,
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        const { user, pendingAthleteSupporters, isAuthenticated } = this.props;
        const { user: prevUser, pendingAthleteSupporters: prevPendingAthleteSupporters, isAuthenticated: prevIsAuthenticated } = prevProps;

        const { fields } = this.state;
        const { fields: prevFields } = prevState;

        if (user?.availableCredits !== prevUser?.availableCredits || pendingAthleteSupporters !== prevPendingAthleteSupporters) {
            this.fetchAllSupportData();
        }

        if (isAuthenticated && !prevIsAuthenticated) {
            WS.addHandler(SocketEventType.SUPPORTERS_UPDATE, this.handleSupportUpdate);
        }

        if (isEqual(prevFields, initialState.fields)
            || isEqual(fields, initialState.fields)
            || isEqual(prevFields, fields)
        ) return;
        
        this.setState({ changesMade: true });
    }

    componentWillUnmount() {
        WS.removeHandler(SocketEventType.SUPPORTERS_UPDATE, this.handleSupportUpdate);
    }

    /**
     * handlers
     */

    onToggleGallery = () => {
        this.setState((prevState) => ({
            showGallery: !prevState.showGallery,
        }));
    };

    onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;

        this.setState((prevState) => ({
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };

    onSubmit = (e: React.MouseEvent) => {
        e.preventDefault();

        const {
            validateProfileUpdate, profileUpdate, user, match: { params: { id } },
        } = this.props;
        const { fields } = this.state;

        const errors = validateProfileUpdate(fields);

        this.setState(
            { errors },
            () => {
                if (!errors) {
                    profileUpdate(fields, this.onSuccess, this.onFailure, user?.role === UserRoles.Admin ? id : undefined);
                }
            },
        );
    };

    onSuccess = () => {
        const { t, user } = this.props;
        this.setState(
            {
                changesMade: false,
                fields: initialState.fields,
                errors: null,
            },
            () => {
                this.prepare();
                if (user?.role === UserRoles.Admin) {
                    displaySuccess({
                        message: t('admin.users.updated'),
                    });
                    return;
                }
                displaySuccess({
                    message: t('profile.updated'),
                });
            },
        );
    };

    onFailure = (errors: KeyedObject) => {
        const { t } = this.props;

        if (errors) {
            if (errors.fields) {
                this.setState({
                    errors: errors.fields,
                });
            }

            if (errors.errors) {
                errors.errors.forEach((error: any) => {
                    displayError({
                        message: t(`errors.${ErrorCode[error.errorCode]}`),
                    });
                });
            }
        }
    };

    onCoverFileDialog = () => {
        if (this.coverRef.current) {
            this.coverRef.current.click();
        }
    };

    onCoverSelected = (files: FileList | null) => {
        const { t } = this.props;
        if (!files) {
            return;
        }
        const { coverUpdate } = this.props;

        const reader = new FileReader();
        const file = files[0];

        reader.onloadend = () => {
            this.setState(
                {
                    cover: reader.result,
                    isFetching: true,
                },
                () => {
                    coverUpdate(
                        file,
                        (response: any) => {
                            this.setState((prevState) => ({
                                fields: {
                                    ...prevState.fields,
                                    coverId: response.data.id,
                                },
                                isFetching: false,
                            }));
                        },
                        () => {
                            this.setState({
                                isFetching: false,
                            });
                            displayError({
                                message: t('profile.uploadError'),
                            });
                        },
                    );
                },
            );
        };

        reader.readAsDataURL(file);
    };

    onAvatarFileDialog = () => {
        if (this.avatarRef.current) {
            this.avatarRef.current.click();
        }
    };

    onAvatarSelected = (files: FileList | null) => {
        const { t } = this.props;
        if (!files) {
            return;
        }
        const { avatarUpdate } = this.props;

        const reader = new FileReader();
        const file = files[0];

        reader.onloadend = () => {
            this.setState(
                {
                    avatar: reader.result,
                    isFetchingAvatar: true,
                },
                () => {
                    avatarUpdate(
                        file,
                        (response: any) => {
                            this.setState((prevState) => ({
                                fields: {
                                    ...prevState.fields,
                                    avatarId: response.data.id,
                                },
                                isFetchingAvatar: false,
                            }));
                        },
                        () => {
                            this.setState({
                                isFetchingAvatar: false,
                            });
                            displayError({
                                message: t('profile.uploadError'),
                            });
                        },
                    );
                },
            );
        };

        reader.readAsDataURL(file);
    };

    onDropDownChange = (option: DropdownOption, name: string): void => {
        const { fields, clubSearchValue } = this.state;

        if (name === 'club' && option.value === ADD_NEW_CLUB) {
            this.setState({
                showAddClubModal: true,
                newClub: clubSearchValue,
            });
            return;
        }
        if (name === 'club' && option.value === INSTRUCTION) {
            this.setState({
                newClub: clubSearchValue,
            });
            return;
        }

        this.setState({
            fields: {
                ...fields,
                [name]: option,
            },
        });
    };

    onClubOptionFilter = (option: DropdownOption, rawValue: string) => {
        if (option.value === ADD_NEW_CLUB) return true;

        return option.value.toLowerCase().includes(rawValue.toLowerCase());
    }

    onAddNewClubInputChange = (event: React.FormEvent<HTMLFormElement>): void => {
        const { value } = event.currentTarget;

        this.setState({
            newClub: value,
        });
    };

    onSaveNewClub = (): void => {
        const { fields, clubsOptions, newClub } = this.state;
        const newClubOption = { label: newClub, value: newClub };
        const newClubErrors = validateForm({ newClub }, {
            newClub: {
                validations: [ValidationType.NotBlank],
            },
        });

        if (fields.sport?.value && newClubErrors === null) {
            this.setState({
                showAddClubModal: false,
                clubsOptions: [...clubsOptions, newClubOption],
                fields: {
                    ...fields,
                    club: newClubOption,
                },
            });
        } else {
            this.setState({
                newClubErrors,
            });
        }
    };

    onClubDropdownInputChange = (newValue: string): void => {
        const {
            getDropdownClubs, t,
        } = this.props;

        const addNewClub: DropdownOption = {
            value: ADD_NEW_CLUB,
            label: t('profile.addClub'),
        };
        const instruction: DropdownOption = {
            value: INSTRUCTION,
            label: t('profile.typeClubs'),
        };
        
        getDropdownClubs(newValue).then((clubs) => {
            this.setState({
                clubsOptions: [instruction, addNewClub, ...enumToOptions(clubs.list, false, false)],
                clubSearchValue: newValue,
            });
        });
    }

    onPageChangeAthletesSupport = (evt: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        const { paramsAthletesSupport } = this.state;

        this.setState(
            {
                paramsAthletesSupport: { ...paramsAthletesSupport, _page: page },
            },
            this.fetchAthletesSupport,
        );
    };

    onRowsPerPageChangeAthletesSupport = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { paramsAthletesSupport } = this.state;

        this.setState(
            {
                paramsAthletesSupport: { ...paramsAthletesSupport, _limit: Number(evt.target.value) },
            },
            this.fetchAthletesSupport,
        );
    };

    onPageChangeSupportsAthlete = (evt: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        const { paramsSupportsAthlete } = this.state;

        this.setState(
            {
                paramsSupportsAthlete: { ...paramsSupportsAthlete, _page: page },
            },
            this.fetchSupportsAthlete,
        );
    };

    onRowsPerPageChangeSupportsAthlete = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { paramsSupportsAthlete } = this.state;

        this.setState(
            {
                paramsSupportsAthlete: { ...paramsSupportsAthlete, _limit: Number(evt.target.value) },
            },
            this.fetchSupportsAthlete,
        );
    };

    onCloseAthletePendingSupportModal = () => {
        const { history } = this.props;
        this.setState({
            pendingAthleteSupporter: null,
        });

        history.push(PROFILE_ROUTE);
    };

    onCloseConfirmationModal = () => {
        const { history, user } = this.props;

        this.setState({
            ccPaymentSucess: false,
            ccPaymentError: false,
        });
        switch (user?.role) {
            case UserRoles.Fan:
                history.push(FAN_PROFILE_ROUTE);
                break;
            case UserRoles.Sponsor:
                history.push(SPONSOR_PROFILE_ROUTE);
                break;
            case UserRoles.Athlete:
                history.push(PROFILE_ROUTE);
                break;
            default:
                break;
        }
    }

    onAcceptSupport = () => {
        const { getUser, user, updateUserWallet } = this.props;

        this.fetchSupportsAthlete();

        if (!user) return;

        getUser(user.id).then((userData) => {
            this.setState({
                userData,
            });

            updateUserWallet(userData?.availableCredits || 0);
        });
    }

    handleSupportUpdate = ({ payload: athleteSupportEvent }: {payload: Partial<SponsorAthletesSupport>}) => {
        const { athletesSupportList } = this.state;
        
        const supportElement = athletesSupportList.find((el) => el.id === athleteSupportEvent.id);
        
        if (!supportElement) {
            return;
        }

        const auxList = athletesSupportList.map((el) => {
            if (el.id === supportElement.id) return { ...el, ...athleteSupportEvent };

            return el;
        });

        this.setState({
            athletesSupportList: auxList,
        });
    }

    /**
     * requests & actions
     */

    fetchAthletesSupport = () => {
        const { paramsAthletesSupport } = this.state;
        const { getAthletesSupport } = this.props;

        getAthletesSupport({ ...paramsAthletesSupport }).then(({ list, total }) => {
            this.setState({
                athletesSupportList: list,
                totalAthletesSupport: total,
            });
        });
    };

    fetchSupportsAthlete = () => {
        const { paramsSupportsAthlete } = this.state;
        const { getSupportsAthlete } = this.props;

        getSupportsAthlete({ ...paramsSupportsAthlete }).then(({ list, total }) => {
            this.setState({
                supportsAthleteList: list,
                totalSupportsAthlete: total,
                isFetchingGallery: true,
            });
        });
    };

    fetchAllSupportData = () => {
        this.fetchAthletesSupport();
        this.fetchSupportsAthlete();
    };

    requestClubs = () => {
        const { getPaginatedClubs, t } = this.props;

        const addNewClub: DropdownOption = {
            value: ADD_NEW_CLUB,
            label: t('profile.addClub'),
        };

        const instruction: DropdownOption = {
            value: INSTRUCTION,
            label: t('profile.typeClubs'),
        };

        getPaginatedClubs().then((clubs) => {
            this.setState({
                clubsOptions: [instruction, addNewClub, ...enumToOptions(clubs.list, false, false)],

            });
        });
    };

    removeCoverImage = () => {
        const { fields, userData } = this.state;
        if (userData) {
            this.setState({
                cover: undefined,
                userData: {
                    ...userData,
                    coverPhoto: null,
                },
                fields: {
                    ...fields,
                    coverId: 0,
                },
            });
        }
    };

    removeUserImage = () => {
        const { fields, userData } = this.state;
        if (userData) {
            this.setState({
                avatar: undefined,
                userData: {
                    ...userData,
                    avatar: null,
                },
                fields: {
                    ...fields,
                    avatarId: 0,
                },
            });
        }
    };

    prepare = async () => {
        const {
            history, getSportests, match, user, isAuthenticated, getAthleteSupport,
        } = this.props;
        const { fields } = this.state;

        if (!user) return null;

        const userId = match?.params?.id || user.id || '';
        const supportId = isAuthenticated && matchPath(history.location.pathname, {
            path: PROFILE_SUPPORT_ROUTE,
            exact: true,
        }) && match?.params?.id ? match?.params?.id : '';

        const promises: Array<Promise<any>> = [axios.get(userURL(userId)), axios.get(sportsURL()), getSportests(userId)];

        if (supportId !== '') promises.push(getAthleteSupport(Number(supportId)));

        let res = [];
        try {
            res = await Promise.all(promises);
        } catch (error) {
            history.push(INDEX_ROUTE);
            return;
        }

        const [userResponse, sports, sportests, athleteSupporter] = res;
        const userSport = userResponse.data.club as Club;
        const { achievements } = userResponse.data;
        const sportsOptions = enumToOptions(sports.data, true);
        const selectedSport = userSport ? getOptionByValue(userSport.clubCompositeKey.sport, sportsOptions) : null;

        this.setState({
            userData: userResponse.data,
            fields: {
                ...fields,
                name: userResponse.data.name || initialState.fields.name,
                lastName: userResponse.data.lastName || initialState.fields.lastName,
                sport: selectedSport || initialState.fields.sport,
                countryCode: userResponse.data.countryCode || initialState.fields.countryCode,
                postalCode: userResponse.data.postalCode || initialState.fields.postalCode,
                fiscalCode: userResponse.data.fiscalCode || initialState.fields.fiscalCode,
                address: userResponse.data.address || initialState.fields.address,
                club: userSport ? {
                    label: userSport.clubCompositeKey.clubName,
                    value: userSport.clubCompositeKey.clubName,
                } : initialState.fields.club,
                weight: userResponse.data.weight || initialState.fields.weight,
                height: userResponse.data.height || initialState.fields.height,
                dateOfBirth: userResponse.data.dateOfBirth ? new Date(userResponse.data.dateOfBirth) : new Date(),
                phrase: userResponse.data.phrase || initialState.fields.phrase,
                biography: userResponse.data.biography || initialState.fields.biography,
                objectives: userResponse.data.objectives || initialState.fields.objectives,
                avatarId: userResponse.data.avatar?.id || initialState.fields.avatarId,
                coverId: userResponse.data.coverPhoto?.id || initialState.fields.coverId,
            },
            sportsOptions,
            sportest: sportests.pop() || null,
            achievements: achievements ? sortAchievementsByDate(achievements) : initialState.achievements,
            pendingAthleteSupporter: athleteSupporter || null,
        }, () => {
            this.requestClubs();
        });

        if (user.id === userResponse.data.id) this.fetchAllSupportData();
    };

    deleteAchievement = (id: number): void => {
        const { deleteAchievement } = this.props;
        deleteAchievement(id, () => { }, this.popAchievement);
    };

    popAchievement = (id: number): void => {
        const { achievements } = this.state;
        this.setState({ achievements: [...achievements].filter((achievement) => achievement.id !== id) });
    };

    /**
     * renders
     */

    renderInputsFiles = () => {
        return (
            <React.Fragment>
                <input
                    id="cover-input"
                    type="file"
                    ref={this.coverRef}
                    data-testid="profile-cover-input"
                    onChange={(e) => this.onCoverSelected(e.target.files)}
                />
                <input
                    id="avatar-input"
                    type="file"
                    ref={this.avatarRef}
                    data-testid="profile-avatar-input"
                    onChange={(e) => this.onAvatarSelected(e.target.files)}
                />
            </React.Fragment>
        );
    };

    renderHeaderContent(): React.ReactNode {
        const { t, user } = this.props;

        const {
            cover,
            avatar,
            userData,
            isFetchingAvatar,
        } = this.state;

        if (!user) return null;

        const itsMe = user.id === userData?.id;
        const currentCover = userData?.coverPhoto ? userData.coverPhoto.main : null;
        const currentAvatar = userData?.avatar ? userData.avatar.main : null;
        const linkURL = `${ATHLETE_ROUTE}/${user.id}`;

        return (
            <React.Fragment>
                <CoverImage
                    image={cover || currentCover}
                    onEdit={this.onCoverFileDialog}
                    onDelete={this.removeCoverImage}
                    tooltip={t('profile.coverTooltip')}
                />
                <div className="buttons">
                    {itsMe && <UserWallet userPageOwner={user} />}
                    <ButtonContact userPageOwner={userData} />
                    <ButtonShare linkURL={linkURL} />
                </div>
                {isFetchingAvatar && (
                    <div className="fetching fetching--avatar">
                        <ReactSVG wrapper="span" src={loader} />
                    </div>
                )}
                <Avatar
                    image={avatar || currentAvatar}
                    editable
                    onEdit={this.onAvatarFileDialog}
                    onDelete={this.removeUserImage}
                />
                <button
                    type="button"
                    className="btn btn--primary-dark gallery-btn gallery-btn"
                    onClick={this.onToggleGallery}
                >
                    {t('profile.galery')}
                </button>
            </React.Fragment>
        );
    }

    renderAthleteInfo(): React.ReactNode {
        const { t, user } = this.props;

        const {
            errors,
            fields,
            sportsOptions,
            isFetching,
            clubsOptions,
        } = this.state;

        if (!user) return null;

        return (
            <div className="row athlete-info">
                <div className="col-xl-3 col-sm-6 col-xs-12">
                    <FormField
                        label={t('profile.name')}
                        type="text"
                        name="name"
                        value={fields.name}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className="col-xl-3 col-sm-6 col-xs-12">
                    <FormField
                        label={t('profile.surname')}
                        type="text"
                        name="lastName"
                        value={fields.lastName}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className="col-xl-3 col-sm-6 col-xs-12">
                    <Dropdown
                        label={t('profile.sport')}
                        name="sport"
                        options={sportsOptions}
                        value={fields.sport}
                        onChange={this.onDropDownChange}
                        errors={errors}
                    />
                </div>
                <div className="col-xl-3 col-sm-6 col-xs-12 with-icon">
                    <div className="icn">
                        <img src={iconShieldCheck} alt="club" />
                    </div>
                    <Dropdown
                        label={t('profile.club')}
                        name="club"
                        options={clubsOptions}
                        value={fields.club}
                        disabled={!fields.sport}
                        onChange={this.onDropDownChange}
                        customFilter={this.onClubOptionFilter}
                        onInputChange={this.onClubDropdownInputChange}
                    />
                </div>
            </div>
        );
    }

    renderAthleteStats(): React.ReactNode {
        const { isFetching, t } = this.props;
        const { fields, errors } = this.state;

        const colClass = 'col-xl-3 col-lg-4 col-sm-6 col-xs-12 with-icon';

        return (
            <div className="row athlete-stats">
                <div className={colClass}>
                    <div className="icn">
                        <img src={iconPin} alt="" />
                    </div>
                    <FormField
                        label={t('profile.address')}
                        type="text"
                        name="address"
                        value={fields.address}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn">
                        <IconHome />
                    </div>
                    <FormField
                        label={t('profile.postalCode')}
                        type="text"
                        name="postalCode"
                        value={fields.postalCode}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn">
                        <IconGlobe />
                    </div>
                    <SelectCountry
                        name="countryCode"
                        label={t('profile.country')}
                        value={fields.countryCode}
                        onChange={this.onDropDownChange}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn icn--phone">
                        <IconBank />
                    </div>
                    <FormField
                        label={t('profile.fiscalCode')}
                        type="text"
                        name="fiscalCode"
                        value={fields.fiscalCode}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn">
                        <img src={iconWeight} alt="" />
                    </div>
                    <FormField
                        label={t('profile.weight')}
                        type="text"
                        name="weight"
                        value={fields.weight}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn">
                        <img src={iconHeight} alt="" />
                    </div>
                    <FormField
                        label={t('profile.height')}
                        type="text"
                        name="height"
                        value={fields.height}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn">
                        <img src={iconBirthday} alt="" />
                    </div>
                    <Datepicker
                        label={t('profile.date')}
                        name="dateOfBirth"
                        value={fields.dateOfBirth}
                        errors={errors}
                        onChange={(date: any) => {
                            this.setState({
                                fields: {
                                    ...fields,
                                    dateOfBirth: date,
                                },
                            });
                        }}
                    />
                </div>
            </div>
        );
    }

    renderProfileInfo = (): React.ReactNode => {
        const { t, user } = this.props;
        const {
            fields, isFetching, errors, achievements, paramsAthletesSupport, totalAthletesSupport, athletesSupportList,
            supportsAthleteList, totalSupportsAthlete, paramsSupportsAthlete, userData,
        } = this.state;

        const itsMe = user?.id === userData?.id;

        return (
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="content-box">
                        <h3 className="title">{t('profile.aboutMe')}</h3>
                        <FormField
                            label={t('profile.definition')}
                            type="text"
                            name="phrase"
                            value={fields.phrase}
                            onChange={this.onInputChange}
                            placeholder=""
                            disabled={isFetching}
                            multiline
                            errors={errors}
                        />
                        <FormField
                            label={t('profile.about')}
                            type="text"
                            name="biography"
                            value={fields.biography}
                            onChange={this.onInputChange}
                            placeholder=""
                            disabled={isFetching}
                            multiline
                            errors={errors}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="content-box">
                        <h3 className="title">{t('profile.myGoals')}</h3>
                        <FormField
                            label={t('profile.goals')}
                            type="text"
                            name="objectives"
                            value={fields.objectives}
                            onChange={this.onInputChange}
                            placeholder=""
                            disabled={isFetching}
                            multiline
                            errors={errors}
                        />
                    </div>
                    {itsMe && (
                        <div className="content-box">
                            <h3 className="title">{t('profile.sponsors')}</h3>
                            <ul className="profile-sponsors" />
                        </div>
                    )}
                </div>
                <div className="col-lg-12">
                    <h3 className="title">{t('profile.course')}</h3>
                    <Timeline
                        achievements={achievements}
                        onAdd={itsMe ? (): void => this.setState({ showNewAchievementForm: true }) : undefined}
                        onDelete={itsMe ? this.deleteAchievement : undefined}
                    />
                </div>
                <div className="col-lg-12">
                    <h3 className="title">{t('profile.clubs')}</h3>
                    {this.renderClubs()}
                </div>
                {(itsMe && athletesSupportList.length > 0) && (
                    <div className="col-lg-12 col-md-12">
                        <div className="content-box">
                            <h3 className="title">{t('profile.sponsorshipManager.mySupportedAthletes')}</h3>
                            <SponsorshipManagerTable
                                totalAthletesSupport={totalAthletesSupport}
                                athletesSupportList={athletesSupportList}
                                onPageChange={this.onPageChangeAthletesSupport}
                                onRowsPerPageChange={this.onRowsPerPageChangeAthletesSupport}
                                params={paramsAthletesSupport}
                                fetchData={this.fetchAthletesSupport}
                                actionButtons={[TableActionType.CANCEL]}
                            />
                        </div>
                    </div>
                )}
                {(itsMe && supportsAthleteList.length > 0) && (
                    <div className="col-lg-12 col-md-12">
                        <div className="content-box">
                            <h3 className="title">{t('profile.sponsorshipManager.mySupporters')}</h3>
                            <SponsorshipManagerTable
                                totalAthletesSupport={totalSupportsAthlete}
                                athletesSupportList={supportsAthleteList}
                                onPageChange={this.onPageChangeSupportsAthlete}
                                onRowsPerPageChange={this.onRowsPerPageChangeSupportsAthlete}
                                params={paramsSupportsAthlete}
                                fetchData={this.onAcceptSupport}
                                actionButtons={[TableActionType.ACCEPT, TableActionType.REJECT]}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    };

    renderClubs = () => {
        const { achievements } = this.state;

        const clubs = achievements.map((achievement) => (achievement.club as Club).clubCompositeKey.clubName);

        const clubsDistinct = clubs.reduce((newArray, club) => (newArray.includes(club) ? newArray : [...newArray, club]), [] as string[]);
        return clubsDistinct.map((club) => (
            <div key={club} className="profile-info__club">
                <div className="profile-info__club__logo">
                    <img src={iconShieldCheck} alt="club logo" />
                </div>
                <span>{club}</span>
            </div>
        ));
    };

    renderSuccessPaymentModal = () => {
        const { t } = this.props;

        return (
            <div className="modal">
                <div className="modal__backdrop">
                    <div className="modal__confirm">
                        <div className="modal--secondary__header">
                            <img src={logo} alt="logo" />
                            <button type="button" onClick={this.onCloseConfirmationModal}>
                                <IconTimes fill="#278982" />
                            </button>
                        </div>
                        <div className="modal--secondary__success">
                                            
                            <IconCheckCircleFilled />
                            <p>{t('market.ccPaymentSuccessInfo1')}</p>
                            <p>{t('market.ccPaymentSuccessInfo2')}</p>
                        </div>
                        <div className="modal--secondary__actions modal--secondary__success">
                            <button
                                className="btn btn--primary-inverse"
                                type="button"
                                onClick={this.onCloseConfirmationModal}
                            >
                                {t('general.ok')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderErrorPaymentModal = () => {
        const { t } = this.props;

        return (
            <div className="modal">
                <div className="modal__backdrop">
                    <div className="modal__confirm">
                        <div className="modal--secondary__header">
                            <img src={logo} alt="logo" />
                            <button type="button" onClick={this.onCloseConfirmationModal}>
                                <IconTimes fill="#278982" />
                            </button>
                        </div>
                        <div className="modal--secondary__success">
                            <IconFailCircleFilled />
                            <p>{t('market.ccPaymentErrorMessage1')}</p>
                            <p>{t('market.ccPaymentErrorMessage2')}</p>
                        </div>
                        <div className="modal--secondary__actions modal--secondary__success">
                            <button
                                className="btn btn--primary-inverse"
                                type="button"
                                onClick={this.onCloseConfirmationModal}
                            >
                                {t('general.ok')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            t,
            user,
            history,
            openTestInfo,
            getSportests,
        } = this.props;

        const {
            changesMade,
            fields,
            userData,
            showGallery,
            isFetching,
            showNewAchievementForm,
            showAddClubModal,
            achievements,
            sportest,
            newClub,
            newClubErrors,
            isFetchingGallery,
            pendingAthleteSupporter,
            ccPaymentSucess,
            ccPaymentError,
        } = this.state;

        if (!userData) return null;

        const itsMe = user?.id === userData?.id;

        const onSportTestFinished = (): void => {
            if (userData && userData.id) {
                const { id } = userData;
                getSportests(id).then((sportests) => this.setState({ sportest: sportests.pop() || null }));
            }
        };

        return (
            <>
                <RouteLeavingGuard
                    when={changesMade}
                    navigate={(pathName: string) => {
                        history.push(pathName);
                    }}
                    shouldBlockNavigation={() => true}
                    title={t('profile.noChangesPersistedConfirmation')}
                    confirmText={t('general.confirm')}
                    cancelText={t('general.cancel')}
                />
                <div className="profile-screen" data-testid="profile-screen">
                    <div className="profile-screen__body">
                        <div className="profile-screen__body__left with-overlay">
                            <div className="profile-screen__body__left__header">
                                {this.renderInputsFiles()}
                                {isFetching && (
                                    <div className="fetching">
                                        <ReactSVG wrapper="span" src={loader} />
                                    </div>
                                )}
                                {this.renderHeaderContent()}
                            </div>
                            {!showGallery ? (
                                <div className="profile-screen__body__left__content">
                                    <div className="profile-top">
                                        <div className="profile-top__left">
                                            {this.renderAthleteInfo()}
                                            <div className="hidden-xs stats-container">
                                                {this.renderAthleteStats()}
                                            </div>
                                        </div>
                                        <div className="profile-top__right">
                                            <button type="button" onClick={(): void => openTestInfo(onSportTestFinished)}>
                                                <SportTestBadge sportTest={sportest} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="profile-info">
                                        <div className="row hidden-sm hidden-md hidden-lg">
                                            <div className="col-sm-12">{this.renderAthleteStats()}</div>
                                        </div>
                                        {this.renderProfileInfo()}
                                    </div>
                                </div>
                            ) : (
                                <div className="profile-screen__body__left__gallery">
                                    <DrawerGallery
                                        galleryUser={userData}
                                        canEdit={itsMe}
                                        reFetchGallery={isFetchingGallery}
                                        onReFetchGallery={() => this.setState({ isFetchingGallery: false })}
                                    />
                                </div>
                            )}
                            {ccPaymentSucess && (this.renderSuccessPaymentModal())}
                            {ccPaymentError && (this.renderErrorPaymentModal())}
                            <div className="overlay">
                                <button type="button" className="btn btn--primary-inverse" onClick={this.onSubmit}>
                                    {t('profile.save')}
                                </button>
                            </div>
                        </div>
                        <div className="profile-screen__body__right">
                            <DrawerGallery
                                galleryUser={userData}
                                canEdit={itsMe}
                                reFetchGallery={isFetchingGallery}
                                onReFetchGallery={() => this.setState({ isFetchingGallery: false })}
                            />
                        </div>
                    </div>
                    {showNewAchievementForm && (
                        <NewAchievementForm
                            close={() => this.setState({ showNewAchievementForm: false })}
                            sport={fields.sport?.value || ''}
                            isFetching={false}
                            addToList={(newAchievement: Achievement): void => {
                                this.setState({
                                    achievements: sortAchievementsByDate([...achievements, newAchievement]),
                                    showNewAchievementForm: false,
                                });
                            }}
                        />
                    )}
                    {showAddClubModal && (
                        <div className="modal modal--small">
                            <div className="modal__backdrop " />
                            <div className="modal__content modal__content--small">
                                <button
                                    type="button"
                                    className="modal__content__close"
                                    data-testid="close-modal"
                                    onClick={() => this.setState({
                                        showAddClubModal: false,
                                    })}
                                >
                                    <IconTimes fill="#000" />
                                </button>
                                <form>
                                    <h3>{t('profile.addClub')}</h3>
                                    <FormField
                                        type="text"
                                        name="newClub"
                                        value={newClub}
                                        onChange={this.onAddNewClubInputChange}
                                        placeholder={t('profile.clubName')}
                                        errors={newClubErrors}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn--centered btn--primary-inverse"
                                        onClick={this.onSaveNewClub}
                                    >
                                        {t('profile.saveClub')}
                                    </button>
                                </form>
                            </div>
                        </div>
                    )}
                    {pendingAthleteSupporter && (
                        <AthletePendingSupportModal
                            athleteSupporter={pendingAthleteSupporter}
                            onClose={() => this.onCloseAthletePendingSupportModal()}
                        />
                    )}
                </div>
            </>
        );
    }
}

export default withAuthenticationContext(
    withSponsorContext(
        withUserContext(
            withTranslationContext(
                withSportestContext(ProfileScreen),
            ),
        ),
    ),
);
