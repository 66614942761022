/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

/**
 * @typedef {Object} OwnProps
 * @property {string} fill
 * @property {Function} onClick
 */
interface OwnProps {
    fill: string;
    onClick: Function;
    testId: string;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * icon svg
 * @param {Props} props
 * @extends {FunctionComponent<Props>}
 */
const IconAfter: FunctionComponent<Props> = (props: Props) => {
    const { onClick, fill, testId } = props;

    return (
        <div className="icon-first__icon" onClick={() => onClick()} data-testid={testId}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill={fill} />
            </svg>
        </div>
    );
};

export default IconAfter;
