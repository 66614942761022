/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { Achievement } from './achievements';
import { DropdownOption, Media } from './misc';

/**
 * @typedef {Object} ProfileFormFields
 * @property {string} name
 * @property {string} lastName
 * @property {DropdownOption} [sport]
 * @property {DropdownOption} club
 * @property {string} weight
 * @property {string} height
 * @property {Date | null} dateOfBirth
 * @property {string} phrase
 * @property {string} biography
 * @property {string} objectives
 * @property {string} avatarId
 * @property {string} coverId
 */
export interface ProfileFormFields {
    name: string;
    lastName: string;
    sport: DropdownOption | null;
    countryCode: DropdownOption | string | null;
    fiscalCode: string;
    postalCode: string;
    address: string;
    club: DropdownOption | null;
    weight: string;
    height: string;
    dateOfBirth: Date | null;
    phrase: string;
    biography: string;
    objectives: string;
    avatarId: any;
    coverId: any;
}

/**
 * @typedef {Object} FanProfileFormFields
 * @property {string} name
 * @property {string} phrase
 * @property {string} biography
 * @property {string} avatarId
 * @property {string} coverId
 */
export interface FanProfileFormFields {
    name: string;
    countryCode: DropdownOption | string | null;
    fiscalCode: string;
    postalCode: string;
    address: string;
    phrase: string;
    biography: string;
    avatarId: any;
    coverId: any;
}

/**
 * @typedef {Object} SponsorProfileFormFields
 * @property {string} name
 * @property {string} phrase
 * @property {string} aboutUs
 * @property {string} biography
 * @property {string} address
 * @property {string} avatarId
 * @property {string} coverId
 */
export interface SponsorProfileFormFields {
    name: string;
    phone: string;
    countryCode: DropdownOption | string | null;
    fiscalCode: string;
    postalCode: string;
    address: string;
    phrase: string;
    aboutUs: string;
    biography: string;
    avatarId: any;
    coverId: any;
}

export interface ManagerProfileFormFields {
    name: string;
    lastName: string;
    phone: string;
    sport: string;
    countryCode: DropdownOption | string | null;
    fiscalCode: string;
    postalCode: string;
    address: string;
    phrase: string;
    biography: string;
    objectives: string;
    avatarId: any;
    coverId: any;
}

/**
 * @typedef {Object} ProfilePostData
 * @property {string} name
 * @property {string} lastName
 * @property {string} [sport]
 * @property {string} [club]
 * @property {number} [weight]
 * @property {number} [height]
 * @property {number} [dateOfBirth]
 * @property {string} [phrase]
 * @property {string} [biography]
 * @property {string} [objectives]
 * @property {any} avatarId
 * @property {any} coverId
 */
export interface ProfilePostData {
    name: string;
    lastName: string;
    countryCode: string | null;
    fiscalCode: string;
    postalCode: string;
    address: string;
    club: Club | null;
    weight: number | null;
    height: number | null;
    dateOfBirth: Date | null;
    phrase: string | null;
    biography: string | null;
    objectives: string | null;
    avatarId: any;
    coverId: any;
}

/**
 * @typedef {Object} FanProfilePostData
 * @property {string} name
 * @property {string} [phrase]
 * @property {string} [biography]
 * @property {any} avatarId
 * @property {any} coverId
 */
export interface FanProfilePostData {
    name: string;
    countryCode: string | null;
    fiscalCode: string;
    postalCode: string;
    address: string;
    phrase: string | null;
    biography: string | null;
    avatarId: any;
    coverId: any;
}

/**
 * @typedef {Object} SponsorProfilePostData
 * @property {string} name
 * @property {string} [phrase]
 * @property {string} [aboutUs]
 * @property {string} [biography]
 * @property {string} [address]
 * @property {any} avatarId
 * @property {any} coverId
 */
export interface SponsorProfilePostData {
    name: string;
    countryCode: string | null;
    fiscalCode: string;
    postalCode: string;
    address: string;
    phone: string;
    phrase: string;
    aboutUs: string;
    biography: string;
    avatarId: any;
    coverId: any;
}

/**
 * @typedef {Object} ManagerProfilePostData
 * @property {string} name
 * @property {string} [phrase]
 * @property {string} [aboutUs]
 * @property {string} [biography]
 * @property {string} [address]
 * @property {any} avatarId
 * @property {any} coverId
 */
export interface ManagerProfilePostData {
    name: string;
    lastName: string;
    countryCode: string | null;
    fiscalCode: string;
    postalCode: string;
    phone: string;
    phrase: string;
    biography: string;
    address: string;
    avatarId: any;
    coverId: any;
}

/**
 * @typedef {Object} User
 * @property {any} id
 * @property {string} email
 * @property {string} name
 * @property {string} acceptedTermsOfUse
 * @property {boolean} validated
 * @property {string} lastName
 * @property {string} [dateOfBirth]
 * @property {string} [phone]
 * @property {string} [nif]
 * @property {string} [address]
 * @property {string} [postalCode]
 * @property {string} [country]
 * @property {string} [nationality]
 * @property {string} [naturalness]
 * @property {string} [civilStatus]
 * @property {string} [avatar]
 * @property {string} [coverPhoto]
 * @property {string} [club]
 * @property {string} [sport]
 * @property {number} weight
 * @property {number} height
 * @property {number} age
 * @property {string} [phrase]
 * @property {string} [biography]
 * @property {string} [objectives]
 * @property {any[]} mediaList
 * @property {any[]} sportingEvents
 * @property {UserRoles} role
 * @property {User[]} [sponsors]
 */
export interface User {
    id: any;
    email: string;
    name: string;
    acceptedTermsOfUse: string;
    validated: boolean;
    lastName: string | null;
    dateOfBirth: string | null;
    phone?: string;
    nif?: string;
    countryCode: string;
    postalCode: string;
    fiscalCode: string;
    address: string | null;
    nationality?: string;
    naturalness?: string;
    civilStatus?: string;
    avatar: Media | null;
    coverPhoto: Media | null;
    club: Club | null;
    weight: number;
    height: number;
    phrase: string | null;
    biography: string | null;
    objectives: string | null;
    mediaList: any[];
    sportingEvents: any[];
    role: UserRoles;
    hiddenProfile: boolean;
    achievements: Achievement[];
    availableCredits?: number;
}

export interface Sponsor extends User {
    sponsoredAthletes: Athlete[];
    aboutUs: string;
}

export interface Athlete extends User {
    sponsors: Sponsor[];
}

export interface BadgesUrl {
    id: number;
    main: string;
    mainMimeType: string;
    mediaType: string;
}

export interface Badge {
    id: number;
    role: string;
    badgeUrl: BadgesUrl;
}
export enum UserRoles {
    Athlete = 'Athlete',
    Sponsor = 'Sponsor',
    Admin = 'Admin',
    Fan = 'Fan',
    Manager = 'Manager',
}

export enum UserTypes {
    Athlete = 'ATHLETE',
    Sponsor = 'SPONSOR',
    Admin = 'ADMIN',
    Fan = 'FAN',
    Manager = 'MANAGER',
}

export interface Club {
    clubCompositeKey: {clubName: string; sport: string};
}

export interface SendMessageRequest {
    userId: number;
    message: string;
}

export interface CreditsUpdateEvent {
    totalCredits: number;
}
